import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text } from "@chakra-ui/react";

export default function Confirmation({ isOpen, onClose, onConfirm, heading, warning }) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <Box px={6} className="rounded-t-md" pt={'10px'} style={{ color: '#3c4043', background: '#f1f3f4', }}>
                    <Text className="rounded-t-md font-normal mb-[5px] text-[14px] robotic">{heading ? heading : 'Are you sure?'}</Text>
                    <ModalCloseButton mx={6} mt={'7px'} h={'min-content'} w={'min-content'} />
                </Box>
                <ModalBody mt={3} mb={0}>
                    {warning ? warning : <Text fontSize={'14px'} fontWeight={'normal'} className="text-[14px] robotic" textAlign={'center'}>This action cannot be undone <br /> Are you sure you want to proceed?</Text>}
                </ModalBody>

                <ModalFooter px={7} pt={3}>
                    <Button fontSize={'14px'} fontWeight={'normal'} className="text-[14px] robotic" variant="ghost" mr={3} onClick={onClose}>Cancel</Button>
                    <Button fontSize={'14px'} fontWeight={'normal'} className="text-[14px] robotic" background={"#1A73E8"} colorScheme="blue" onClick={onConfirm}>
                        Confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}