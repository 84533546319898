import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { getISOWeek, isAfter, isBefore, isSameDay, isSameWeek, isWeekend } from 'date-fns';
import { useAtom } from 'jotai';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ViewProjectsFilter from './Components/Employees/ViewProjectsFilter';
import ProjectsList from './Components/Projects/NameDetails/ProjectsList';
import UserContext from './Context/User/UserContext';
import Tooltip from './SharedViewComponents/Tooltip';
import { calendarLength, employees, holidays, optimalDates, projects, records } from './atoms';
import { checkSameDay, getUTCDate } from './utils/Functions/dateFunctions';
const GanttChart = () => {
  const [employeeList] = useAtom(employees);
  const [collapse, setCollapse] = useState(true);
  const [projectList] = useAtom(projects);
  const [calendarData, setCalendarData] = useState([]);
  const [optimalDate] = useAtom(optimalDates);
  const [holidaysList] = useAtom(holidays);
  const [recordList] = useAtom(records);
  const { openedList, setOpenedList } = useContext(UserContext)
  const [, setCalendarLength] = useAtom(calendarLength)

  const toggleItem = (item) => {
    const isOpened = openedList.includes(item);
    if (!isOpened) {
      setOpenedList((prev) => [...prev, item]);
    } else {
      const updatedList = openedList.filter(existingItem => existingItem !== item);
      setOpenedList(updatedList);
    }
  };
  const addItem = (item) => {
    const isOpened = openedList.some((i) => i === item);
    if (!isOpened) {
      setOpenedList((prev) => [...prev, item]);
    }
  };
  useEffect(() => {
    if (collapse) {
      const openProjectsNames = projectList
        .filter(project => openedList.some((pro) => pro === project.name) && project?.closed === true)
        .map(project => project.name);
      setOpenedList(openProjectsNames)
    } else {
      const openProjectsNames = projectList
        .filter(project => !project?.closed === true)
        .map(project => project.name);
      setOpenedList([...openedList, ...openProjectsNames])
    }
  }, [collapse])


  useEffect(() => {
    createCalendarData(optimalDate.cs_date < optimalDate.is_date ? optimalDate.cs_date : optimalDate.is_date, optimalDate.ce_date > optimalDate.ie_date ? optimalDate.ce_date : optimalDate.ie_date);
    // eslint-disable-next-line
  }, [optimalDate]);

  const createCalendarData = useCallback((startDate, endDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    startDate = new Date(startDate);
    startDate = startDate < new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000) ? startDate : new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);


    endDate = new Date(endDate);
    const maxEndDate = new Date(today.getTime() + 10 * 7 * 24 * 60 * 60 * 1000);
    endDate = endDate > maxEndDate ? endDate : maxEndDate;

    startDate = new Date(startDate.setDate(startDate.getDate() - startDate.getDay() + 1));
    endDate = new Date(endDate.setDate(endDate.getDate() + (7 - endDate.getDay())));
    const newCalendarData = [];

    while (startDate <= endDate) {
      if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
        const dayData = {
          date: startDate.getDate(),
          month: startDate.toLocaleString('default', { month: 'long' }),
          monthInd: startDate.getMonth(),
          year: startDate.getFullYear(),
          // isSameDay: isSameDay(new Date().setHours(0, 0, 0, 0), startDate)
        };
        newCalendarData.push(dayData);
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    setCalendarData(newCalendarData);
    setCalendarLength(newCalendarData.length)
  }, [])

  const convertObjectToDate = useCallback((inputObject) => {
    const utcTimestamp = Date.UTC(inputObject?.year, inputObject.monthInd, inputObject?.date, 0, 0, 0, 0);
    const utcDate = new Date(utcTimestamp);
    return utcDate.toUTCString();
  }, [])

  const findAssignment = useCallback((projectName, empName, singleDate) => {
    if (!recordList.hasOwnProperty(empName)) {
      return false
    }
    const employeeAssignments = recordList[empName];

    const bool = employeeAssignments.findIndex((assignment) => assignment.project === projectName);
    if (employeeAssignments) {
      if (bool !== -1) {
        const projectAssignment = employeeAssignments.find((assignment) => assignment.project === projectName);
        const isDatePresent = projectAssignment.dates?.some((i) => isSameDay(i, singleDate));
        if (isDatePresent) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }, [recordList])

  const checkAssignment = useCallback((empName, singleDate) => {
    const employeeAssignments = recordList[empName];
    if (!employeeAssignments) {
      return false
    }
    //checks if employee is assigned to some other project
    if (employeeAssignments?.some((assignment) => (assignment.project !== "" && assignment.dates?.some((i) => isSameDay(i, singleDate))))) {
      return true
    }
    return false
  }, [recordList])


  const getEmployeeCountForDateAndProject = useCallback((date, projectName) => {
    let count = 0;
    Object.values(recordList)?.forEach((i) => {
      if (i?.some((assignment) => assignment?.project === projectName && assignment?.dates?.some((j) => isSameDay(j, date)))) {
        count++
      }
    })
    return count
  }, [recordList])

  const sameTeam = useCallback((date, projectName) => {
    let team = [];
    Object.values(recordList).forEach((i, ind) => {
      if (i?.some((assignment) => assignment?.project === projectName && assignment?.dates?.some((j) => isSameDay(j, date)))) {
        team.push(Object.keys(recordList)[ind]);
      }
    })
    if (team?.length === 1) {
      return getTeamByName(team[0])
    } else {
      const modifiedTeam = team.map(getTeamByName);
      const uniqueSet = new Set(modifiedTeam);
      return !(uniqueSet.size === modifiedTeam?.length) && uniqueSet.size === 1 ? getTeamByName(team[0]) : false;
    }
  }, [employeeList, projectList, recordList])

  const getTeamByName = useCallback((emp) => {
    return employeeList?.find((i) => i?.name === emp)?.team
  }, [employeeList])

  const getNextWorkingDay = useCallback((curr) => {
    let nextDay = curr;
    nextDay.setDate(nextDay.getDate() + 1);

    if (isWeekend(nextDay)) {
      return getNextWorkingDay(nextDay);
    }
    return nextDay;
  }, [])
  const [subCalendar, setSubCalendar] = useState([])
  const [currInd, setCurrInd] = useState(0)
  const [gap, setGap] = useState(10)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 990) {
        setViewFilter(false)
        setGap(window.innerWidth <= 445 ? 10 : window.innerWidth <= 550 ? 15 : window.innerWidth <= 665 ? 20 : window.innerWidth <= 780 ? 25 : window.innerWidth <= 890 ? 30 : 35)
      } else {
        setSubCalendar(calendarData)
      }
      calculateGap()
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    calculateGap()
  }, [calendarData])

  const calculateGap = () => {
    if (window.innerWidth < 990) {
      setGap(window.innerWidth <= 445 ? 10 : window.innerWidth <= 550 ? 15 : window.innerWidth <= 665 ? 20 : window.innerWidth <= 780 ? 25 : window.innerWidth <= 890 ? 30 : 35)
    }
  }

  useEffect(() => {
    calculateSubCalendar()
  }, [calendarData, gap])

  const calculateSubCalendar = () => {
    if (window.innerWidth < 990) {
      setSubCalendar(calendarData.slice(currInd, currInd + gap))
    }
  }

  useEffect(() => {
    if (window.innerWidth < 990) {
      const date = new Date()
      const currIndex = calendarData.findIndex((i) => isSameWeek(convertObjectToDate(i), date))
      setCurrInd(currIndex)
      setSubCalendar(calendarData.slice(currIndex, currIndex + gap))
    }

  }, [calendarData])

  const findDate = (e) => {
    const currIndex = calendarData.findIndex((i) => isSameWeek(e.target.value, convertObjectToDate(i)));
    if (currIndex !== -1) {
      setCurrInd(currIndex + gap)
      setSubCalendar(calendarData.slice(currIndex, currIndex + gap))
    }
  }
  const gotoPrev = () => {
    if (currInd >= 1) {
      if (currInd >= gap) {
        setSubCalendar(calendarData.slice(currInd - gap, currInd))
        setCurrInd(currInd - gap)
      } else {
        setSubCalendar(calendarData.slice(0, gap))
        setCurrInd(0)
      }
    }
  }
  const gotoNext = () => {
    if (currInd + gap <= calendarData.length - 1) {
      setSubCalendar(calendarData.slice(currInd + gap, currInd + gap + gap))
      setCurrInd(currInd + gap)
    }
  }





  const refW = useRef(null)

  const [proFilter, setProFilter] = useState([])
  const [viewFilter, setViewFilter] = useState(false)
  return (
    <Box mb={'5vh'} ref={refW} className='select-none'>
      {window.innerWidth < 900 ?
        <Box display={'flex'} className='select-none' width={'100%'} >
          {/* FIRST SIDE */}
          <Box position={'sticky'} zIndex={9} left={0} minWidth={'100px'} ml={0} background={'white'} borderRight={'none'} display={'flex'} justifyContent={'start'} alignItems={'start'} flexDir={'column'} >
            <Box bg={'white'} zIndex={8} style={{ position: 'sticky', top: 0 }} flexDir={'column'} transition={'all 0.1s ease-in-out'} height={'65px'} display={'flex'} w={'100%'}>
              <Flex justifyContent={'space-between'} px={1} >
                <Button bg={'white'} className={'hover:border-black robotic mb-1 mx-1'} maxH={'25px'} boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'} fontWeight={'normal'} fontSize={'14px'} w={'100%'} onClick={() => setViewFilter(true)} >FILTER</Button>
                {viewFilter && <ViewProjectsFilter viewFilter={viewFilter} setViewFilter={setViewFilter} proFilter={proFilter} setProFilter={setProFilter} />}
              </Flex>
              <Flex justifyContent={'space-evenly'}>
                <Flex justifyContent={'center'} alignItems={'center'} className='space-x-2' mt={'5px'}>
                  <Button size={'xs'} bg={'white'} className='border-[1px] p-1 border-[#32afff]  max-h-[28px] max-w-[15px]' onClick={gotoPrev}>&lt;</Button>
                  <Button size={'xs'} bg={'white'} className='border-[1px] p-1 border-[#32afff]  max-h-[28px] max-w-[15px]' onClick={gotoNext}>&gt;</Button>
                </Flex>
                <Box mt={'5px'} ><Tooltip label={'Search by Date'}>
                  <Input p={1} m={0} border={'1px solid #32afff'} _hover={{ border: '1px solid #32afff', bg: '#e1e1e1', }} outline={'none'} onChange={findDate} maxW={'24px'} color={'white'} h={'24px'} type='date' />
                </Tooltip>
                </Box>
              </Flex>
            </Box>
            <Box w={'100%'}>
              <Flex zIndex={1} justifyContent={'space-between'} flexDir={'row-reverse'} alignItems={'center'} transition={'all 0.3s ease-in-out'} bgColor={'#eeeeee'} position={'sticky'} top={`${65}px`} borderLeftRadius={'5px'} background={'#eeeeee'} className='h-[20px] text-white w-[100%] text-center border-none'>
                {collapse ? <IoIosArrowDown onClick={() => setCollapse(false)} cursor={'pointer'} style={{ marginRight: '5px' }} fontSize={'14px'} /> : <IoIosArrowUp onClick={() => setCollapse(true)} cursor={'pointer'} style={{ marginRight: '5px' }} fontSize={'14px'} />}
                <Text fontSize={'13px'} className='robotic whitespace-nowrap font-normal' ms={'3px'} mt={'2px'} color={'#3c4043'}>PROJECTS</Text>
              </Flex>
              <ProjectsList proFilter={proFilter} toggleItem={toggleItem} addItem={addItem} closed={false} />
            </Box>
          </Box>


          {/* SECOND SIDE */}
          <Box p={0} order={2} borderRight={'3px solid #eeeeee'} alignItems={'start'} display={'flex'} flexDir={'column'}>
            <Box style={{ position: 'sticky', top: 0 }} w={'100%'} transition={'all 0.3s ease-in-out'} zIndex={8} height={'65px'} display={'flex'} flexDir={'column'} background={'white'}>
              <Flex borderBottom={'1px solid #eeeeee'} className='space-x-[1px]' height={'22px'} bg={'#eeeeee'} flexDir={'row'} flex={1} alignItems={'center'} >
                {subCalendar?.map((i, ind) => {
                  const DATE_BOX_WIDTH = 21.4;
                  const DATE_BOX_MARGIN = 1;
                  const monthDays = subCalendar.filter(k => k.month === i.month && k.year === i.year).length;
                  const monthBoxWidth = (monthDays * DATE_BOX_WIDTH) + ((monthDays - 1) * DATE_BOX_MARGIN);
                  let month = i.month.charAt(0).toUpperCase() + i.month.slice(1)
                  return (subCalendar[ind - 1]?.month !== subCalendar[ind]?.month && <Box ms={ind === 0 && '2px'} bg={'white'} key={ind} minW={`${monthBoxWidth}px`} flexDir={'column'}>
                    <Box textAlign={'center'} fontSize={'12px'} className='robotic' h={'18px'} display={'grid'} placeItems={'center'}  >{monthDays <= 2 ? month.substr(0, 3) : month} {monthDays <= 3 ? '' : i.year}</Box>
                  </Box>)
                })}
              </Flex>

              <Flex borderBottom={'2px solid #eeeeee'} height={'20px'} background={'#eeeeee'} flexDir={'row'} alignItems={'center'} justifyContent={'stretch'}>
                {subCalendar?.map((i, ind) => {
                  return (ind % 5 === 0 &&
                    <Box key={ind} ms={'3px'} bg={'white'} flex={1} flexDir={'column'}>
                      <Box textAlign={'center'} h={'20px'} minW={'109px'} maxW={'109px'} fontSize={'12px'} className='robotic' display={'grid'} placeItems={'center'}>{`${navigator.language.split('-')[0] === 'da' ? 'Uge' : navigator.language.split('-')[0] === 'pt' ? 'Semana' : 'Week'} ${getISOWeek(convertObjectToDate(i))}`}</Box>
                    </Box>)
                })}
              </Flex>

              <Flex h='20px' w={'100%'} background={'#eeeeee'}>{subCalendar?.map((i, indC) => {
                const date = new Date()
                let sameday = checkSameDay(date, i)
                return <Box background={'white'} borderBottom={sameday ? '1px solid red' : '1px solid #eeeeee'} key={indC} marginLeft={indC % 5 === 0 ? '3px' : '1px'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
                  <Box h={'21px'} w={'21px'} fontSize={'12px'} className="roboto-condensed" display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={sameday ? { borderRadius: '50px', background: 'red', maxHeight: '18px', width: '18px', color: 'white' } : {}}>{`${i?.date}`}</Box></Box>
                </Box>
              })}
              </Flex>
            </Box>


            <Box >
              <Box transition={'all 0.3s ease-in-out'} bg={'#eeeeee'} color={'#3c4043'} position={'sticky'} top={`${65}px`} className={` h-[20px]`} style={{ width: `${subCalendar.length * 22.4}px` }}>
              </Box>
              {projectList?.filter((i) => {
                if (proFilter.length === 0) {
                  return !i.closed;
                } else {
                  return i.assigned.some((ass) => proFilter.some((pro) => pro.name === ass.name));
                }
              })?.map((i, ind) => {
                let start = i.cs_date < i.is_date ? i.cs_date : i.is_date
                let end = i.ce_date > i.ie_date ? i.ce_date : i.ie_date
                start = getUTCDate(start)
                end = getUTCDate(end)
                let sameday = false
                return !i.closed && <Box key={ind}>
                  <Flex background={'#eeeeee'}>{subCalendar?.map((j, indC) => {
                    const currDay = convertObjectToDate(j)
                    const employeeCount = getEmployeeCountForDateAndProject(currDay, i?.name)
                    const inRange = (isBefore(start, currDay) || isSameDay(start, currDay)) && (isAfter(end, currDay) || isSameDay(end, currDay))
                    const date = new Date()
                    sameday = checkSameDay(date, j)

                    return (
                      <Box ml={indC % 5 === 0 ? '3px' : '1px'} h={'22px'} borderBottom={'1px solid #eeeeee'} background={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'} className={`box`} key={indC} >
                        {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px' }} />}
                        {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginRight: '20px', position: 'absolute', width: '1px', height: '28px' }} />}
                        <Box outlineOffset={'-2px'} outline={isSameDay(i.ce_date, currDay) && isSameDay(i.ie_date, currDay) ? '2px solid #3c4043' : isSameDay(i.ce_date, currDay) ? '2px solid #3c4043' : isSameDay(i.ie_date, currDay) ? '2px solid red' : 'none'} borderRadius={'5px'} >
                          {inRange ?
                            <Box borderLeftRadius={isSameDay(start, currDay) ? '5px' : ''} borderRightRadius={isSameDay(end, currDay) ? '5px' : ''} cursor={'pointer'} background={inRange && (employeeCount === 0 ? '#ffff00' : sameTeam(currDay, i?.name) !== false ? sameTeam(currDay, i?.name) : '#e1e1e1')} outlineOffset={'-2px'} height={'21px'} key={ind} w={'21px'} p={'2px'} textAlign={'center'}  >
                              {isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) || isSameDay(i.ce_date, currDay) || isSameDay(i.ie_date, currDay) ?
                                <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={isSameDay(i.cs_date, currDay) ? "Contract Start Date" : isSameDay(i.ce_date, currDay) ? "Contract End Date" : isSameDay(i.is_date, currDay) ? "Internal Start Date" : "Internal End Date"} >
                                  <Box textAlign={'center'} outlineOffset={'-1px'} h={'17px'} w={'17px'} borderRadius={isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) ? '50px' : 'none'} outline={isSameDay(i.is_date, currDay) && isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : isSameDay(i.is_date, currDay) ? '2px solid red' : isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : 'none'}>
                                    <Text color={'#3c4043'} className='robotic' fontSize={'12px'}>{inRange && employeeCount}
                                    </Text>
                                  </Box>
                                </Tooltip> :
                                <Box textAlign={'center'} outlineOffset={'-1px'} h={'17px'} w={'17px'} borderRadius={isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) ? '50px' : 'none'} outline={isSameDay(i.is_date, currDay) && isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : isSameDay(i.is_date, currDay) ? '2px solid red' : isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : 'none'}>
                                  <Text color={'#3c4043'} className='robotic' fontSize={'12px'}>{inRange && employeeCount}
                                  </Text>
                                </Box>}
                            </Box>
                            :
                            <Box height={'21px'} key={ind} w={'21px'} p={'2px'}>
                            </Box>
                          }
                        </Box>
                      </Box>
                    )
                  })}
                  </Flex>
                  {
                    openedList?.some((single) => single === i?.name) && i?.assigned?.map((empId, index) => {
                      const curr = employeeList.find((emp) => emp?.name === empId?.name)
                      return (<Flex bg={'#eeeeee'} key={index}>{subCalendar?.map((l, indC) => {
                        const converted = convertObjectToDate(l);
                        const absentee = curr?.absent_dates?.some((day) => isSameDay(day, converted))
                        const globalHoliday = holidaysList?.some((day) => isSameDay(day, converted))
                        const inRange = (isBefore(start, converted) || isSameDay(start, converted)) && (isAfter(end, converted) || isSameDay(end, converted))

                        const date = new Date()
                        sameday = checkSameDay(date, l)
                        const matchedAssignment = findAssignment(i?.name, curr?.name, converted)
                        let proName = ""
                        if (!matchedAssignment && checkAssignment(curr?.name, converted)) {
                          proName = (recordList[curr?.name]?.find((assignment) => (assignment.project !== i?.name && assignment.dates?.find((i) => isSameDay(i, converted)))))?.project
                        }
                        return inRange ? (
                          globalHoliday ?
                            (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                              <Box>
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                <Box textColor={'white'} height={'20px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                                  <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"General Absent Day"} >
                                    <Box bg={'#e1e1e1'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>) :
                            absentee ? (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                              <Box>
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                <Box textColor={'white'} height={'20px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                                  <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"Employee Absent Day"} >
                                    <Box bg={'#3c4043'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                                  </Tooltip>
                                </Box>
                              </Box></Box>)
                              :
                              <Box w={'21px'} key={indC} bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h={'20px'} cursor={'pointer'} >
                                  {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                  {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '28px', }} />}

                                  {matchedAssignment ? <Box flexDir={'column'} w={'20px'} height={'19px'}
                                    mb={'auto'} display={'flex'} justifyContent={'center'} alignItems={'center'}
                                    background={curr?.team} borderRadius={'5px'}  >
                                  </Box> :
                                    (!matchedAssignment && checkAssignment(curr?.name, converted)) ? <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={`Assigned to Project: "${proName}"`} >
                                      <Box display={'grid'} placeItems={'center'} h={'100%'}><Box w={'10px'} h={'3px'} background={curr?.team}></Box> </Box></Tooltip> :
                                      (!matchedAssignment && !checkAssignment(curr?.name, converted)) ? <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={`Unused Employee`} >
                                        <Box display={'grid'} placeItems={'center'} h={'100%'}  ><Box h={'17px'} w={'17px'} borderRadius={'10px'} bg={'#ffff00'}>
                                        </Box> </Box></Tooltip> : null
                                  }
                                </Box></Box>)

                          : (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                            <Box flexDir={'column'}>
                              {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                              {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                              <Box w={'21px'} fontSize={'15px'} textAlign={'center'} p={1}></Box>
                            </Box></Box>)
                      })}
                      </Flex>)
                    }
                    )
                  }
                </Box>
              })}
            </Box>



          </Box>

        </Box>


        :





        <Box display={'flex'} className='select-none' width={'100%'} >
          {/* FIRST SIDE */}
          <Box position={'sticky'} zIndex={9} left={0} minWidth={'200px'} ml={0} background={'white'} borderRight={'none'} display={'flex'} justifyContent={'start'} alignItems={'start'} flexDir={'column'} >
            <Box bg={'white'} zIndex={8} style={{ position: 'sticky', top: 0 }} flexDir={'column'} transition={'all 0.1s ease-in-out'} height={'65px'} display={'flex'} w={'100%'}>
              <Flex justifyContent={'space-between'} px={1} >
                <Button bg={'white'} className={'hover:border-black robotic mb-1 mx-1'} maxH={'25px'} boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'} fontWeight={'normal'} fontSize={'14px'} minW={'180px'} onClick={() => setViewFilter(true)} >FILTER</Button>
                {viewFilter && <ViewProjectsFilter viewFilter={viewFilter} setViewFilter={setViewFilter} proFilter={proFilter} setProFilter={setProFilter} />}
              </Flex>
            </Box>
            <Box w={'100%'}>
              <Flex zIndex={1} justifyContent={'space-between'} flexDir={'row-reverse'} alignItems={'center'} transition={'all 0.3s ease-in-out'} bgColor={'#eeeeee'} position={'sticky'} top={`${65}px`} borderLeftRadius={'5px'} background={'#eeeeee'} className='h-[20px] text-white w-[100%] text-center border-none'>
                {collapse ? <IoIosArrowDown onClick={() => setCollapse(false)} cursor={'pointer'} style={{ marginRight: '5px' }} fontSize={'14px'} /> : <IoIosArrowUp onClick={() => setCollapse(true)} cursor={'pointer'} style={{ marginRight: '5px' }} fontSize={'14px'} />}
                <Text fontSize={'13px'} className='robotic whitespace-nowrap font-normal' mt={'2px'} ms={'3px'} color={'#3c4043'}>PROJECTS</Text>
              </Flex>
              <ProjectsList proFilter={proFilter} toggleItem={toggleItem} addItem={addItem} closed={false} />
            </Box>
          </Box>


          {/* SECOND SIDE */}
          <Box p={0} order={2} borderRight={'3px solid #eeeeee'} alignItems={'start'} display={'flex'} flexDir={'column'}>
            <Box style={{ position: 'sticky', top: 0 }} w={'100%'} transition={'all 0.3s ease-in-out'} zIndex={8} height={'65px'} display={'flex'} flexDir={'column'} background={'white'}>
              <Flex borderBottom={'1px solid #eeeeee'} className='space-x-[1px]' height={'22px'} bg={'#eeeeee'} flexDir={'row'} flex={1} alignItems={'center'} >
                {calendarData?.map((i, ind) => {
                  const DATE_BOX_WIDTH = 21.4;
                  const DATE_BOX_MARGIN = 1;
                  const monthDays = calendarData.filter(k => k.month === i.month && k.year === i.year).length;
                  const monthBoxWidth = (monthDays * DATE_BOX_WIDTH) + ((monthDays - 1) * DATE_BOX_MARGIN);
                  let month = i.month.charAt(0).toUpperCase() + i.month.slice(1)
                  return (calendarData[ind - 1]?.month !== calendarData[ind]?.month && <Box ms={ind === 0 && '2px'} bg={'white'} key={ind} minW={`${monthBoxWidth}px`} flexDir={'column'}>
                    <Box textAlign={'center'} fontSize={'12px'} className='robotic' h={'18px'} display={'grid'} placeItems={'center'}  >{monthDays <= 2 ? month.substr(0, 3) : month} {monthDays <= 3 ? '' : i.year}</Box>
                  </Box>)
                })}
              </Flex>

              <Flex borderBottom={'2px solid #eeeeee'} height={'20px'} background={'#eeeeee'} flexDir={'row'} alignItems={'center'} justifyContent={'stretch'}>
                {calendarData?.map((i, ind) => {
                  return (ind % 5 === 0 &&
                    <Box key={ind} ms={'3px'} bg={'white'} flex={1} flexDir={'column'}>
                      <Box textAlign={'center'} h={'20px'} minW={'109px'} maxW={'109px'} fontSize={'12px'} className='robotic' display={'grid'} placeItems={'center'}>{`${navigator.language.split('-')[0] === 'da' ? 'Uge' : navigator.language.split('-')[0] === 'pt' ? 'Semana' : 'Week'} ${getISOWeek(convertObjectToDate(i))}`}</Box>
                    </Box>)
                })}
              </Flex>

              <Flex h='20px' w={'100%'} background={'#eeeeee'}>{calendarData?.map((i, indC) => {
                const date = new Date()
                let sameday = checkSameDay(date, i)
                return <Box background={'white'} borderBottom={sameday ? '1px solid red' : '1px solid #eeeeee'} key={indC} marginLeft={indC % 5 === 0 ? '3px' : '1px'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
                  <Box h={'21px'} w={'21px'} fontSize={'12px'} className="roboto-condensed" display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={sameday ? { borderRadius: '50px', background: 'red', maxHeight: '18px', width: '18px', color: 'white' } : {}}>{`${i?.date}`}</Box></Box>
                </Box>
              })}
              </Flex>
            </Box>


            <Box >
              <Box transition={'all 0.3s ease-in-out'} bg={'#eeeeee'} color={'#3c4043'} position={'sticky'} top={`${65}px`} className={` h-[20px]`} style={{ width: `${calendarData.length * 22.4}px` }}>
              </Box>
              {projectList?.filter((i) => {
                if (proFilter.length === 0) {
                  return !i.closed;
                } else {
                  return i.assigned.some((ass) => proFilter.some((pro) => pro.name === ass.name));
                }
              })?.map((i, ind) => {
                let start = i.cs_date < i.is_date ? i.cs_date : i.is_date
                let end = i.ce_date > i.ie_date ? i.ce_date : i.ie_date
                start = getUTCDate(start)
                end = getUTCDate(end)
                let sameday = false
                return !i.closed && <Box key={ind}>
                  <Flex background={'#eeeeee'}>{calendarData?.map((j, indC) => {
                    const currDay = convertObjectToDate(j)
                    const employeeCount = getEmployeeCountForDateAndProject(currDay, i?.name)
                    const inRange = (isBefore(start, currDay) || isSameDay(start, currDay)) && (isAfter(end, currDay) || isSameDay(end, currDay))
                    const date = new Date()
                    sameday = checkSameDay(date, j)

                    return (
                      <Box ml={indC % 5 === 0 ? '3px' : '1px'} h={'22px'} borderBottom={'1px solid #eeeeee'} background={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'} className={`box`} key={indC} >
                        {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px' }} />}
                        {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginRight: '20px', position: 'absolute', width: '1px', height: '28px' }} />}
                        <Box outlineOffset={'-2px'} outline={isSameDay(i.ce_date, currDay) && isSameDay(i.ie_date, currDay) ? '2px solid #3c4043' : isSameDay(i.ce_date, currDay) ? '2px solid #3c4043' : isSameDay(i.ie_date, currDay) ? '2px solid red' : 'none'} borderRadius={'5px'} >
                          {inRange ?
                            <Box borderLeftRadius={isSameDay(start, currDay) ? '5px' : ''} borderRightRadius={isSameDay(end, currDay) ? '5px' : ''} cursor={'pointer'} background={inRange && (employeeCount === 0 ? '#ffff00' : sameTeam(currDay, i?.name) !== false ? sameTeam(currDay, i?.name) : '#e1e1e1')} outlineOffset={'-2px'} height={'21px'} key={ind} w={'21px'} p={'2px'} textAlign={'center'}  >
                              {isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) || isSameDay(i.ce_date, currDay) || isSameDay(i.ie_date, currDay) ?
                                <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={isSameDay(i.cs_date, currDay) ? "Contract Start Date" : isSameDay(i.ce_date, currDay) ? "Contract End Date" : isSameDay(i.is_date, currDay) ? "Internal Start Date" : "Internal End Date"} >
                                  <Box textAlign={'center'} outlineOffset={'-1px'} h={'17px'} w={'17px'} borderRadius={isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) ? '50px' : 'none'} outline={isSameDay(i.is_date, currDay) && isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : isSameDay(i.is_date, currDay) ? '2px solid red' : isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : 'none'}>
                                    <Text color={'#3c4043'} className='robotic' fontSize={'12px'}>{inRange && employeeCount}
                                    </Text>
                                  </Box>
                                </Tooltip> :
                                <Box textAlign={'center'} outlineOffset={'-1px'} h={'17px'} w={'17px'} borderRadius={isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) ? '50px' : 'none'} outline={isSameDay(i.is_date, currDay) && isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : isSameDay(i.is_date, currDay) ? '2px solid red' : isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : 'none'}>
                                  <Text color={'#3c4043'} className='robotic' fontSize={'12px'}>{inRange && employeeCount}
                                  </Text>
                                </Box>}
                            </Box>
                            :
                            <Box height={'21px'} key={ind} w={'21px'} p={'2px'}>
                            </Box>
                          }
                        </Box>
                      </Box>
                    )
                  })}
                  </Flex>
                  {
                    openedList?.some((single) => single === i?.name) && i?.assigned?.map((empId, index) => {
                      const curr = employeeList.find((emp) => emp?.name === empId?.name)
                      return (<Flex bg={'#eeeeee'} key={index}>{calendarData?.map((l, indC) => {
                        const converted = convertObjectToDate(l);
                        const absentee = curr?.absent_dates?.some((day) => isSameDay(day, converted))
                        const globalHoliday = holidaysList?.some((day) => isSameDay(day, converted))
                        const inRange = (isBefore(start, converted) || isSameDay(start, converted)) && (isAfter(end, converted) || isSameDay(end, converted))
                        const date = new Date()
                        sameday = checkSameDay(date, l)
                        const matchedAssignment = findAssignment(i?.name, curr?.name, converted)
                        let proName = ""
                        if (!matchedAssignment && checkAssignment(curr?.name, converted)) {
                          proName = (recordList[curr?.name]?.find((assignment) => (assignment.project !== i?.name && assignment.dates?.find((i) => isSameDay(i, converted)))))?.project
                        }
                        return inRange ? (
                          globalHoliday ?
                            (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                              <Box>
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                <Box textColor={'white'} height={'20px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                                  <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"General Absent Day"} >
                                    <Box bg={'#e1e1e1'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>) :
                            absentee ? (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                              <Box>
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                <Box textColor={'white'} height={'20px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                                  <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"Employee Absent Day"} >
                                    <Box bg={'#3c4043'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                                  </Tooltip>
                                </Box>
                              </Box></Box>)
                              :
                              <Box w={'21px'} key={indC} bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h={'20px'} cursor={'pointer'} >
                                  {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                                  {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '28px', }} />}

                                  {matchedAssignment ? <Box flexDir={'column'} w={'20px'} height={'19px'}
                                    mb={'auto'} display={'flex'} justifyContent={'center'} alignItems={'center'}
                                    background={curr?.team} borderRadius={'5px'}  >
                                  </Box> :
                                    (!matchedAssignment && checkAssignment(curr?.name, converted)) ? <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={`Assigned to Project: "${proName}"`} >
                                      <Box display={'grid'} placeItems={'center'} h={'100%'}><Box w={'10px'} h={'3px'} background={curr?.team}></Box> </Box></Tooltip> :
                                      (!matchedAssignment && !checkAssignment(curr?.name, converted)) ? <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={`Unused Employee`} >
                                        <Box display={'grid'} placeItems={'center'} h={'100%'}  ><Box h={'17px'} w={'17px'} borderRadius={'10px'} bg={'#ffff00'}>
                                        </Box> </Box></Tooltip> : null
                                  }
                                </Box></Box>)

                          : (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                            <Box flexDir={'column'}>
                              {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                              {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '28px', }} />}
                              <Box w={'21px'} fontSize={'15px'} textAlign={'center'} p={1}></Box>
                            </Box></Box>)
                      })}
                      </Flex>)
                    }
                    )
                  }
                </Box>
              })}
            </Box>



          </Box>

        </Box>
      }


    </Box>
  )
}



export default memo(GanttChart)