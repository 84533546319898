import { Box, Button, CloseButton, Flex, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react";
import { format, isBefore, isSameDay } from "date-fns";
import { useAtom } from "jotai";
import { Fragment, memo, useCallback, useState } from "react";
import Draggable from 'react-draggable';
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { employees, holidays, projects, records } from "../../atoms";
import { isWeekendCustom } from "../../utils/Functions/dateFunctions";
import showToast from '../../utils/Toast/Toast';
function NewProject({ open, setOpen, setOpenedList }) {

    const [tasks, setTasks] = useAtom(projects)
    const [holidaysList] = useAtom(holidays)
    const [employeeList] = useAtom(employees)
    const [recordsList, setRecords] = useAtom(records)
    const [newPro, setPro] = useState({
        name: "",
        cs_date: "",
        ce_date: "",
        is_date: "",
        closed: false,
        ie_date: "",
        assigned: []
    })

    const toast = useToast()
    const { name, cs_date, ce_date, is_date, ie_date, assigned } = newPro

    const getNextWorkingDay = (curr) => {
        let nextDay = new Date(curr);
        nextDay.setDate(nextDay.getDate() + 1);
        if (isWeekendCustom(format(nextDay, "yyyy-MM-dd")) || holidaysList.some((i) => i === format(nextDay, "yyyy-MM-dd"))) {
            return getNextWorkingDay(nextDay);
        }
        return format(nextDay, "yyyy-MM-dd");
    }
    const HandleChange = (e) => {
        let { name, value } = e.target;
        if (name !== 'name' && (isWeekendCustom(value) || holidaysList.some((i) => i === value))) {
            value = getNextWorkingDay(value)
        }
        setPro((prevPro) => {
            if (name === 'cs_date') {
                return {
                    ...prevPro,
                    [name]: value,
                    is_date: value,
                };
            }
            if (name === 'ce_date') {
                return {
                    ...prevPro,
                    [name]: value,
                    ie_date: value,
                };
            }
            return {
                ...prevPro,
                [name]: value,
            };
        });
    };

    const getDatesInRangeExcludingWeekends = useCallback((startDate, endDate, emp) => {
        const result = [];
        let currentDate = startDate
        currentDate = new Date(currentDate);
        currentDate = currentDate.toISOString();

        endDate = new Date(endDate);
        endDate = endDate.toISOString();
        while (isBefore(currentDate, endDate) || isSameDay(currentDate, endDate)) {
            const dayOfWeek = new Date(currentDate).getUTCDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6 && !(holidaysList?.some((i) => isSameDay(i, currentDate))) && !(emp?.absent_dates?.some((i) => isSameDay(i, currentDate)))) {
                result.push(new Date(currentDate));
            }
            currentDate = new Date(currentDate);
            currentDate.setDate(currentDate.getDate() + 1);
            currentDate = currentDate.toISOString();
        }
        return result;
    }, [recordsList, newPro, tasks]);

    const assignEmpAtDate = useCallback((projectName, empName, singleDate) => {
        if (projectName !== undefined) {
            setRecords((prevAssignments) => {
                const updatedAssignments = { ...prevAssignments };
                if (!updatedAssignments.hasOwnProperty(empName)) {
                    updatedAssignments[empName] = [];
                    const employeeAssignments = updatedAssignments[empName];
                    const newProjectAssignment = { project: projectName, dates: [singleDate] };
                    employeeAssignments.push(newProjectAssignment);
                } else {
                    const employeeAssignments = updatedAssignments[empName];
                    //checks if employee is assigned to some other project
                    if (employeeAssignments?.some((assignment) => (assignment.project !== projectName && assignment.dates?.some((i) => isSameDay(i, singleDate))))) {
                        // const ProName = (employeeAssignments?.find((assignment) => (assignment.project !== projectName && assignment.dates?.find((i) => isSameDay(i, singleDate)))))?.project
                        // !more && ShowToast(toast, 'Error', `${empName} is Already Assigned on this Date to Project: "${ProName}".`, 'error')
                    } else {
                        const existingProjectIndex = employeeAssignments.findIndex((assignment) => assignment.project === projectName);
                        if (existingProjectIndex !== -1) {
                            if (employeeAssignments[existingProjectIndex].dates?.some((i) => isSameDay(i, singleDate))) {
                                employeeAssignments[existingProjectIndex].dates = employeeAssignments[existingProjectIndex].dates.filter((i) => !(isSameDay(i, singleDate)));
                            } else {
                                employeeAssignments[existingProjectIndex].dates.push(singleDate);
                            }
                        } else {
                            const newProjectAssignment = { project: projectName, dates: [singleDate] };
                            employeeAssignments.push(newProjectAssignment);
                        }
                    }
                }
                return updatedAssignments;
            });
        }
    }, [recordsList, newPro, tasks])

    const selectionFun = useCallback((proj, emp, startDate, endDate) => {
        const sutcTimestamp = Date.UTC(startDate.split('-')[0], parseInt(startDate.split('-')[1]) - 1, startDate.split('-')[2], 0, 0, 0, 0);
        const sutcDate = new Date(sutcTimestamp);
        startDate = sutcDate.toUTCString();
        const eutcTimestamp = Date.UTC(endDate.split('-')[0], parseInt(endDate.split('-')[1]) - 1, endDate.split('-')[2], 0, 0, 0, 0);
        const eutcDate = new Date(eutcTimestamp);
        endDate = eutcDate.toUTCString();
        const dates = getDatesInRangeExcludingWeekends(startDate, endDate, employeeList.find((i) => i.name === emp))
        dates.forEach((i) => {
            assignEmpAtDate(proj, emp, i)
        })
    }, [recordsList, tasks, newPro])
    const AddProject = () => {
        if (!name || !cs_date || !ce_date || !is_date || !ie_date) {
            showToast(toast, 'Error', 'Add Name of Project + Start and End Dates', 'error');
        } else if (tasks?.some((i) => i.name === name)) {
            showToast(toast, 'Error', 'A Project with this Name already exists', 'error');
            // } else if ([0, 6]?.includes(new Date(cs_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${cs_date} cannot be contract start date as it is a weekend.`, 'error');
            // } else if ([0, 6]?.includes(new Date(ce_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${ce_date} cannot be contract end date as it is a weekend.`, 'error');
            // } else if ([0, 6]?.includes(new Date(is_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${is_date} cannot be internal start date as it is a weekend.`, 'error');
            // } else if ([0, 6]?.includes(new Date(ie_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${ie_date} cannot be internal end date as it is a weekend.`, 'error');
        } else if (cs_date > ce_date || is_date > ie_date) {
            showToast(toast, 'Error', 'Start Date should be before End Date', 'error');
        } else {
            setTasks([...tasks, newPro])
            if (assigned?.length >= 1) {
                assigned?.forEach((i) => {
                    selectionFun(newPro?.name, i?.name, newPro?.cs_date < newPro?.is_date ? newPro?.cs_date : newPro?.is_date, newPro?.ce_date > newPro?.ie_date ? newPro?.ce_date : newPro?.ie_date)
                })
            }
            setOpenedList((prev) => [...prev, newPro.name])
            setPro({
                name: "",
                cs_date: "",
                ce_date: "",
                is_date: "",
                ie_date: "",
                closed: false,
                assigned: []
            })
            setOpen(false)
        }
    }
    const AssignEmployee = useCallback((empName) => {
        // if (!empName) {
        //     showToast(toast, 'Error', "Select an Employee from the list to assign", "error")
        // } else {
        if (!assigned.some((i) => i.name === empName)) {
            setPro(prevPro => {
                return {
                    ...prevPro,
                    assigned: [...assigned, { name: empName }]
                }
            });
        }

        // }
        // eslint-disable-next-line
    }, [employeeList, assigned])
    const unAssignEmployee = useCallback((empName) => {
        // if (!empName) {
        //     showToast(toast, 'Error', "Select an Employee from the list to unassign", "error")
        // } else {

        if (assigned.some((i) => i.name === empName)) {
            const newList = assigned.filter((k) => k.name !== empName)
            setPro(prevPro => {
                return {
                    ...prevPro,
                    assigned: [...newList]
                }
            });
        }

        // }
        // eslint-disable-next-line
    }, [employeeList, assigned])
    const [empFilter, setEmpFilter] = useState("")
    return (
        <>
            <Modal isOpen={open} >
                <ModalOverlay style={{ zIndex: 10 }} />
                <Draggable cancel=".clickable">
                    <div className="robotic select-none" style={{ fontWeight: 'normal', fontSize: '14px', position: 'fixed', top: 0, zIndex: 10 }}>
                        <ModalContent>
                            <ModalHeader className="bg-slate-200 cursor-move rounded-t-md mb-5 roboto-light" style={{ color: '#3c4043', background: '#f1f3f4', display: 'flex', maxHeight: '40px', alignItems: 'center', justifyContent: 'space-between' }}><Text className="text-[14px] font-normal">Add Project</Text><CloseButton w={'min-content'} h={'min-content'} className="clickable" onClick={() => setOpen(false)} /></ModalHeader>
                            <ModalBody className="clickable" background={'white'} >
                                <Text></Text><Input fontSize={'22px'} color={'#5f6368'} _placeholder={{ color: '#5f6368' }} value={name} onChange={HandleChange} variant='flushed' name={'name'} placeholder='Add Name of Project...' />
                                <Flex justifyContent={'center'} marginTop={3} gap={5} alignItems={'center'}><Text w={'135px'}  >Contract&nbsp;Start:</Text><Input ml={4} onChange={HandleChange} value={cs_date} name="cs_date" placeholder="Contract Start" size="sm" type="date" /></Flex>
                                <Flex justifyContent={'center'} marginTop={2} gap={5} alignItems={'center'}><Text w={'135px'}  >Contract&nbsp;End:</Text><Input ml={4} onChange={HandleChange} value={ce_date} name="ce_date" placeholder="Contract End" size="sm" type="date" /></Flex>
                                <Flex justifyContent={'center'} marginTop={2} gap={5} alignItems={'center'}><Text w={'135px'}  >Internal&nbsp;Start:</Text><Input ml={4} onChange={HandleChange} value={is_date} name="is_date" placeholder="Internal Start" size="sm" type="date" /></Flex>
                                <Flex justifyContent={'center'} marginTop={2} gap={5} alignItems={'center'}><Text w={'135px'}  >Internal&nbsp;End:</Text><Input ml={4} onChange={HandleChange} value={ie_date} name="ie_date" placeholder="Internal End" size="sm" type="date" /></Flex>
                            </ModalBody>

                            {<Box mt={'3vh'} className="clickable" mb={'3vh'} px={'1.5vw'}>
                                <Text className="robotic text-[14px] font-normal mb-2">Add Employee to Project</Text>
                                <Input size={'sm'} value={empFilter} onChange={(e) => setEmpFilter(e.target.value)} placeholder="Search for Employee Name" type="text" />
                                {/* <Select onChange={(e) => setCurrentEmp(e.target.value)} value={currentEmp} variant="filled" placeholder="Assign more Employees"> */}
                                <Flex flexDir={'row'} justifyContent={'start'} rowGap={'5px'} mt={'1vh'} alignItems={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                                    {employeeList?.filter((employee) => employee?.name?.toLowerCase().includes(empFilter.toLowerCase())).map((k, index) => (
                                        !assigned.some((already) => already.name === k.name) && (
                                            <Fragment key={index}>
                                                <Flex h={'25px'} ps={2} pe={'2px'} borderRadius={'5px'} fontSize={'small'} textColor={'white'} columnGap={'3px'} justifyContent={'space-between'} alignItems={'center'} style={{ backgroundColor: k.team }} key={index}>
                                                    <Text fontSize={'12px'} fontWeight={'normal'} className="robotic" color={'#3c4043'}>{k.name}</Text>
                                                    <CiSquarePlus color="#3c4043" cursor={'pointer'} style={{ marginBottom: 1 }} fontSize={'20px'} onClick={() => AssignEmployee(k?.name)} />
                                                </Flex>
                                            </Fragment>
                                        )
                                    ))}
                                </Flex>
                                {assigned.length >= 1 && <Text mb={'1vh'} mt={'3vh'}>Employees Added</Text>}
                                <Flex flexDir={'row'} justifyContent={'start'} rowGap={'5px'} alignItems={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                                    {employeeList?.map((k, index) => (
                                        assigned.some((already) => already.name === k.name) && (
                                            <Fragment key={index}>
                                                <Flex h={'25px'} ps={2} pe={'2px'} borderRadius={'5px'} fontSize={'small'} textColor={'white'} columnGap={'3px'} justifyContent={'space-between'} alignItems={'center'} style={{ backgroundColor: k.team }} key={index}>
                                                    <Text fontSize={'12px'} fontWeight={'normal'} className="robotic" color={'#3c4043'}>{k.name}</Text>
                                                    <CiSquareMinus color="#3c4043" cursor={'pointer'} style={{ marginBottom: 1 }} fontSize={'20px'} onClick={() => unAssignEmployee(k?.name)} />
                                                </Flex>
                                            </Fragment>
                                        )
                                    ))}
                                </Flex>
                            </Box>}
                            <ModalFooter className="clickable">
                                <Button fontWeight={'normal'} fontSize={'14px'} variant='ghost' onClick={() => setOpen(false)}>Cancel</Button>
                                <Button fontWeight={'normal'} fontSize={'14px'} background={"#1A73E8"} colorScheme='blue' ml={3} onClick={AddProject}>
                                    Save
                                </Button>
                            </ModalFooter>


                        </ModalContent>
                    </div >
                </Draggable>
            </Modal>
        </>
    )
}
export default memo(NewProject)