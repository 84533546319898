import { Box, Button, Switch, Text, useToast } from '@chakra-ui/react'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { GoDash } from "react-icons/go"
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { MdEdit } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'

import { Link, useNavigate } from 'react-router-dom'
import Confirmation from '../Components/Confirmation/ConfirmationModel'
import UserContext from '../Context/User/UserContext'
import ShowToast from '../utils/Toast/Toast'
const Accounts = () => {
    const [allUsers, setAllUsers] = useState([])
    useEffect(() => {
        getAllUsers()
    }, [])
    let [sortKey, setSortKey] = useState("lastLogin")
    let [order, setOrder] = useState("desc")
    function filterDatesLast30Days(dates) {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        return dates?.filter(dateString => {
            const date = new Date(dateString);
            return date > thirtyDaysAgo; // Keep dates that are after thirtyDaysAgo
        });
    }
    const getAllUsers = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/allUsers`, { withCredentials: true })
        const updatedData = data?.map(user => ({
            ...user,
            loginCount: filterDatesLast30Days(user?.loginHistory)?.length
        }));
        defaultSort('lastLogin', 'desc', updatedData)
    }

    const activate = async (id) => {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/activate/${id}`, undefined, { withCredentials: true })
        await getAllUsers()
    }
    const deactivate = async (id) => {
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/deactivate/${id}`, undefined, { withCredentials: true })
        await getAllUsers()
    }


    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const openWarning = (userId) => {
        setSelectedUserId(userId); // Set the selected user for deletion
        setIsWarningOpen(true);
    };

    const closeWarning = () => {
        setSelectedUserId(null); // Reset selected user
        setIsWarningOpen(false);
    };


    const { getUserData } = useContext(UserContext)
    const Navigate = useNavigate()
    const toast = useToast()
    const logout = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/logout`, { withCredentials: true })
            await getUserData()
            ShowToast(toast, 'Success', 'Logged out successfully', 'success')
            setTimeout(() => {
                Navigate('/admin/login')
                window.location.reload()
            }, 500);
        } catch (error) {

            console.log(error)
            if (error?.response?.data?.message) {
                ShowToast(toast, 'Error', error?.response?.data?.message, 'error')
            } else {
                ShowToast(toast, 'Error', error?.message, 'error')
            }
        }
    }
    useEffect(() => {
        if (sortKey && order) {
            sortByKey(sortKey, order)
        }
    }, [order, sortKey])

    function sortByKey(key, order) {
        if (order !== "asc" && order !== "desc") {
            throw new Error('Invalid order, please provide "asc" or "desc".');
        }

        const users = allUsers.sort((a, b) => {
            const valA = a[key];
            const valB = b[key];

            // Treat undefined values as null
            const aValue = valA === undefined ? null : valA;
            const bValue = valB === undefined ? null : valB;

            if (order === "asc") {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
        });
        setAllUsers([...users])
    }
    function defaultSort(key, order, data) {
        if (order !== "asc" && order !== "desc") {
            throw new Error('Invalid order, please provide "asc" or "desc".');
        }

        const users = data.sort((a, b) => {
            const valA = a[key];
            const valB = b[key];

            // Treat undefined values as null
            const aValue = valA === undefined ? null : valA;
            const bValue = valB === undefined ? null : valB;

            if (order === "asc") {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
        });
        setAllUsers([...users])
    }




    const deleteAccount = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/delete/${selectedUserId}`, { withCredentials: true })
            ShowToast(toast, 'Success', 'User Deleted successfully', 'success')
            await getAllUsers();
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                ShowToast(toast, 'Error', error?.response?.data?.message, 'error')
            } else {
                ShowToast(toast, 'Error', error?.message, 'error')
            }
        }
    }

    return (
        <Box>
            <Box display={'grid'} placeItems={'end'} m={5}><Button onClick={logout}>Logout</Button></Box>
            {/* <TableContainer mx={2} > */}
            <table width={'100%'} >
                <thead style={{ position: 'sticky', zIndex: 10, top: -1 }}>
                    <tr >
                        <th className='bg-black' style={{ padding: 5, fontSize: '10px', color: 'white', border: '1px solid white' }} cursor={'pointer'} onClick={() => { if (sortKey === "status" && order === "asc") { setOrder("desc") } else { setSortKey("status"); setOrder("asc"); } }} ><Box className='flex flex-row justify-between'><Text>STATUS </Text><Text>{sortKey === "status" && order === "asc" ? <IoIosArrowDown /> : sortKey === "status" && order === "desc" ? <IoIosArrowUp /> : <GoDash />}</Text></Box></th>
                        <th className='bg-black' style={{ padding: 5, fontSize: '10px', color: 'white', border: '1px solid white' }}>GANTT</th>
                        <th className='bg-black' style={{ padding: 5, fontSize: '10px', color: 'white', border: '1px solid white' }} cursor={'pointer'} onClick={() => { if (sortKey === "name" && order === "asc") { setOrder("desc") } else { setSortKey("name"); setOrder("asc"); } }} ><Box className='flex flex-row justify-between'><Text>COMPANY NAME </Text><Text>{sortKey === "name" && order === "asc" ? <IoIosArrowDown /> : sortKey === "name" && order === "desc" ? <IoIosArrowUp /> : <GoDash />}</Text></Box></th>
                        <th className='bg-black' style={{ padding: 5, fontSize: '10px', color: 'white', border: '1px solid white' }} cursor={'pointer'} onClick={() => { if (sortKey === "email" && order === "asc") { setOrder("desc") } else { setSortKey("email"); setOrder("asc"); } }} ><Box className='flex flex-row justify-between'><Text>EMAIL </Text><Text>{sortKey === "email" && order === "asc" ? <IoIosArrowDown /> : sortKey === "email" && order === "desc" ? <IoIosArrowUp /> : <GoDash />}</Text></Box></th>
                        <th className='bg-black' style={{ padding: 5, fontSize: '10px', color: 'white', border: '1px solid white' }} cursor={'pointer'} onClick={() => { if (sortKey === "lastLogin" && order === "asc") { setOrder("desc") } else { setSortKey("lastLogin"); setOrder("asc"); } }} ><Box className='flex flex-row justify-between'><Text>LAST LOGIN </Text><Text>{sortKey === "lastLogin" && order === "asc" ? <IoIosArrowDown /> : sortKey === "lastLogin" && order === "desc" ? <IoIosArrowUp /> : <GoDash />}</Text></Box></th>
                        <th className='bg-black' style={{ padding: 5, fontSize: '10px', color: 'white', border: '1px solid white' }} cursor={'pointer'} onClick={() => { if (sortKey === "loginCount" && order === "asc") { setOrder("desc") } else { setSortKey("loginCount"); setOrder("asc"); } }} ><Box className='flex flex-row justify-between'><Text>30 D LOGS </Text><Text>{sortKey === "loginCount" && order === "asc" ? <IoIosArrowDown /> : sortKey === "loginCount" && order === "desc" ? <IoIosArrowUp /> : <GoDash />}</Text></Box></th>
                        <th className='bg-black' style={{ padding: 5, fontSize: '10px', color: 'white', border: '1px solid white' }} cursor={'pointer'} onClick={() => { if (sortKey === "createdAt" && order === "asc") { setOrder("desc") } else { setSortKey("createdAt"); setOrder("asc"); } }} ><Box className='flex flex-row justify-between'><Text>CREATED </Text><Text>{sortKey === "createdAt" && order === "asc" ? <IoIosArrowDown /> : sortKey === "createdAt" && order === "desc" ? <IoIosArrowUp /> : <GoDash />}</Text></Box></th>
                        <th className='bg-black' style={{ padding: 5, fontSize: '10px', color: 'white', border: '1px solid white' }} >DELETE </th>
                    </tr>
                </thead>
                <tbody >
                    {allUsers?.map((i, ind) => {
                        const thisDate = new Date(i?.lastLogin);
                        const thisDate2 = new Date(i?.createdAt);


                        // Format the thisDate as desired (e.g., MM/DD/YYYY HH:MM:SS)
                        const formattedDate = `${String(thisDate.getDate()).padStart(2, '0')}/${String(thisDate.getMonth() + 1).padStart(2, '0')}/${thisDate.getFullYear()}`;
                        const creationDate = `${String(thisDate2.getDate()).padStart(2, '0')}/${String(thisDate2.getMonth() + 1).padStart(2, '0')}/${thisDate2.getFullYear()}`;


                        return <tr >
                            <td style={{ width: '100px', padding: 5, background: ind % 2 === 0 ? 'transparent' : '#EEEEEE', border: '1px solid black' }} cursor={'pointer'}><Box style={{ textAlign: 'center', width: '100%' }} onClick={() => { i.status ? deactivate(i._id) : activate(i._id) }} h={'25px'} textColor={'white'} transition={'all 0.25s ease-in-out'} w={'25px'} display={'grid'} placeItems={'center'} fontSize={'1.3rem'} textAlign={'center'} >
                                <Switch isChecked={i?.status} colorScheme='green' id='email-alerts' />
                            </Box>
                            </td>
                            <td className='border-[2px] w-[100px]' style={{ padding: 5, background: ind % 2 === 0 ? 'transparent' : '#EEEEEE', border: '1px solid black' }} ><Link to={`/admin/gantt/${i._id}`} className='hover:text-blue-400 flex justify-center align-middle text-[22px]'><MdEdit /></Link></td>
                            <td className='border-[2px]' style={{ padding: 5, background: ind % 2 === 0 ? 'transparent' : '#EEEEEE', border: '1px solid black' }} >{i?.name}</td>
                            <td className='border-[2px]' style={{ padding: 5, background: ind % 2 === 0 ? 'transparent' : '#EEEEEE', border: '1px solid black' }} >{i?.email}</td>
                            <td className='border-[2px] w-[100px] text-center' style={{ padding: 5, background: ind % 2 === 0 ? 'transparent' : '#EEEEEE', border: '1px solid black' }} >{i?.lastLogin && formattedDate}</td>
                            <td className='border-[2px] w-[100px] text-center' style={{ padding: 5, background: ind % 2 === 0 ? 'transparent' : '#EEEEEE', border: '1px solid black' }} >{i?.loginCount}</td>
                            <td className='border-[2px] w-[100px] text-center' style={{ padding: 5, background: ind % 2 === 0 ? 'transparent' : '#EEEEEE', border: '1px solid black' }} >{i?.createdAt && creationDate}</td>
                            <td style={{ padding: 5, background: ind % 2 === 0 ? 'transparent' : '#EEEEEE', border: '1px solid black' }} className='border-[2px] w-[100px]  hover:text-red-500 ' cursor={'pointer'} onClick={() => openWarning(i._id)}><Box className='flex justify-center align-middle text-[22px]'><RiDeleteBin6Line cursor={'pointer'} className='hover:text-red-500 ' /></Box>
                                <Confirmation isOpen={isWarningOpen} onClose={closeWarning} onConfirm={() => { deleteAccount(); closeWarning(); }} />
                            </td>
                        </tr>
                    })}


                </tbody>
            </table>
            {/* </TableContainer> */}
        </Box >




    )
}

export default Accounts