import { Box, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';

const TooltipExample = ({ children, label }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTouch = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };

    return (
        <Box onTouchStart={handleTouch} onMouseEnter={() => setIsTooltipOpen(true)} onMouseLeave={() => setIsTooltipOpen(false)}>
            <Tooltip
                label={label}
                isOpen={isTooltipOpen}
                color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow
            >
                {children}
            </Tooltip>
        </Box>
    );
};
export default TooltipExample