import { ChakraProvider } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import App from './App';
import User from './Context/User/User'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <User>
        <DndProvider backend={HTML5Backend}>
            <ChakraProvider>
                <App />
            </ChakraProvider>
        </DndProvider>
    </User>
);

