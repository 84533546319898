import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { Suspense, useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../GanttChart.css';
import logo from '../assets/logo.png'
import GanttChartView from '../GanttChartView';
import { calendarLength, currName, employees, holidays, optimalDates, projects, records } from '../atoms';
import parseCustomDate from '../utils/Functions/parseCustomDate';
import axios from 'axios';
import UserContext from '../Context/User/UserContext';
import ShowToast from '../utils/Toast/Toast';
function GanttPageView() {
  const [proj, setProjects] = useAtom(projects)
  const [, setRecords] = useAtom(records)
  const [, setHolidays] = useAtom(holidays)
  const [, setEmployees] = useAtom(employees)
  const [, setName] = useAtom(currName)
  const [, setOptimalDate] = useAtom(optimalDates)
  const { setView } = useContext(UserContext)
  const [totalLength] = useAtom(calendarLength)

  const { key, name } = useParams()
  const toast = useToast()

  const viewChart = async (key) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/charts/find/${name}/${key}`)
      setRecords(data?.records)
      setProjects(data?.projects)
      setHolidays(data?.holidays)
      setEmployees(data?.employees)
      setName(data?.user?.name)
      setView(true)
    } catch (error) {
      if (error?.response?.data?.message) {
        ShowToast(toast, 'Error', error?.response?.data?.message, 'error')
      } else {
        ShowToast(toast, 'Error', error?.message, 'error')
      }
    }
  }

  useEffect(() => {
    viewChart(key)
  }, [])

  const optimalDatesCalc = useCallback(() => {
    if (proj.length >= 1) {
      let optimal = {
        is_date: new Date(proj[0]?.is_date),
        cs_date: new Date(proj[0]?.cs_date),
        ie_date: new Date(proj[0]?.ie_date),
        ce_date: new Date(proj[0]?.ce_date),
      };

      for (let i = 1; i < proj.length; i++) {
        const project = proj[i];
        optimal.is_date = new Date(Math.min(optimal?.is_date.getTime(), parseCustomDate(project.is_date).getTime()));
        optimal.cs_date = new Date(Math.min(optimal?.cs_date.getTime(), parseCustomDate(project.cs_date).getTime()));
        optimal.ie_date = new Date(Math.max(optimal?.ie_date.getTime(), parseCustomDate(project.ie_date).getTime()));
        optimal.ce_date = new Date(Math.max(optimal?.ce_date.getTime(), parseCustomDate(project.ce_date).getTime()));
      }
      setOptimalDate(optimal);
    }
    // eslint-disable-next-line
  }, [proj]);

  useEffect(() => {
    optimalDatesCalc()
    // eslint-disable-next-line
  }, [proj])



  return (
    <Box className='flex flex-col mt-[2vh]' >
      {window.innerWidth >= 900 ? <Box position={'sticky'} w={`${(window.outerWidth * 15 / 100) + (totalLength * 22.245)}px`} py={'15px'} borderBottom={'1px solid #eeeeee'} mb={'15px'} left={'16vw'} id='glass'>
        <Button overflow={'hidden'} background={'white'} color={'#3c4043'} className='robotic' _hover={{ background: '#EEEEEE' }} h={'35px'} border={'none'} position={'sticky'} left={'0vw'}>
          <img width={'100px'} src={logo} />
        </Button>
        <Button background={'white'} border={'none'} color={'#3c4043'} className='robotic' _hover={{ background: 'white' }} h={'35px'} position={'sticky'} left={'12.5vw'}>
          <Text display={'flex'} fontSize={'20px'} className='robotic text-[20px] font-normal' alignItems={'center'} zIndex={30} w={'100%'}>{name.replaceAll('_',' ')}</Text>
        </Button>
      </Box> : null}
      <Suspense fallback={<Box background={'black'}>Loading</Box>}>
        <GanttChartView />
      </Suspense>
    </Box>
  );
}

export default GanttPageView;
