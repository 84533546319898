import { Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import { isAfter, isBefore, isSameDay, isWeekend } from 'date-fns'
import { useAtom } from 'jotai'
import React, { useCallback, useContext } from 'react'
import UserContext from '../../Context/User/UserContext'
import absent_Emp from '../../Icons/Absent_Employee.png'
import absent_General from '../../Icons/Absent_General.png'
import DeliveredPro from '../../Icons/Delivered_Project.png'
import { projects } from '../../atoms'
import { checkSameDay, getUTCDate } from '../../utils/Functions/dateFunctions'


const ClosedProjects = ({ calendarData }) => {
  const [projectList] = useAtom(projects)
  const { openedList } = useContext(UserContext)
  const getEmployeeCountForDateAndProject = useCallback((date, project) => {
    let count = 0;
    Object?.values(project?.records)?.forEach((i) => {
      if (i?.some((assignment) => assignment?.project === project.name && assignment?.dates !== null && assignment?.dates?.some((j) => isSameDay(j, date)))) {
        count++
      }
    })
    return count
  }, [])

  const sameTeam = useCallback((date, project) => {
    let team = [];
    Object.values(project.records).forEach((i, ind) => {
      if (i?.some((assignment) => assignment?.project === project.name && assignment?.dates?.some((j) => isSameDay(j, date)))) {
        team.push(Object.keys(project.records)[ind]);
      }
    })
    if (team?.length === 1) {
      return getTeamByName(team[0], project)
    } else {
      const modifiedTeam = team.map(getTeamByName);
      const uniqueSet = new Set(modifiedTeam);
      return !(uniqueSet.size === modifiedTeam.length) && uniqueSet.size === 1 ? getTeamByName(team[0], project) : false;
    }
  }, [])

  const getTeamByName = useCallback((emp, project) => {
    return project.assignedDetails?.find((i) => i?.name === emp)?.team
  }, [])

  const getNextWorkingDay = useCallback((curr) => {
    let nextDay = curr;
    nextDay.setDate(nextDay.getDate() + 1);

    if (isWeekend(nextDay)) {
      return getNextWorkingDay(nextDay);
    }
    return nextDay;
  }, [])

  const convertObjectToDate = useCallback((inputObject) => {
    const utcTimestamp = Date.UTC(inputObject?.year, inputObject.monthInd, inputObject?.date, 0, 0, 0, 0);
    const utcDate = new Date(utcTimestamp);
    return utcDate.toUTCString();
  }, [])

  const findAssignment = useCallback((project, empName, singleDate) => {
    if (!project.records.hasOwnProperty(empName)) {
      return false
    }
    const employeeAssignments = project.records[empName];

    const bool = employeeAssignments?.findIndex((assignment) => assignment?.project === project?.name);
    if (employeeAssignments) {
      if (bool !== -1) {
        const projectAssignment = employeeAssignments?.find((assignment) => assignment.project === project.name);
        const isDatePresent = projectAssignment.dates?.some((i) => isSameDay(i, singleDate));
        if (isDatePresent) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }, [])

  return (
    <>   {projectList?.filter((i) => i.closed === true)?.sort((a, b) => new Date(b.close_date) - new Date(a.close_date)) // Sort projects based on close_date
      .map((i, ind) => {
        let start = i.cs_date < i.is_date ? i.cs_date : i.is_date
        let end = i.ce_date > i.ie_date ? i.ce_date : i.ie_date
        start = getUTCDate(start)
        end = getUTCDate(end)
        let sameday = false;
        return <Box key={ind}>
          <Flex bg={'#eeeeee'} key={ind}>{calendarData?.map((j, indC) => {
            const currDay = convertObjectToDate(j)
            const employeeCount = getEmployeeCountForDateAndProject(currDay, i)
            const date = new Date()
            sameday = checkSameDay(date, j)

            const inRange = (isBefore(start, currDay) || isSameDay(start, currDay)) && (isAfter(end, currDay) || isSameDay(end, currDay))
            return (
              <Box ml={indC % 5 === 0 ? '3px' : '1px'} borderBottom={'1px solid #eeeeee'} h={'22px'} background={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'} className='box' key={indC}>
                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                <Box outlineOffset={'-2px'} outline={isSameDay(i.ce_date, currDay) && isSameDay(i.ie_date, currDay) ? '2px solid #3c4043' : isSameDay(i.ce_date, currDay) ? '2px solid #3c4043' : isSameDay(i.ie_date, currDay) ? '2px solid red' : 'none'} borderRadius={'5px'}>

                  {isSameDay(i?.close_date, currDay) ?
                    <Box h={'21px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'}  >
                      <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"Project Delivery Date"} >
                        <Box bg={'#ec5045'} height={'18px'} width={'18px'} borderRadius={'10px'}></Box>
                        {/* <img src={DeliveredPro} alt='delivered' /> */}
                      </Tooltip>
                    </Box> :

                    inRange ? <Box borderLeftRadius={isSameDay(start, currDay) ? '5px' : ''} borderRightRadius={isSameDay(end, currDay) ? '5px' : ''} background={(employeeCount === 0 ? 'yellow' : sameTeam(currDay, i) !== false ? sameTeam(currDay, i) : 'grey')} height={'21px'} key={ind} w={'21px'} textAlign={'center'} p={'2px'}>
                      {isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) || isSameDay(i.ce_date, currDay) || isSameDay(i.ie_date, currDay) ?
                        <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={isSameDay(i.cs_date, currDay) ? "Contract Start Date" : isSameDay(i.ce_date, currDay) ? "Contract End Date" : isSameDay(i.is_date, currDay) ? "Internal Start Date" : "Internal End Date"} >
                          <Box outlineOffset={'-1px'} h={'17px'} w={'17px'} borderRadius={isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) ? '50px' : 'none'} outline={isSameDay(i.is_date, currDay) && isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : isSameDay(i.is_date, currDay) ? '2px solid red' : isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : 'none'}>
                            <Text color={'#3c4043'} fontSize={'12px'}>{employeeCount}
                            </Text>
                          </Box>
                        </Tooltip> :
                        <Box outlineOffset={'-1px'} h={'17px'} w={'17px'} borderRadius={isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) ? '50px' : 'none'} outline={isSameDay(i.is_date, currDay) && isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : isSameDay(i.is_date, currDay) ? '2px solid red' : isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : 'none'}>
                          <Text color={'#3c4043'} fontSize={'12px'}>{employeeCount}
                          </Text>
                        </Box>}
                    </Box>
                      :
                      <Box w='21px'></Box>
                  }
                </Box>
              </Box>
            )
          })}
          </Flex>
          {
            openedList?.some((single) => single === i.name) && i?.assigned?.map((empId, index) => {
              const curr = i?.assignedDetails?.find((emp) => emp.name === empId.name)
              let sameday = false;
              return (<Flex bg={'#eeeeee'} key={index}>{calendarData?.map((l, indC) => {
                const converted = convertObjectToDate(l);
                const absentee = curr?.absent_dates?.some((day) => isSameDay(day, converted))
                const globalHoliday = i?.holidaysList?.some((day) => isSameDay(day, converted))
                const date = new Date()
                sameday = checkSameDay(date, l)
                const inRange = (isBefore(start, converted) || isSameDay(start, converted)) && (isAfter(end, converted) || isSameDay(end, converted))
                return (
                  inRange ? (globalHoliday ?
                    (<Box bg={'white'} height={'20px'} ms={indC % 5 === 0 ? '3px' : '1px'} borderBottom={'1px solid #eeeeee'} key={indC}>
                      {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '21px', }} />}
                      {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '21px', }} />}
                      <Box textColor={'white'} transition={'all 0.25s ease-in-out'} height={'20px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                        <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"General Absent Day"} >
                          {/* <img width={'18px'} height={'18px'} src={absent_General} /> */}
                          <Box bg={'#e1e1e1'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                        </Tooltip>
                      </Box>
                    </Box>)
                    : absentee ?
                      (<Box bg={'white'} height={'20px'} borderBottom={'1px solid #eeeeee'} ms={indC % 5 === 0 ? '3px' : '1px'} key={indC}>
                        {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '21px', }} />}
                        {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '21px', }} />}
                        <Box opacity={openedList?.some((single) => single === i.name) ? 1 : 0} textColor={'white'} transition={'all 0.25s ease-in-out'} height={'20px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                          <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"Employee Absent Day"} >
                            <Box bg={'#3c4043'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                            {/* <img width={'18px'} height={'18px'} src={absent_Emp} /> */}
                          </Tooltip>
                        </Box>
                      </Box>)
                      : inRange ?
                        (<Box bg={'white'} height={'20px'} borderBottom={'1px solid #eeeeee'} ms={indC % 5 === 0 ? '3px' : '1px'} w={'21px'} key={indC} >
                          {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '21px', }} />}
                          {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '21px', }} />}
                          {findAssignment(i, curr?.name, converted) ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'20px'} borderRadius={'5px'} >
                              <Box mb={'auto'} background={curr?.team} h={'19px'} w={'20px'} borderRadius={'5px'}></Box>
                            </Box> :
                            <Box flexDir={'column'} height={'20px'} borderRadius={'5px'} w={'21px'} >
                              {<Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={`Unused Employee`} >
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h={'100%'} ><Box h={'17px'} bg={'#ffff00'} borderRadius={'10px'} w={'17px'}></Box> </Box></Tooltip>
                              }
                            </Box>}
                        </Box>) : null) :
                    (<Box bg={'white'} height={'20px'} borderBottom={'1px solid #eeeeee'} ms={indC % 5 === 0 ? '3px' : '1px'} key={indC} flexDir={'column'}>
                      {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '21px', }} />}
                      {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '21px', }} />}
                      <Box height={'20px'} w={'21px'} fontSize={'12px'} textAlign={'center'} p={'2px'}></Box>
                    </Box>))
              })}
              </Flex>)
            }
            )
          }
        </Box >
      })}</>
  )
}

export default ClosedProjects