import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { VscLoading } from 'react-icons/vsc';
import profileImage from "../../../assets/profile.jpg";
import ShowToast from '../../../utils/Toast/Toast';
import { useNavigate } from 'react-router-dom'
import logo from '../../../assets/logo.png'
const ForgotPassword = () => {
    const toast = useToast()
    const Navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const forgotFun = async () => {
        const regex = /^[A-Z0-9.+-_]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const isValid = regex.test(email);
        if (!email || !isValid) {
            ShowToast(toast, 'Error', 'Enter a valid Email')
        } else {
            try {
                setLoading(true)
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/forgot-password`, { email: email })
                setLoading(false)
                ShowToast(toast, 'Success', 'Email sent - Check your Email box')
                setEmail("")
            } catch (error) {
                console.log(error)
                if (error?.response?.data?.message) {
                    ShowToast(toast, 'Error', error?.response?.data?.message)
                } else {
                    ShowToast(toast, 'Error', error?.message)
                }
                setLoading(false)
            }
        }
    }
    return (
        <div>
            <section className=" min-h-[90vh] dark:bg-gray-900">
                <div className="flex flex-col items-center justify-center mt-[10vh] px-6 py-8 mx-auto lg:py-0">
                    <div className="mt-[2vh] flex items-center mb-2 text-2xl font-semibold ">
                        <img src={logo} width={'150px'} alt="" />
                    </div>
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0  dark:border-gray-700">
                        <div className="mt-1 p-2 space-y-2 md:space-y-4 sm:p-8">
                            <h1 className="robotic px-3 text-[14px]  ">
                                Please fill in your email and we will send you an email with instructions to reset your password.
                            </h1>



                            <div className='px-3'>
                                <label
                                    htmlFor="userId"
                                    className="robotic text-[14px] "
                                >
                                    Your email address:
                                </label>
                                <input onChange={(e) => setEmail(e.target.value)}
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="bg-gray-50  border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={email}
                                />
                            </div>


                            <div className="w-full rounded-lg  xl:p-0 dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between">
                                <div className=" flex flex-row justify-end ">
                                    <button
                                        onClick={forgotFun}
                                        className="bg-[#1A73E8] w-full text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 "
                                    >
                                        RESET PASSWORD {loading && <VscLoading className='animate-spin' />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>)
}

export default ForgotPassword