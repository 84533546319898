import { Box, CloseButton, Flex, Text } from "@chakra-ui/react";

export default function ShowToast(toast, title, description, status, duration = 3000) {
    toast({
        title: title,
        description: description,
        status: status,
        duration: 10000,
        isClosable: true,
        backgroundColor: "green.500",
        render: ({ onClose }) => (
            <Flex
                p={3}
                bg={"#212125"}
                borderRadius="md"
                justifyContent={'space-between'}
                alignItems={'center'}
                // color={'white'}
            >
                {/* <Text fontWeight="bold">{title}</Text> */}
                <Text className="roboto-light text-[14px] text-[#e8eaed]" >{description}</Text>
                <CloseButton
                    className="text-[#e8eaed]"
                    onClick={onClose}
                />

            </Flex>
        )
    });
}