import { Box, Button, CloseButton, Divider, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useAtom } from 'jotai';
import Draggable from 'react-draggable';
import { v4 as uuidv4 } from 'uuid';
import { keys } from '../../atoms';
import { useContext } from 'react';
import deleteIcon from '../../Icons/Delete.png'
import UserContext from '../../Context/User/UserContext';

import { MdContentCopy } from "react-icons/md";
import ShowToast from '../../utils/Toast/Toast';
const GenerateKeys = ({ open, setOpen }) => {
    // const [expiry, setExpiry] = useState('');
    // const [generatedKey, setGenKey] = useState('');
    const { getChart, user } = useContext(UserContext)
    const [keyList] = useAtom(keys)
    const generateKey = async () => {
        const key = uuidv4();
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/charts/generateShareKey`, { key }, { withCredentials: true })
        await getChart()
    }

    const deleteKey = async () => {
        const key = uuidv4();
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/charts/deleteKey`, { key }, { withCredentials: true })
        await getChart()
    }
    const toast = useToast()

    const copyLink = async (text) => {
        navigator.clipboard.writeText(text).then(() => {
            ShowToast(toast, 'Info', 'Text copied to clipboard!', 'info');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }





    return (
        <>
            <Modal isOpen={open}>
                <ModalOverlay style={{ zIndex: 10 }} />
                <Draggable cancel=".clickable">
                    <div className="robotic select-none" style={{ fontSize: '14px', fontWeight: 'normal', position: 'fixed', top: '2vh', zIndex: 10 }}>
                        <ModalContent>
                            <ModalHeader className="bg-slate-200 cursor-move rounded-t-md mb-[5px] roboto-light" style={{ color: '#3c4043', background: '#f1f3f4', display: 'flex', maxHeight: '40px', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Text className="text-[14px] font-normal">Share Your ProjektPlan</Text><CloseButton className="clickable" style={{width:'min-content',height:'min-content'}} onClick={() => setOpen(false)} />
                            </ModalHeader>
                            <ModalBody className="clickable mb-[30px]">
                                <Button colorScheme='teal' w={'100%'} className='robotic text-[14px] font-normal' style={{ fontWeight: 'normal' }} bg={'#1A73E8'} _hover={{bg:'#1A73E8'}} mt={7} onClick={generateKey}>Generate New Shareable Link</Button>
                                {keyList.length >= 1 ? <></> : <Text className='robotic text-[15px] text-red-900 text-center mt-[30px]'>No Current Active Shareable Link</Text>}
                                {keyList?.map((i, ind) => {
                                    const thisDate = new Date(i?.generationDate);
                                    const formattedDate = `${thisDate.getHours()}:${thisDate.getMinutes()} ${thisDate.getDate()}/${thisDate.getMonth() + 1}/${thisDate.getFullYear()}`;

                                    return <Box key={ind}>
                                        <Text className='robotic text-[15px] mt-[20px]'>Current Active Shareable Link - {formattedDate}</Text>
                                        <Text mb={2} fontSize={'14px'}><a style={{ color: 'blue', wordBreak: 'break-all', overflowWrap: 'break-word' }} target='_blank' href={`https://gantt-chart-project.vercel.app/${user.name.replace(/ /g, '_')}/${i?.key}`} textColor={'blue'}  >https://gantt-chart-project.vercel.app/{user.name.replace(/ /g, '_')}/{i?.key}</a></Text>
                                        <Flex mt={'10px'} justifyContent={'space-between'}>
                                            <Flex onClick={deleteKey} cursor={'pointer'} h={'20px'}><Text className='robotic me-[5px] '>Delete Link</Text> <img src={deleteIcon} /></Flex>
                                            <Flex onClick={() => copyLink(`https://gantt-chart-project.vercel.app/${user.name.replace(/ /g, '_')}/${i?.key}`)} cursor={'pointer'} h={'20px'}><Text className='robotic me-[10px]'>Copy Link</Text><MdContentCopy fontSize={'20px'} /></Flex>
                                        </Flex>
                                    </Box>
                                })}
                            </ModalBody>


                        </ModalContent>
                    </div>
                </Draggable>
            </Modal>
        </>
    )
}

export default GenerateKeys