import { atom } from 'jotai';
export const projects = atom([]);
export const employees = atom([]);
export const teams = atom([
    , "#BBDEFB", "#FFE0B2", "#C8E6C9", "#E1BEE7", "#FFECB3", "#B2EBF2", "#F8BBD0", "#F0F4C3", "#FFCCBC", "#C5CAE9",
    "#7986CB", "#FF8A65", "#DCE775", "#F06292", "#4DD0E1", "#FFD54F", "#BA68C8", "#81C784", "#FFB74D", "#64B5F6",
    , "#2196F3", "#FF9800", "#4CAF50", "#9C27B0", "#FFC107", "#00BCD4", "#E91E63", "#CDDC39", "#FF5722", "#3F51B5",]);
export const holidays = atom([]);
export const records = atom([]);
export const optimalDates = atom({
    is_date: '',
    cs_date: '',
    ie_date: '',
    ce_date: '',
})
export const calendarLength = atom(0)
export const keys = atom([])
export const currName = atom("")
