import { Box, Button, Flex, Text, Tooltip, useToast } from '@chakra-ui/react';
import { differenceInBusinessDays, getISOWeek, isAfter, isBefore, isSameDay, isWeekend } from 'date-fns';
import { useAtom } from 'jotai';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { LuChevronRightCircle } from "react-icons/lu";
import NewEmployee from './Components/Employees/NewEmployee';
import OverViewFilter from './Components/Employees/OverViewFilter';
import EmployeeList from './Components/Employees/Overview/EmployeeList';
import ClosedProjects from './Components/Projects/ClosedProjects';
import EditProject from './Components/Projects/EditProject';
import ProjectsList from './Components/Projects/NameDetails/ProjectsList';
import NewProject from './Components/Projects/NewProject';
import UserContext from './Context/User/UserContext';
import DraggableBox from './DND/DraggableBox';
import { itemTypes } from './DragTypes/Itemtypes';
import { calendarLength, employees, holidays, optimalDates, projects, records } from './atoms';
import { checkSameDay, getUTCDate } from './utils/Functions/dateFunctions';
import ShowToast from './utils/Toast/Toast';

const GanttChart = () => {
  const [firstTime, setFirstTime] = useState(0)
  const [employeeList] = useAtom(employees);
  const [collapseAllOverview, setCollapseAllOverview] = useState(false)

  const [collapse, setCollapse] = useState(true);
  const [projectList] = useAtom(projects);
  const [calendarData, setCalendarData] = useState([]);
  const [overFilter, setOverFilter] = useState([])

  const [optimalDate] = useAtom(optimalDates);
  const [holidaysList] = useAtom(holidays);
  const [recordList, setRecords] = useAtom(records);
  const { collapseAllClosed, setCollapseAllClosed, openedList, setOpenedList } = useContext(UserContext)

  const toggleItem = (item) => {
    const isOpened = openedList.includes(item);
    if (!isOpened) {
      setOpenedList((prev) => [...prev, item]);
    } else {
      const updatedList = openedList.filter(existingItem => existingItem !== item);
      setOpenedList(updatedList);
    }
  };
  const addItem = (item) => {
    const isOpened = openedList.some((i) => i === item);
    if (!isOpened) {
      setOpenedList((prev) => [...prev, item]);
    }
  };
  useEffect(() => {
    if (collapse) {
      const openProjectsNames = projectList
        .filter(project => openedList.some((pro) => pro === project.name) && project?.closed === true)
        .map(project => project.name);
      setOpenedList(openProjectsNames)
    } else {
      const openProjectsNames = projectList
        .filter(project => !project?.closed === true)
        .map(project => project.name);
      setOpenedList([...openedList, ...openProjectsNames])
    }
  }, [collapse])
  useEffect(() => {
    if (!collapseAllClosed) {
      const openProjectsNames = projectList
        .filter(project => openedList.some((pro) => pro === project.name) && !project?.closed === true)
        .map(project => project.name);
      setOpenedList(openProjectsNames)
    }
  }, [collapseAllClosed])

  const [, setCalendarLength] = useAtom(calendarLength)



  const [empExpand, setEmpExpand] = useState(false)
  const [selection, setSelection] = useState({ projectName: "", employeeName: "", startDate: "", endDate: "" });
  const { projectName, employeeName, startDate, endDate } = selection;
  const toast = useToast()

  useEffect(() => {
    createCalendarData(optimalDate.cs_date < optimalDate.is_date ? optimalDate.cs_date : optimalDate.is_date, optimalDate.ce_date > optimalDate.ie_date ? optimalDate.ce_date : optimalDate.ie_date);
    // eslint-disable-next-line
  }, [optimalDate]);

  const createCalendarData = useCallback((startDate, endDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    startDate = new Date(startDate);
    startDate = startDate < new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000) ? startDate : new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);


    endDate = new Date(endDate);
    const maxEndDate = new Date(today.getTime() + 10 * 7 * 24 * 60 * 60 * 1000);
    endDate = endDate > maxEndDate ? endDate : maxEndDate;

    startDate = new Date(startDate.setDate(startDate.getDate() - startDate.getDay() + 1));
    endDate = new Date(endDate.setDate(endDate.getDate() + (7 - endDate.getDay())));
    const newCalendarData = [];

    while (startDate <= endDate) {
      if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
        const dayData = {
          date: startDate.getDate(),
          month: startDate.toLocaleString('default', { month: 'long' }),
          monthInd: startDate.getMonth(),
          year: startDate.getFullYear(),
          // isSameDay: isSameDay(new Date().setHours(0, 0, 0, 0), startDate)
        };
        newCalendarData.push(dayData);
      }
      startDate.setDate(startDate.getDate() + 1);
    }
    setFirstTime((prev) => prev + 1)
    setCalendarData(newCalendarData);
    setCalendarLength(newCalendarData.length)
  }, [])
  const convertObjectToDate = useCallback((inputObject) => {
    const utcTimestamp = Date.UTC(inputObject?.year, inputObject.monthInd, inputObject?.date, 0, 0, 0, 0);
    const utcDate = new Date(utcTimestamp);
    return utcDate.toUTCString();
  }, [])

  const assignEmpAtDate = useCallback((projectName, empName, singleDate, more) => {
    setRecords((prevAssignments) => {
      const updatedAssignments = { ...prevAssignments };
      if (!updatedAssignments.hasOwnProperty(empName)) {
        updatedAssignments[empName] = [];
        const employeeAssignments = updatedAssignments[empName];
        const newProjectAssignment = { project: projectName, dates: [singleDate] };
        employeeAssignments.push(newProjectAssignment);
      } else {
        const employeeAssignments = updatedAssignments[empName];
        //checks if employee is assigned to some other project
        if (employeeAssignments?.some((assignment) => (assignment.project !== projectName && assignment.dates?.some((i) => isSameDay(i, singleDate))))) {
          // const ProName = (employeeAssignments?.find((assignment) => (assignment.project !== projectName && assignment.dates?.find((i) => isSameDay(i, singleDate)))))?.project
          // !more && ShowToast(toast, 'Error', `${empName} is Already Assigned on this Date to Project: "${ProName}".`, 'error')
        } else {
          const existingProjectIndex = employeeAssignments.findIndex((assignment) => assignment.project === projectName);
          if (existingProjectIndex !== -1) {
            if (employeeAssignments[existingProjectIndex].dates?.some((i) => isSameDay(i, singleDate))) {
              employeeAssignments[existingProjectIndex].dates = employeeAssignments[existingProjectIndex].dates.filter((i) => !(isSameDay(i, singleDate)));
            } else {
              employeeAssignments[existingProjectIndex].dates.push(singleDate);
            }
          } else {
            const newProjectAssignment = { project: projectName, dates: [singleDate] };
            employeeAssignments.push(newProjectAssignment);
          }
        }
      }

      return updatedAssignments;
    });
  }, [recordList])

  const findAssignment = useCallback((projectName, empName, singleDate) => {
    if (!recordList.hasOwnProperty(empName)) {
      return false
    }
    const employeeAssignments = recordList[empName];

    const bool = employeeAssignments.findIndex((assignment) => assignment.project === projectName);
    if (employeeAssignments) {
      if (bool !== -1) {
        const projectAssignment = employeeAssignments.find((assignment) => assignment.project === projectName);
        const isDatePresent = projectAssignment.dates?.some((i) => isSameDay(i, singleDate));
        if (isDatePresent) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }, [recordList])

  const getDatesInRangeExcludingWeekends = useCallback((startDate, endDate, emp) => {
    const result = [];

    let currentDate = startDate
    currentDate = new Date(currentDate);
    currentDate = currentDate.toISOString();

    endDate = new Date(endDate);
    endDate = endDate.toISOString();
    while (isBefore(currentDate, endDate) || isSameDay(currentDate, endDate)) {


      const dayOfWeek = new Date(currentDate).getUTCDay();
      if (dayOfWeek !== 6 && dayOfWeek !== 0 && !(holidaysList?.some((i) => isSameDay(i, currentDate))) && !(emp.absent_dates?.some((i) => isSameDay(i, currentDate)))) {
        result.push(new Date(currentDate));
      }
      currentDate = new Date(currentDate);
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate = currentDate.toISOString();
    }
    return result;
  }, [recordList]);
  const checkAssignment = useCallback((empName, singleDate) => {
    const employeeAssignments = recordList[empName];
    if (!employeeAssignments) {
      return false
    }
    //checks if employee is assigned to some other project
    if (employeeAssignments?.some((assignment) => (assignment.project !== projectName && assignment.dates?.some((i) => isSameDay(i, singleDate))))) {
      return true
    }
    return false
  }, [recordList])

  const selectionFun = useCallback((proj, emp, curr) => {
    if (projectName === "" && employeeName === "" && startDate === "" && endDate === "") {
      const data = { projectName: proj, employeeName: emp.name, startDate: curr, endDate: "" }
      setSelection(data)
      // ShowToast(toast, 'Success', 'Selection mode turned ON.', 'success', 2000)
    } else {
      if (proj === projectName && emp.name === employeeName) {
        if (isSameDay(startDate, curr)) {
          const data = { projectName: "", employeeName: "", startDate: "", endDate: "" }
          setSelection(data)
          // ShowToast(toast, 'INFO', 'Selection mode turned OFF.', 'info', 2000)
        } else if (isBefore(curr, startDate)) {
          ShowToast(toast, 'Error', 'Right Click on a Date after the Chosen one', 'error')
        } else {
          const dates = getDatesInRangeExcludingWeekends(startDate, curr, emp)
          dates.forEach((i) => {
            assignEmpAtDate(proj, emp.name, i, true)
          })
          // ShowToast(toast, 'Success', 'Successfully made assignments. Selection mode turned OFF', 'success', 4000)
          const data = { projectName: "", employeeName: "", startDate: "", endDate: "" }
          setSelection(data)
        }
      } else {
        const data = { projectName: proj, employeeName: emp.name, startDate: curr, endDate: "" }
        setSelection(data)
        // ShowToast(toast, 'Success', 'Selection mode turned ON.', 'success')
      }
    }
  }, [setSelection, selection])

  const getEmployeeCountForDateAndProject = useCallback((date, projectName) => {
    let count = 0;
    Object.values(recordList)?.forEach((i) => {
      if (i?.some((assignment) => assignment?.project === projectName && assignment?.dates?.some((j) => isSameDay(j, date)))) {
        count++
      }
    })
    return count
  }, [recordList])

  const getEmployeeCountForDate = useCallback((date) => {
    let count = 0;

    Object?.values(recordList)?.forEach((i) => {
      if (i?.some((assignment) => assignment?.dates?.some((j) => isSameDay(j, date)))) {
        count++
      }
    })
    return count
  }, [recordList])



  const absenteeCountForDate = useCallback((date) => {
    let count = 0;
    employeeList.forEach((emp) => {
      if (emp.absent_dates.some((i) => isSameDay(i, date))) {
        count++
      } else if (holidaysList.some((i) => isSameDay(i, date))) {
        count++
      }
    })
    return count
  }, [employeeList, holidaysList])


  const sameTeam = useCallback((date, projectName) => {
    let team = [];
    Object.values(recordList).forEach((i, ind) => {
      if (i?.some((assignment) => assignment?.project === projectName && assignment?.dates?.some((j) => isSameDay(j, date)))) {
        team.push(Object.keys(recordList)[ind]);
      }
    })
    if (team?.length === 1) {
      return getTeamByName(team[0])
    } else {
      const modifiedTeam = team.map(getTeamByName);
      const uniqueSet = new Set(modifiedTeam);
      return !(uniqueSet.size === modifiedTeam?.length) && uniqueSet.size === 1 ? getTeamByName(team[0]) : false;
    }
  }, [employeeList, projectList, recordList])

  const getTeamByName = useCallback((emp) => {
    return employeeList?.find((i) => i?.name === emp)?.team
  }, [employeeList])

  const getNextWorkingDay = useCallback((curr) => {
    let nextDay = curr;
    nextDay.setDate(nextDay.getDate() + 1);

    if (isWeekend(nextDay)) {
      return getNextWorkingDay(nextDay);
    }
    return nextDay;
  }, [])


  const handleScroll = useCallback((scrollValue) => {
    window.scrollTo(scrollValue, 0); // Scrolls the main window horizontally
  }, []);

  useEffect(() => {
    if (firstTime === 1 || firstTime === 2) {
      const date = new Date()
      let nextday = date;
      const start = convertObjectToDate(calendarData ? calendarData[0] : null)
      if (isWeekend(date)) {
        nextday = getNextWorkingDay(date)
      }
      handleScroll((22.4 * (differenceInBusinessDays(nextday, start) - 10)))
    }
  }, [calendarData, optimalDate, firstTime])







  const refH = useRef(null);
  const [Height, setHeight] = useState(0)

  useEffect(() => {
    setHeight(refH?.current?.clientHeight)
  }, [empExpand, overFilter])


  function sortObjectsByTeam() {
    let sortedObjects = {};
    employeeList.forEach((obj, index) => {
      const teamName = obj.team;
      if (sortedObjects[teamName]) {
        sortedObjects[teamName].push({ ...obj, index }); // Include index with each object
      } else {
        sortedObjects[teamName] = [{ ...obj, index }];
      }
    });

    // Convert the sorted object back to an array
    const sortedArray = Object.values(sortedObjects).reduce((acc, val) => acc.concat(val), []);
    return sortedArray;
  }
  const [{ isOver }, drop] = useDrop({
    drop: (item) => { !overFilter.some((i) => i.name === item.name) && setOverFilter([...overFilter, employeeList.find((i) => i.name === item.name)]); setCollapseAllOverview(true); },
    accept: itemTypes.EMPTOPRO,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    })
  });
  function sortObjectsByTeamName() {
    let sortedObjects = {};

    employeeList?.filter((currEmp) => overFilter?.length === 0 ? true : overFilter?.some((filter) => filter.name === currEmp.name))?.forEach((obj, index) => {
      const teamName = obj.team;
      if (sortedObjects[teamName]) {
        sortedObjects[teamName].push({ ...obj, index }); // Include index with each object
      } else {
        sortedObjects[teamName] = [{ ...obj, index }];
      }
    });

    // Convert the sorted object back to an array
    const sortedArray = Object.values(sortedObjects).reduce((acc, val) => acc.concat(val), []);
    return sortedArray;
  }

  const [openPro, setOpenPro] = useState(false)
  const [proInd, setProInd] = useState('')
  const [newProDialog, setNewProDialog] = useState(false)
  return (
    <Box mb={'5vh'} w={`${(window.outerWidth * 15 / 100) + (calendarData.length * 22.4)}px`} className='select-none'>
      {/* <Box minW={'10px'} minH={'100%'} background={'white'} position={'sticky'} left={0} zIndex={9}></Box> */}


      <Flex background={'white'} style={{ position: 'sticky', top: 0 }} w={`${(window.outerWidth * 15 / 100) + (calendarData.length * 22.4)}px`} zIndex={10}>
        <Flex w={'15vw'} position={'sticky'} top={0} left={'10px'} display={'flex'} flexDir={'row'} justifyContent={'start'} alignItems={'start'} >{empExpand ?
          <Text onClick={() => setEmpExpand(false)} cursor={'pointer'}><IoIosArrowUp style={{ marginRight: '5px', marginLeft: '5px', marginTop: '2px' }} /></Text>
          : <Text onClick={() => setEmpExpand(true)} cursor={'pointer'}><IoIosArrowDown style={{ fontSize: '15px', marginRight: '5px', marginLeft: '5px', marginTop: '2px' }} /></Text>}<Text fontSize={'13px'} className='robotic' whiteSpace={'nowrap'}>Team Overview ({employeeList.length})  </Text></Flex>
        <Box w={'82vw'} position={'sticky'} top={0} left={'15.8vw'} id='glass'>
          <Box ms={0} rowGap={empExpand ? '5px' : '18px'} display={'flex'} ref={refH} h={!empExpand && '20px'} overflowY={'auto'} flexDir='row' w={'100%'} flexWrap={'wrap'} justifyContent={'start'} alignItems={'center'}>
            {sortObjectsByTeam().map((i, ind) => {
              return <DraggableBox name={i.name} employee={i} ind={i.index} key={ind}></DraggableBox>
            })}
          </Box>
        </Box>
      </Flex>

      <Box display={'flex'} className='select-none' width={'100%'} >
        <Box minW={'10px'} minH={'100%'} background={'white'} position={'sticky'} left={0} zIndex={9}></Box>
        {/* FIRST SIDE */}
        <Box position={'sticky'} zIndex={9} left={'10px'} background={'white'} borderRight={'none'} display={'flex'} justifyContent={'start'} alignItems={'start'} flexDir={'column'} minW={'15vw'}>
          <Box zIndex={8} ref={drop} color={isOver ? 'white' : 'black'} background={isOver ? '#e1e1e1' : 'white'} style={{ position: 'sticky', top: Height }} flexDir={'column'} transition={'all 0.1s ease-in-out'} height={'65px'} display={'flex'} w={'100%'}>
            <Flex justifyContent={'center'} alignItems={'center'} minH={'43px'} >
              <Button bg={'white'} mt={0} className={'hover:border-black my-1 robotic mx-1'} maxH={'30px'} boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'} fontWeight={'normal'} fontSize={'13px'} w={'100%'} onClick={() => setNewProDialog(true)}>ADD PROJECT</Button>
              {newProDialog && <NewProject setOpenedList={setOpenedList} open={newProDialog} setOpen={setNewProDialog} />}
              <NewEmployee />
            </Flex>
            {/* <GlobalHolidays /> */}
            <Flex justifyContent={'space-between'} h={'15px'} px={1} >
              <Flex>{!collapseAllOverview ? <IoIosArrowDown style={{ marginLeft: '2px', marginRight: '5px' }} onClick={() => setCollapseAllOverview(!collapseAllOverview)} cursor={'pointer'} fontSize={'14px'} /> : <IoIosArrowUp style={{ marginLeft: '2px', marginRight: '5px' }} onClick={() => setCollapseAllOverview(!collapseAllOverview)} cursor={'pointer'} fontSize={'14px'} />}
                <Text display={'flex'} flexDir={'row'} flexWrap={'nowrap'} whiteSpace={'nowrap'} className='robotic' fontSize={'13px'} justifyContent={'center'} alignItems={'center'} fontWeight={'normal'}>Employee Status {<Text ms={'2px'} fontSize={'13px'} className='robotic' color={overFilter.length === 0 || overFilter.length === employeeList.length ? 'black' : 'red'}> ({overFilter.length === 0 ? employeeList.length : overFilter.length})</Text>}</Text>
              </Flex>
              <OverViewFilter setCollapse={setCollapseAllOverview} overFilter={overFilter} setOverFilter={setOverFilter} />
            </Flex>
          </Box>
          <Box transition={'all 0.3s ease-in-out'} zIndex={8} style={{ position: 'sticky', top: Height + 65, background: 'white', width: '100%' }}>
            {collapseAllOverview && <EmployeeList setOverFilter={setOverFilter} overFilter={overFilter} />}
          </Box>
          {/* <Flex justifyContent={'start'} position={'fixed'} top={`${Height + 124 + ((overFilter.length === 0 ? employeeList.length : overFilter.length) * 20)}px`} borderLeftRadius={'5px'} alignItems={'center'} background={'#eeeeee'} className='h-[20px] text-white w-[100%] text-center border-none'> */}
          <Box w={'100%'}>
            <Flex zIndex={1} alignItems={'center'} transition={'all 0.3s ease-in-out'} bgColor={'#eeeeee'} position={'sticky'} top={collapseAllOverview ? `${Height + 124 + ((overFilter.length === 0 ? employeeList.length : overFilter.length) * 20)}px` : `${Height + 65}px`} borderLeftRadius={'5px'} background={'#eeeeee'} className='h-[20px] text-white w-[100%] text-center border-none'>
              {collapse ? <IoIosArrowDown onClick={() => setCollapse(false)} cursor={'pointer'} style={{ marginLeft: '6px', marginRight: '5px' }} fontSize={'14px'} /> : <IoIosArrowUp onClick={() => setCollapse(true)} cursor={'pointer'} style={{ marginLeft: '6px', marginRight: '5px' }} fontSize={'14px'} />}
              <Text fontSize={'13px'} className='robotic font-normal' color={'#3c4043'}>ACTIVE PROJECTS</Text>
            </Flex>
            <ProjectsList toggleItem={toggleItem} addItem={addItem} closed={false} />
          </Box>
          <Box w={'100%'}>
            <Flex zIndex={1} transition={'all 0.3s ease-in-out'} bgColor={'#eeeeee'} position={'sticky'} top={collapseAllOverview ? `${Height + 124 + ((overFilter.length === 0 ? employeeList.length : overFilter.length) * 20)}px` : `${Height + 65}px`} borderLeftRadius={'5px'} background={'#eeeeee'} color={'#3c4043'} className='h-[20px] text-white w-[100%] text-center border-none'>
              {!collapseAllClosed ? <IoIosArrowDown onClick={() => setCollapseAllClosed(!collapseAllClosed)} cursor={'pointer'} style={{ marginTop: '3px', marginLeft: '6px', marginRight: '5px' }} fontSize={'14px'} /> : <IoIosArrowUp onClick={() => setCollapseAllClosed(!collapseAllClosed)} cursor={'pointer'} style={{ marginTop: '3px', marginLeft: '6px', marginRight: '5px' }} fontSize={'14px'} />}
              <Text fontSize={'13px'} className='robotic' color={'#3c4043'}>Delivered Projects</Text>
            </Flex>
            {collapseAllClosed && <ProjectsList addItem={addItem} toggleItem={toggleItem} closed={true} />}
          </Box>
        </Box>


        {/* SECOND SIDE */}
        <Box p={0} order={2} borderBottom={collapseAllClosed && '3px solid #eeeeee'} alignItems={'start'} display={'flex'} flexDir={'column'}>
          <Box borderY={'3px solid #eeeeee'} style={{ position: 'sticky', top: Height }} transition={'all 0.3s ease-in-out'} zIndex={8} height={'65px'} display={'flex'} flexDir={'column'} background={'white'}>
            <Flex borderBottom={'1px solid #eeeeee'} className='space-x-[1px]' height={'22px'} bg={'#eeeeee'} flexDir={'row'} flex={1} alignItems={'center'} >
              {calendarData?.map((i, ind) => {
                const DATE_BOX_WIDTH = 21.4;
                const DATE_BOX_MARGIN = 1;
                const monthDays = calendarData.filter(k => k.month === i.month && k.year === i.year).length;
                const monthBoxWidth = (monthDays * DATE_BOX_WIDTH) + ((monthDays - 1) * DATE_BOX_MARGIN);
                return (calendarData[ind - 1]?.month !== calendarData[ind]?.month && <Box ms={ind === 0 && '2px'} bg={'white'} key={ind} minW={`${monthBoxWidth}px`} flexDir={'column'}>
                  <Box textAlign={'center'} fontSize={'12px'} className='robotic' h={'18px'} display={'grid'} placeItems={'center'}  >{i.month.charAt(0).toUpperCase() + i.month.slice(1)} {i.year}</Box>
                </Box>)
              })}
            </Flex>
            <Flex borderBottom={'2px solid #eeeeee'} height={'20px'} background={'#eeeeee'} flexDir={'row'} alignItems={'center'} justifyContent={'stretch'}>
              {calendarData?.map((i, ind) => {
                return (ind % 5 === 0 &&
                  <Box key={ind} ms={'3px'} bg={'white'} flex={1} minW={'109px'} maxW={'109px'} flexDir={'column'}>
                    <Box textAlign={'center'} h={'20px'} fontSize={'12px'} className='robotic' display={'grid'} placeItems={'center'}>{`${navigator.language.split('-')[0] === 'da' ? 'Uge' : navigator.language.split('-')[0] === 'pt' ? 'Semana' : 'Week'} ${getISOWeek(convertObjectToDate(i))}`}</Box>
                  </Box>)
              })}
            </Flex>

            <Flex h='20px' background={'#eeeeee'}>{calendarData?.map((i, indC) => {
              const date = new Date()
              let sameday = checkSameDay(date, i)
              return <Box background={'white'} borderBottom={sameday ? '1px solid red' : '1px solid #eeeeee'} key={indC} marginLeft={indC % 5 === 0 ? '3px' : '1px'} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
                <Box h={'21px'} w={'21px'} fontSize={'12px'} className="roboto-condensed" display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
                  <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={sameday ? { borderRadius: '50px', background: 'red', maxHeight: '18px', width: '18px', color: 'white' } : {}}>{`${i?.date}`}</Box></Box>
              </Box>
            })}
            </Flex>
          </Box>

          {collapseAllOverview && <Box zIndex={8} transition={'all 0.3s ease-in-out'} style={{ position: 'sticky', top: Height + 65, background: 'white' }}>
            <Flex bg={'#eeeeee'}>{calendarData?.map((i, indC) => {
              const curr = convertObjectToDate(i)
              const date = new Date()
              let sameday = checkSameDay(date, i)
              let checkHoliday = holidaysList.some((j) => isSameDay(j, curr))
              let absentee = checkHoliday ? 0 : employeeList?.length - (absenteeCountForDate(curr) + getEmployeeCountForDate(curr))
              return (<Box display={'grid'} placeItems={'center'} w={'21px'} borderBottom={'1px solid #eeeeee'} h='20px' ml={indC % 5 === 0 ? '3px' : '1px'} bg={'white'} key={indC} >
                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '21px' }} />}
                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '21px' }} />}
                <Box textColor={absentee === 0 ? 'black' : 'white'} background={absentee === 0 ? 'white' : '#ffff00'} borderRadius={'50px'} height={'18px'} w={'18px'} display={'grid'} placeItems={'center'} >
                  <Text whiteSpace={'nowrap'} fontSize={'12px'}>  {absentee}
                  </Text></Box>
              </Box>)
            })}
            </Flex>


            {/* Absent */}
            <Flex bg={'#eeeeee'} zIndex={8}>{collapseAllOverview && calendarData?.map((i, indC) => {
              const curr = convertObjectToDate(i)
              const date = new Date()
              let sameday = checkSameDay(date, i)


              return (<Box borderBottom={'1px solid #eeeeee'} h='20px' ml={indC % 5 === 0 ? '3px' : '1px'} bg={'white'} key={indC} flexDir={'column'}>
                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '21px' }} />}
                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '21px' }} />}

                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'20px'} w={'21px'} fontSize={'12px'} textAlign={'center'} >
                  <Text whiteSpace={'nowrap'} fontSize={'12px'}>{absenteeCountForDate(curr)}</Text>
                </Box>
              </Box>)
            })}</Flex>


            {/* Booked */}
            <Flex bg={'#eeeeee'} zIndex={8}>{collapseAllOverview && calendarData?.map((i, indC) => {
              const converted = convertObjectToDate(i)
              const start = optimalDate.cs_date < optimalDate.is_date ? optimalDate.cs_date : optimalDate.is_date
              const end = optimalDate.ce_date > optimalDate.ie_date ? optimalDate.ce_date : optimalDate.ie_date
              const date = new Date()
              let sameday = checkSameDay(date, i)

              return (<Box borderBottom={'1px solid #eeeeee'} h='20px' ml={indC % 5 === 0 ? '3px' : '1px'} bg={'white'} key={indC} flexDir={'column'}>
                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '21px' }} />}
                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '21px' }} />}

                <Box height={'20px'} w={'21px'} textAlign={'center'} >
                  <Text whiteSpace={'nowrap'} fontSize={'12px'}>{(isBefore(start, converted) || isSameDay(start, converted)) && (isAfter(end, converted) || isSameDay(end, converted)) ? getEmployeeCountForDate(convertObjectToDate(i)) : 0}</Text>
                </Box>
              </Box>)
            })}</Flex>

            {sortObjectsByTeamName()?.map((item, index) => {
              let sameday = false
              return <Flex zIndex={8} bg={'#eeeeee'} key={index}>{calendarData?.map((i, indC) => {
                const thisdate = convertObjectToDate(i)
                const date = new Date()
                sameday = checkSameDay(date, i)
                let proName = ""
                if (checkAssignment(item?.name, thisdate)) {
                  proName = recordList[item?.name]?.find((assignment) => (assignment?.dates?.find((i) => isSameDay(i, thisdate))))?.project
                }
                const used = recordList[item?.name]?.some((assignment) => (assignment?.dates?.some((one) => isSameDay(one, thisdate))))
                return (<Box key={indC} borderBottom={'1px solid #eeeeee'} h='20px' ml={indC % 5 === 0 ? '3px' : '1px'} bg={'white'} flexDir={'column'}>
                  {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '21px' }} />}
                  {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '21px' }} />}
                  {used ? <Tooltip hasArrow label={`Assigned to Project: "${proName}"`} color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed'>
                    <Box height={'19px'} borderRadius={'5px'} w={'21px'} background={item?.team} fontSize={'0.8rem'} textAlign={'center'} >
                    </Box>
                  </Tooltip>
                    : holidaysList?.some((j) => isSameDay(thisdate, j)) ?
                      <Box textColor={'white'} height={'20px'} borderRadius={'5px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                        <Tooltip className='roboto-condensed' hasArrow label={"General Absent Day"} >
                          <Box bg={'#e1e1e1'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                        </Tooltip>
                      </Box>
                      : (item.absent_dates?.some((j) => isSameDay(thisdate, j))) ?
                        <Box textColor={'white'} height={'20px'} borderRadius={'5px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                          <Tooltip className='roboto-condensed' hasArrow label={"Employee Absent Day"} >
                            <Box bg={'#3c4043'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                          </Tooltip>
                        </Box>
                        :
                        <Tooltip className='roboto-condensed' hasArrow label={`Unused Employee`} >
                          <Box height={'20px'} w={'21px'} display={'grid'} placeItems={'center'}>
                            <Box height={'17px'} w={'17px'} borderRadius={'10px'} bg={'#ffff00'}>
                            </Box>
                          </Box>
                        </Tooltip>
                  }
                </Box>
                )
              })}
              </Flex>
            })}

          </Box>}


          <Box>
            <Box transition={'all 0.3s ease-in-out'} bg={'#eeeeee'} color={'#3c4043'} position={'sticky'} top={collapseAllOverview ? `${Height + 124 + ((overFilter.length === 0 ? employeeList.length : overFilter.length) * 20)}px` : `${Height + 65}px`} className={` h-[20px]`} style={{ width: `${calendarData.length * 22.4}px` }}>
            </Box>
            {openPro && proInd && <EditProject proName={proInd} open={openPro} setOpen={setOpenPro} />}
            {projectList?.map((i, ind) => {
              let start = i.cs_date < i.is_date ? i.cs_date : i.is_date
              let end = i.ce_date > i.ie_date ? i.ce_date : i.ie_date
              start = getUTCDate(start)
              end = getUTCDate(end)
              let sameday = false;

              return !i.closed && <Box key={ind}>
                {/* {openPro && <EditPro open={openPro} setOpen={setOpenPro} ind={ind}></EditPro>} */}
                <Flex background={'#eeeeee'}>{calendarData?.map((j, indC) => {
                  const currDay = convertObjectToDate(j)
                  const employeeCount = getEmployeeCountForDateAndProject(currDay, i?.name)
                  const inRange = (isBefore(start, currDay) || isSameDay(start, currDay)) && (isAfter(end, currDay) || isSameDay(end, currDay))
                  const date = new Date()
                  sameday = checkSameDay(date, j)
                  // // let sameday = isSameDay(currDay, date)
                  // // if (isWeekend(date)) {
                  // //   let nextday = getNextWorkingDay(date)
                  // //   sameday = isSameDay(nextday, currDay)
                  // // }

                  return (
                    <Box ml={indC % 5 === 0 ? '3px' : '1px'} h={'22px'} borderBottom={'1px solid #eeeeee'} background={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'} className={`box`} key={indC} >
                      {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginLeft: '20px', position: 'absolute', width: '1px', height: '31px' }} />}
                      {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginRight: '20px', position: 'absolute', width: '1px', height: '31px' }} />}
                      <Box outlineOffset={'-2px'} outline={isSameDay(i.ce_date, currDay) && isSameDay(i.ie_date, currDay) ? '2px solid #3c4043' : isSameDay(i.ce_date, currDay) ? '2px solid #3c4043' : isSameDay(i.ie_date, currDay) ? '2px solid red' : 'none'} borderRadius={'5px'} >
                        {inRange ?
                          <Box onClick={() => { setProInd(i.name); setOpenPro(true) }} borderLeftRadius={isSameDay(start, currDay) ? '5px' : ''} borderRightRadius={isSameDay(end, currDay) ? '5px' : ''} cursor={'pointer'} background={inRange && (employeeCount === 0 ? '#ffff00' : sameTeam(currDay, i?.name) !== false ? sameTeam(currDay, i?.name) : '#e1e1e1')} outlineOffset={'-2px'} height={'21px'} key={ind} w={'21px'} p={'2px'} textAlign={'center'}  >
                            {isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) || isSameDay(i.ce_date, currDay) || isSameDay(i.ie_date, currDay) ?
                              <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={isSameDay(i.cs_date, currDay) ? "Contract Start Date" : isSameDay(i.ce_date, currDay) ? "Contract End Date" : isSameDay(i.is_date, currDay) ? "Internal Start Date" : "Internal End Date"} >
                                <Box textAlign={'center'} outlineOffset={'-1px'} h={'17px'} w={'17px'} borderRadius={isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) ? '50px' : 'none'} outline={isSameDay(i.is_date, currDay) && isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : isSameDay(i.is_date, currDay) ? '2px solid red' : isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : 'none'}>
                                  <Text color={'#3c4043'} fontSize={'12px'}>{inRange && employeeCount}
                                  </Text>
                                </Box>
                              </Tooltip> :
                              <Box textAlign={'center'} outlineOffset={'-1px'} h={'17px'} w={'17px'} borderRadius={isSameDay(i.is_date, currDay) || isSameDay(i.cs_date, currDay) ? '50px' : 'none'} outline={isSameDay(i.is_date, currDay) && isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : isSameDay(i.is_date, currDay) ? '2px solid red' : isSameDay(i.cs_date, currDay) ? '2px solid #3c4043' : 'none'}>
                                <Text color={'#3c4043'} fontSize={'12px'}>{inRange && employeeCount}
                                </Text>
                              </Box>}
                          </Box>
                          :
                          <Box height={'21px'} key={ind} w={'21px'} p={'2px'}>
                          </Box>
                        }
                      </Box>
                    </Box>
                  )
                })}
                </Flex>
                {
                  openedList?.some((single) => single === i?.name) && i?.assigned?.map((empId, index) => {
                    let sameday = false;
                    const curr = employeeList.find((emp) => emp?.name === empId?.name)
                    return (<Flex bg={'#eeeeee'} key={index}>{calendarData?.map((l, indC) => {
                      const converted = convertObjectToDate(l);
                      const absentee = curr?.absent_dates?.some((day) => isSameDay(day, converted))
                      const globalHoliday = holidaysList?.some((day) => isSameDay(day, converted))
                      const inRange = (isBefore(start, converted) || isSameDay(start, converted)) && (isAfter(end, converted) || isSameDay(end, converted))

                      const checkSelection = (projectName === i?.name && employeeName === curr?.name && isSameDay(converted, startDate))
                      const date = new Date()
                      sameday = checkSameDay(date, l)
                      const matchedAssignment = findAssignment(i?.name, curr?.name, converted)
                      let proName = ""
                      if (!matchedAssignment && checkAssignment(curr?.name, converted)) {
                        proName = (recordList[curr?.name]?.find((assignment) => (assignment.project !== i?.name && assignment.dates?.find((i) => isSameDay(i, converted)))))?.project
                      }
                      return inRange ? (
                        globalHoliday ?
                          (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                            <Box>
                              {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                              {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                              <Box textColor={'white'} height={'20px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                                <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"General Absent Day"} >
                                  <Box bg={'#e1e1e1'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>) :
                          absentee ? (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                            <Box>
                              {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                              {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                              <Box textColor={'white'} height={'20px'} w={'21px'} display={'grid'} placeItems={'center'} textAlign={'center'} >
                                <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={"Employee Absent Day"} >
                                  <Box bg={'#3c4043'} height={'17px'} width={'17px'} borderRadius={'10px'}></Box>
                                </Tooltip>
                              </Box>
                            </Box></Box>)
                            :
                            <Box w={'21px'} key={indC} bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} >
                              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h={'20px'} onContextMenu={(e) => { e.preventDefault(); selectionFun(i.name, curr, converted) }} cursor={'pointer'} _hover={{ outlineOffset: '-2px', outline: '1px solid black' }}>
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                                {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '31px', }} />}

                                {matchedAssignment ? <Box flexDir={'column'} w={'20px'} height={'19px'}
                                  onClick={() => assignEmpAtDate(i?.name, curr?.name, converted)}
                                  mb={'auto'} display={'flex'} justifyContent={'center'} alignItems={'center'}
                                  background={curr?.team} borderRadius={'5px'}  >
                                  {checkSelection && <LuChevronRightCircle color='red' fontSize={'18px'} className='animate-pulse' />}
                                </Box> :
                                  (!matchedAssignment && checkAssignment(curr?.name, converted)) ? <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={`Assigned to Project: "${proName}"`} >
                                    <Box display={'grid'} placeItems={'center'} h={'100%'}><Box w={'10px'} h={'3px'} background={curr?.team}></Box> </Box></Tooltip> :
                                    (!matchedAssignment && !checkAssignment(curr?.name, converted)) ? <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={`Unused Employee`} >
                                      <Box onClick={() => assignEmpAtDate(i?.name, curr?.name, converted)} display={'grid'} placeItems={'center'} h={'100%'}  ><Box h={'17px'} w={'17px'} borderRadius={'10px'} bg={'#ffff00'}>
                                        {checkSelection && <LuChevronRightCircle color='red' fontSize={'18px'} className='animate-pulse' />}
                                      </Box> </Box></Tooltip> : null
                                }
                              </Box></Box>)

                        : (<Box bg={'white'} borderBottom={'1px solid #eeeeee'} ml={indC % 5 === 0 ? '3px' : '1px'} h={'20px'} key={indC}>
                          <Box flexDir={'column'}>
                            {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginLeft: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                            {sameday && <hr style={{ background: 'red', color: 'black', borderColor: 'red', marginTop: '-1px', marginRight: '20px', position: 'absolute', width: '1px', height: '31px', }} />}
                            <Box w={'21px'} fontSize={'15px'} textAlign={'center'} p={1}></Box>
                          </Box></Box>)
                    })}
                    </Flex>)
                  }
                  )
                }
              </Box>
            })}
          </Box>



          <Box transition={'all 0.3s ease-in-out'} bg={'#eeeeee'} color={'#3c4043'} position={'sticky'} top={collapseAllOverview ? `${Height + 124 + ((overFilter.length === 0 ? employeeList.length : overFilter.length) * 20)}px` : `${Height + 65}px`} className={` h-[20px]`} style={{ width: `${calendarData.length * 22.4}px` }}>
          </Box>
          {collapseAllClosed && <ClosedProjects calendarData={calendarData} />}
        </Box>

      </Box>
    </Box >
  )
}



export default memo(GanttChart)