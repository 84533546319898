import { Box, Flex, Text } from "@chakra-ui/react";
import { memo, useContext, useState } from "react";
import { useDrag } from "react-dnd";
import { LuPencil } from "react-icons/lu";
import EditEmployee from '../Components/Employees/EditEmployee';
import UserContext from '../Context/User/UserContext';
import { itemTypes } from "../DragTypes/Itemtypes";
import EditIcon from '../Icons/Edit.png'
const DraggableBox = ({ employee, name, ind }) => {
    const { view } = useContext(UserContext)
    const [{ isDragging }, drag] = useDrag({
        type: itemTypes.EMPTOPRO,
        item: { name },
        canDrag: !view,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        })
    });

    const [open, setOpen] = useState(false)
    return (
        <Flex color={'white'} alignItems={'center'} mb={'1px'} me={'5px'} height={'20px'} justifyContent={'space-between'} borderRadius={'5px'} p={'2px'} pe={'3px'} w={'115px'} transition={'all 0.1s ease-in-out'} background={employee.team} ref={drag} className={`hover:rounded-[7px]`} cursor={'pointer'} style={{ opacity: isDragging ? 0.3 : 1 }} >
            <Text fontSize={'12px'} className="roboto-condensed">{employee.name}</Text>
            {/* {!view && <LuPencil onClick={() => setOpen(true)} fontSize={'15px'} className={`hover:text-black  cursor-pointer`} />} */}
            {!view && <Box w={'17px'}><img style={{ width: '15px' }} src={EditIcon} onClick={() => setOpen(true)} fontSize={'15px'} className={`hover:text-black  cursor-pointer`} /></Box>}
            {!view && open && <EditEmployee open={open} setOpen={setOpen} ind={ind}></EditEmployee>}
        </Flex>

    );
};
export default memo(DraggableBox)