import { useToast } from "@chakra-ui/react";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { AiFillCloseSquare } from 'react-icons/ai';
import { Link, useNavigate, } from "react-router-dom";
import ShowToast from "../../../utils/Toast/Toast";
import logo from '../../../assets/logo.png'
const Signup = () => {
    const toast = useToast()
    let [initial, setInitial] = useState({
        name: "",
        email: "",
        password: "",
        rePassword: "",
    })



    const changeFun = (e) => {
        if (e.target.name === 'email') {
            setInitial((prev) => ({
                ...prev,
                [e.target.name]: e.target.value.toLowerCase()
            }));
        } else {
            setInitial((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }));
        }
    }

    const Navigate = useNavigate()
    const signup = async () => {
        try {

            const regex = /^[A-Z0-9.+-_]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            const isValid = regex.test(initial.email);
            if (!isValid) {
                ShowToast(toast, "Error", "You have entered an invalid Email", 'error')
            } else if (!(initial.name || initial.email || initial.password || initial.rePassword)) {
                ShowToast(toast, "Error", "Please fill out all the fields", 'error')
            }
            else if (!(initial.password === initial.rePassword)) {
                ShowToast(toast, "Error", "Your Passwords do not match", 'error')
            }
            else if (initial.password.length <= 7 || initial.rePassword.length <= 7) {
                ShowToast(toast, "Error", "Password should be at least 8 digits long", 'error')
            } else {
                const updatedStateObject = { ...initial };
                delete updatedStateObject['rePassword'];
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/signup`, updatedStateObject, { withCredentials: true })
                ShowToast(toast, 'Success', 'Account created Successfully', 'success')
                Navigate('/')
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                ShowToast(toast, "Error", error?.response?.data?.message)
            } else if (error?.response?.data) {
                ShowToast(toast, "Error", error?.response?.data)
            } else {
                ShowToast(toast, "Error", error?.message)
            }
        }
    }

    return (

        <div className="flex robotic flex-col select-none  min-h-[100vh] w-[100%] bg-gray-50">
            <div className=" flex ms-[10px] items-center mb-1 text-2xl font-semibold ">
                <img src={logo} width={'150px'} alt="" />
            </div>
            <div className="text-center font-bold text-[36px] mt-1 mb-4">
                <h1 className="mb-[10px]">Effortlessly&nbsp;track&nbsp;and&nbsp;manage&nbsp;employee&nbsp;and&nbsp;project&nbsp;allocations<br />with&nbsp;our&nbsp;user-friendly&nbsp;visual&nbsp;overview</h1>
            </div>
            <div className="flex flex-row  w-[100%] bg-gray-50 justify-evenly">
                <section className="w-[50%] ms-[10%]">
                    <div className="flex flex-col items-center justify-center px-6 py-8  lg:py-0">

                        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <div className="space-y-4  md:space-y-4">
                                    <div>
                                        <label htmlFor="name" className="block mb-2 text-[14px] robotic text-gray-900 dark:text-white">Add your company name</label>
                                        <input onChange={changeFun} type="name" name="name" value={initial.name} id="name" className="text-[14px] robotic bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Carpenter Smith" required="" />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-[14px] robotic text-gray-900 dark:text-white">Add your email</label>
                                        <input onChange={changeFun} type="email" name="email" value={initial.email} id="email" className="text-[14px] robotic bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="John@carpenter.com" required="" />
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block mb-2 text-[14px] robotic text-gray-900 dark:text-white">Choose your password </label>
                                        <input onChange={changeFun} type="password" name="password" value={initial.password} id="password" placeholder="At least 8 characters long" className="text-[14px] robotic bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                                    </div>
                                    <div>
                                        <label htmlFor="confirm-password" className="block mb-2 text-[14px] robotic text-gray-900 dark:text-white">Confirm your password</label>
                                        <input onChange={changeFun} type="password" name="rePassword" value={initial?.rePassword} id="confirm-password" className="text-[14px] robotic bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                                    </div>





                                    <div >
                                        <button onClick={signup} className="w-full bg-[#1A73e8] text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create a free ProjektPlan account</button>
                                    </div>


                                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                        Already have an account? <Link to='/login' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="me-[5vw] flex flex-col justify-center align-middle">
                    <h1 className="text-[23px] roboto-condensed font-bold" style={{ fontWeight: 'bold' }}>Get started in 1 minute:</h1>
                    <ul>
                        <li className="text-[20px] mx-[2vw] roboto-condensed my-[5vh]" style={{ listStyle: 'circle' }}>Stop wasting your time planning workforce management in Excel or with paper and pencil</li>
                        <li className="text-[20px] mx-[2vw] roboto-condensed my-[5vh]" style={{ listStyle: 'circle' }}>Tailored for efficient management of employees on constantly changing projects and locations</li>
                        <li className="text-[20px] mx-[2vw] roboto-condensed my-[5vh]" style={{ listStyle: 'circle' }}>Easy to share so everyone is always fully updated on what, where, and with whom they should work</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Signup;
