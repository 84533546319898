import { isWeekend } from "date-fns";

const getNextWorkingDay = (curr) => {
    let nextDay = curr;
    nextDay.setDate(nextDay.getDate() + 1);

    if (isWeekend(nextDay)) {
        return getNextWorkingDay(nextDay);
    }
    return nextDay;
}
export function isSameDayCustom(date1, date2) {
    if (!(date1 instanceof Date)) {
        date1 = new Date(date1);
    }
    if (!(date2 instanceof Date)) {
        date2 = new Date(date1);
    }
    if (date1.getUTCDate() === date2.getUTCDate() && date1.getUTCMonth() === date2.getUTCMonth() && date1.getUTCFullYear() === date2.getUTCFullYear()) {
        return true;
    } else {
        return false
    }
}
export function isWeekendCustom(date1) {
    if (!(date1 instanceof Date)) {
        date1 = new Date(date1);
    }
    if (date1.getUTCDay() === 0 || date1.getUTCDay() === 6) {
        return true;
    } else {
        return false
    }
}
export function getUTCDate(date1) {

    // Extract year, month, and day from the inputDate
    const [year, month, day] = date1.split("-");

    // Use Date.UTC() to create a UTC timestamp for the given date
    const utcTimestamp = Date.UTC(year, month - 1, day, 0, 0, 0, 0);

    // Use the UTC timestamp to create a Date object representing the same date
    const utcDate = new Date(utcTimestamp);
    return utcDate.toUTCString();
}

export function checkSameDay(date, calendarDate) {
    if (isWeekend(date)) {
        let nextday = getNextWorkingDay(date)
        return nextday.getDate() === calendarDate.date && nextday.toLocaleString('default', { month: 'long' }) === calendarDate.month && nextday.getFullYear() === calendarDate.year
    }
    return date.getDate() === calendarDate.date && date.toLocaleString('default', { month: 'long' }) === calendarDate.month && date.getFullYear() === calendarDate.year
}