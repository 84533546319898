import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import UserContext from '../../../Context/User/UserContext'
import ShowToast from '../../../utils/Toast/Toast'
import logo from '../../../assets/logo.png'
const Login = () => {
    let [initial, setInitial] = useState({
        email: "",
        password: "",
    })
    const toast = useToast()

    const changeFun = (e) => {
        if (e.target.name === 'email') {
            setInitial((prev) => ({
                ...prev,
                [e.target.name]: e.target.value.toLowerCase()
            }));
        } else {
            setInitial((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }));
        }
    }
    const { getUserData, getChart } = useContext(UserContext);

    const loginFun = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/login`, initial, { withCredentials: true })
            await getUserData()
            await getChart()
            // ShowToast(toast, 'Success', 'Logged in successfully')

        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                ShowToast(toast, 'Error', error?.response?.data?.message)
            } else {
                ShowToast(toast, 'Error', error?.message)
            }
        }
    }
    return (
        <div>
            <section className="bg-gray-50 dark:bg-gray-900 min-h-[100vh]">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto" style={{ justifyContent: 'center' }}>
                    <Link to="/" className="mt-[5vh] flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        {/* <img className="w-8 h-8 mr-2" src={logo} alt="logo" /> */}
                        <div className="mt-[2vh] flex items-center mb-1 text-2xl font-semibold ">
                            <img src={logo} width={'150px'} alt="" />
                        </div>
                    </Link>
                    <div className="w-full text-[#3c4043] bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 robotic space-y-2 md:space-y-3 ">
                            <h1 className="mx-2 text-xl font-bold leading-tight tracking-tight">
                                Login to Your Account
                            </h1>
                            <div className="space-y-2 md:space-y-3">
                                <div className='mx-2'>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input onChange={changeFun} type="email" name="email" id="email" className="text-[14px] robotic bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter email" required="" />
                                </div>
                                <div className='mx-2'>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input onChange={changeFun} type="password" name="password" id="password" className="text-[14px] robotic bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='Enter password' required="" />
                                </div>

                                <div>
                                    <button onClick={loginFun} className="w-full text-white bg-[#1A73E8] rounded-lg py-2.5 text-center ">LOGIN WITH EMAIL</button>
                                </div>
                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Don't have an account? <Link to='/register' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Signup here</Link> or <Link to='/password/forgot' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot Password?</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>)
}

export default Login