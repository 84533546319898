import { Box, CloseButton, Flex, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { Fragment, memo, useState } from "react";
import Draggable from 'react-draggable';
import { CiFilter, CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { employees } from "../../atoms";
function OverViewFilter({ overFilter, setOverFilter, setCollapse }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [employeeList] = useAtom(employees)
    const [filter, setFilter] = useState("")
    return (
        <>
            {/* <Box width={'16px'} cursor={'pointer'} display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={onOpen}><img style={{background:'black'}} src={FilterIcon} /></Box> */}
            <Box width={'16px'} cursor={'pointer'} color={'#5f6368'} _hover={{ color: '#ec5045' }} display={'flex'} justifyContent={'center'} alignItems={'center'} transform={'scaleX(-1)'} onClick={onOpen}><CiFilter fontSize={'17px'} /></Box>
            <Modal isOpen={isOpen} >
                <ModalOverlay style={{ zIndex: 10 }} />
                <Draggable cancel=".clickable">
                    <div className="robotic  select-none" style={{ fontSize: '14px', fontWeight: 'normal', position: 'fixed', top: '2vh', zIndex: 10 }}>
                        <ModalContent>
                            <ModalHeader className="hover:bg-slate-300 cursor-move mb-[5px] rounded-t-md roboto-light" style={{ color: '#3c4043', background: '#f1f3f4', display: 'flex', maxHeight: '40px', alignItems: 'center', justifyContent: 'space-between' }}><Text className="text-[14px] font-normal robotic">Employee Status Filter</Text><CloseButton className="clickable" onClick={() => onClose()} /></ModalHeader>
                            <ModalBody className="clickable rounded-b-md" background={'white'} >
                                <Input size={'sm'} fontSize={'14px'} mt={4} value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Search for Employee Name" type="text" />
                                {/* <Select onChange={(e) => setCurrentEmp(e.target.value)} value={currentEmp} variant="filled" placeholder="Assign more Employees"> */}
                                <Text mt={2} className="text-[14px] robotic">Select Employees to Filter:</Text>
                                <Flex flexDir={'row'} justifyContent={'start'} rowGap={'5px'} mt={'1vh'} alignItems={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                                    {/* {employeeList.filter((employee) => employee?.name?.toLowerCase().includes(empFilter.toLowerCase()).map((k, index) => ( */}
                                    {employeeList.filter((emp) => emp?.name?.toLowerCase().includes(filter.toLowerCase()) && !overFilter?.some((i) => i.name === emp.name)).map((k, index) => (
                                        <Fragment key={index} >
                                            <Flex h={'25px'} ps={2} pe={'2px'} borderRadius={'5px'} fontSize={'small'} textColor={'white'} columnGap={'3px'} justifyContent={'space-between'} alignItems={'center'} style={{ backgroundColor: k.team }} key={index}>
                                                <Text fontSize={'12px'} fontWeight={'normal'} className="robotic" color={'#3c4043'}>{k.name}</Text>
                                                <CiSquarePlus color="#3c4043" cursor={'pointer'} style={{ marginBottom: 1 }} fontSize={'20px'} onClick={() => { setOverFilter([...overFilter, k]); setCollapse(true); }} />
                                            </Flex>
                                        </Fragment>

                                    ))}
                                </Flex>
                                {employeeList.filter((emp) => overFilter?.some((i) => i.name === emp.name)).length >= 1 && <Text mt={2} className="text-[14px] robotic">Display Filtered Employees:</Text>}
                                <Flex flexDir={'row'} mb={'5vh'} justifyContent={'start'} rowGap={'5px'} mt={2} alignItems={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                                    {/* {employeeList.filter((employee) => employee?.name?.toLowerCase().includes(empFilter.toLowerCase()).map((k, index) => ( */}
                                    {overFilter.length === 0 && employeeList.length !== 0 ? <Text >All Employees currently are displayed.</Text> :
                                        <>{employeeList.filter((emp) => overFilter?.some((i) => i.name === emp.name)).map((k, index) => (
                                            <Fragment key={index} >
                                                <Flex h={'25px'} ps={2} pe={'2px'} borderRadius={'5px'} fontSize={'small'} textColor={'white'} columnGap={'3px'} justifyContent={'space-between'} alignItems={'center'} style={{ backgroundColor: k.team }} key={index}>
                                                    <Text fontSize={'12px'} fontWeight={'normal'} className="robotic" color={'#3c4043'}>{k.name}</Text>
                                                    <CiSquareMinus color="#3c4043" cursor={'pointer'} style={{ marginBottom: 1 }} fontSize={'20px'} onClick={() => { setOverFilter(overFilter.filter((i) => i.name !== k.name)); setCollapse(true); }} />
                                                </Flex>
                                            </Fragment>
                                        ))}
                                        </>}
                                </Flex>
                                {overFilter.length >= 2 && <Text align={'end'} mb={2} className="text-blue-500" cursor={'pointer'} onClick={() => setOverFilter([])}>Remove All</Text>}
                            </ModalBody>

                        </ModalContent>
                    </div >
                </Draggable>
            </Modal>
        </>
    )
}
export default memo(OverViewFilter)