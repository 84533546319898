import { Button, CloseButton, useModalStyles, Flex, Input, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, extendTheme, ChakraProvider } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { Fragment, memo, useState } from "react";
import Draggable from 'react-draggable';
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { employees } from "../../atoms";
function VeiwFilter({ proFilter, setProFilter, viewFilter, setViewFilter }) {
    const [employeeList] = useAtom(employees)
    const [filter, setFilter] = useState("")
    const theme = extendTheme({
        components: {
            Modal: {
                baseStyle: (props) => ({
                    dialog: {
                        margin: 0,
                        outerWidth: '100%'
                    }
                })
            }
        }
    });
    return (
        <>
            <ChakraProvider disableGlobalStyle theme={theme}>
                <Modal isOpen={viewFilter}>
                    <ModalOverlay height={'100vh'} w={'100vw'} style={{ zIndex: 10 }} />
                    <Draggable cancel=".clickable">
                        <div style={{ minWidth: '100vw', position: 'fixed', top: window.innerWidth <= 900 ? '0px' : '100px', zIndex: 10 }}>
                            <ModalContent >
                                <ModalHeader className="hover:bg-slate-300 cursor-move mb-[5px] rounded-t-md roboto-light" style={{ color: '#3c4043', background: '#f1f3f4', display: 'flex', maxHeight: '40px', alignItems: 'center', justifyContent: 'space-between' }}><Text className="text-[14px] font-normal robotic">Projects Filter</Text><CloseButton className="clickable" onClick={() => setViewFilter(false)} /></ModalHeader>
                                <ModalBody className="clickable rounded-[10px]" background={'white'} >
                                    <Input placeholder="Search for Employee Name" className="robotic text-[14px]" type="text" onChange={(e) => setFilter(e.target.value)} />
                                    {/* <Select onChange={(e) => setCurrentEmp(e.target.value)} value={currentEmp} variant="filled" placeholder="Assign more Employees"> */}
                                    <Text mt={2} className="text-[14px] robotic">Select Employees to Filter:</Text>
                                    <Flex flexDir={'row'} justifyContent={'start'} rowGap={'5px'} mt={'1vh'} alignItems={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                                        {/* {employeeList.filter((employee) => employee?.name?.toLowerCase().includes(empFilter.toLowerCase()).map((k, index) => ( */}
                                        {employeeList.filter((emp) => !proFilter?.some((i) => i.name === emp.name)).map((k, index) => {
                                            return (
                                                k.name.toLowerCase().includes(filter) && <Fragment key={index} >
                                                    <Flex h={'25px'} ps={2} pe={'2px'} borderRadius={'5px'} fontSize={'small'} textColor={'white'} columnGap={'3px'} justifyContent={'space-between'} alignItems={'center'} style={{ backgroundColor: k.team }} key={index}>
                                                        <Text className="robotic text-[12px]">{k.name}</Text>
                                                        <CiSquarePlus onClick={() => setProFilter([...proFilter, k])} cursor={'pointer'} className="hover:text-black" fontSize={'20px'} />
                                                    </Flex>
                                                </Fragment>

                                            )
                                        })}
                                    </Flex>
                                    {employeeList.filter((emp) => proFilter?.some((i) => i.name === emp.name)).length >= 1 ? <Text mt={2} className="text-[14px] robotic">Display Filtered Employees:</Text>
                                        : <Text className="robotic text-[14px] mt-4">All Employees currently are being displayed</Text>}
                                    <Flex flexDir={'row'} mb={'5vh'} justifyContent={'start'} rowGap={'5px'} mt={'1vh'} alignItems={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                                        {/* {employeeList.filter((employee) => employee?.name?.toLowerCase().includes(empFilter.toLowerCase()).map((k, index) => ( */}
                                        {employeeList.filter((emp) => proFilter?.some((i) => i.name === emp.name)).map((k, index) => (
                                            <Fragment key={index}>
                                                <Flex h={'25px'} ps={2} pe={'2px'} borderRadius={'5px'} fontSize={'small'} textColor={'white'} columnGap={'3px'} justifyContent={'space-between'} alignItems={'center'} style={{ backgroundColor: k.team }} key={index}>
                                                    <Text className="robotic text-[12px]">{k.name}</Text>
                                                    <CiSquareMinus onClick={() => setProFilter(proFilter.filter((i) => i.name !== k.name))} cursor={'pointer'} className="hover:text-black" fontSize={'20px'} />
                                                </Flex>
                                            </Fragment>
                                        ))}
                                    </Flex>
                                </ModalBody>

                            </ModalContent>
                        </div >
                    </Draggable>
                </Modal >
            </ChakraProvider>
        </>
    )
}
export default memo(VeiwFilter)