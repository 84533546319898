import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import UserContext from '../../../Context/User/UserContext'
import ShowToast from '../../../utils/Toast/Toast'

const AdminLogin = () => {
    let [initial, setInitial] = useState({
        email: "",
        password: "",
    })
    const toast = useToast()

    const changeFun = (e) => {
        if (e.target.name === 'email') {
            setInitial((prev) => ({
                ...prev,
                [e.target.name]: e.target.value.toLowerCase()
            }));
        } else {
            setInitial((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }));
        }
    }
    const { getUserData} = useContext(UserContext);

    const loginFun = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/adminlogin`, initial, { withCredentials: true })
            await getUserData()
            // await getChart()
            ShowToast(toast, 'Success', 'Logged in successfully')

        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                ShowToast(toast, 'Error', error?.response?.data?.message)
            } else {
                ShowToast(toast, 'Error', error?.message)
            }
        }
    }
    
    return (
        <div>
            <section className="bg-gray-50 dark:bg-gray-900 min-h-[100vh]">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto" style={{ justifyContent: 'center' }}>
                    <Link to="/" className="mt-[10vh] flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        {/* <img className="w-8 h-8 mr-2" src={logo} alt="logo" /> */}
                        LABOURSOFT
                    </Link>
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Admin Login to Your Account
                            </h1>
                            <div className="space-y-4 md:space-y-6">
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                                    <input onChange={changeFun} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                                    <input onChange={changeFun} type="password" name="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                                </div>

                                <div>
                                    <button onClick={loginFun} style={{ background: 'deepskyblue' }} className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">LOGIN</button>
                                </div>
                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Don't have an account? <Link to='/register' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Signup here</Link> or <Link to='/password/forgot' className="font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot Password?</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>)
}

export default AdminLogin