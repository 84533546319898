import { Box, Button, CloseButton, Collapse, Flex, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react"
import { format, isAfter, isBefore, isSameDay } from "date-fns"
import { useAtom } from "jotai"
import { Fragment, memo, useCallback, useEffect, useState } from "react"
import Draggable from "react-draggable"
import { holidays, records } from "../../atoms"
import { isSameDayCustom, isWeekendCustom } from "../../utils/Functions/dateFunctions"
import showToast from '../../utils/Toast/Toast'
function GlobalHolidays({ open, setOpen }) {

    const [holidaysList, setHolidays] = useAtom(holidays)
    const [, setRecords] = useAtom(records)
    const [startRange, setStart] = useState("")
    const [endRange, setEnd] = useState("")
    const [result, setResult] = useState([])
    const toast = useToast()

    useEffect(() => {
        setResult(holidaysList)
    }, [])

    const removeDateFromAssignments = useCallback((targetDate) => {
        setRecords((prevAssignments) => {
            const updatedAssignments = { ...prevAssignments };
            Object.keys(updatedAssignments).forEach((empName) => {
                const employeeAssignments = updatedAssignments[empName];
                employeeAssignments.forEach((assignment) => {
                    const dateIndex = assignment.dates.findIndex((date) => isSameDay(date, targetDate));
                    if (dateIndex !== -1) {
                        assignment.dates.splice(dateIndex, 1);
                    }
                });
            });

            return updatedAssignments;
        });
    }, []);



    const getValidDates = () => {
        let range = [];
        if (!startRange || !endRange) {
            showToast(toast, 'Error', 'Enter the From and To Dates', 'error');
        } else if (isBefore(startRange, new Date()) && !isSameDay(startRange, new Date())) {
            showToast(toast, 'Error', "Employee Absent Days can't be Added before Today's Date", 'error');
        }
        else if (!(isBefore(startRange, endRange) || isSameDay(startRange, endRange))) {
            showToast(toast, 'Error', 'From Date has to be before the To Date', 'error');
        } else {
            let start = new Date(startRange);
            let timezoneOffsetInMinutes = start.getTimezoneOffset();
            start.setMinutes(start.getMinutes() + timezoneOffsetInMinutes);
            let end = new Date(endRange);
            let timezoneOffsetInMinutesEnd = end.getTimezoneOffset();
            end.setMinutes(end.getMinutes() + timezoneOffsetInMinutesEnd);
            while (start <= end) {
                if (!isWeekendCustom(format(start, 'yyyy-MM-dd')) &&
                    !holidaysList.some((holiday) => holiday === format(start, 'yyyy-MM-dd'))) {
                    range.push(format(start, 'yyyy-MM-dd')); // Add a new Date object to the range array
                }
                start.setDate(start.getDate() + 1);
            }

            setResult([...result, ...range]);
            setStart("")
            setEnd("")
        }
    };

    const saveHolidays = () => {
        result.filter((i) => isAfter(i, new Date()) || isSameDayCustom(i, new Date()))?.forEach((i) => {
            removeDateFromAssignments(i);
        })
        setHolidays([...result])
        setOpen(false)
    }
    const deleteHoliday = (item) => {
        const updatedAbsentDates = result.filter((i, index) => i !== item);
        setResult(updatedAbsentDates)

    }
    let previousMonthYear = null;
    return (
        <>
            <Modal isOpen={open} >
                <ModalOverlay style={{ zIndex: 10 }} />
                <Draggable cancel=".clickable">
                    <div className="robotic select-none" style={{ fontSize: '14px', fontWeight: 'normal', position: 'fixed', top: 0, zIndex: 10 }}>
                        <ModalContent>
                            <ModalHeader className="cursor-move rounded-t-md mb-[5px] roboto-light" style={{ fontWeight: 'normal', color: '#3c4043', background: '#f1f3f4', display: 'flex', maxHeight: '40px', alignItems: 'center', justifyContent: 'space-between' }}><Text className="text-[14px]">General Absent Days</Text><CloseButton width={'min-content'} height={'min-content'} p={0} className="clickable" onClick={() => setOpen(false)} /></ModalHeader>
                            <ModalBody className="clickable">
                                <Text mt={2} className="robotic" fontSize={'14px'} fontWeight={'normal'}>Add Planned Absent Days applicable for all Employees</Text><Text fontSize={'13px'}></Text>
                                <Flex flexDir={'row'} justifyContent={'space-between'} mt={2} alignItems={'center'}>
                                    <Flex flexDir={'column'} justifyContent={'center'} alignItems={'start'}><Text fontSize={'12px'}>From</Text>
                                        <Input value={startRange} onChange={(e) => setStart(e.target.value)} name="absent_dates_from" placeholder="From" size="sm" type="date" />
                                    </Flex>

                                    <Flex flexDir={'column'} justifyContent={'center'} alignItems={'start'}><Text fontSize={'12px'}>To</Text>
                                        <Input value={endRange} onChange={(e) => setEnd(e.target.value)} name="absent_dates_to" placeholder="To" size="sm" type="date" />
                                    </Flex>
                                    <button className="ml-3 mt-4 text-[#1A73E8]" onClick={getValidDates}>Add Dates</button>
                                </Flex>

                                {result?.filter((i) => isAfter(i, new Date()) || isSameDayCustom(i, new Date()))?.length >= 1 && <Text mt={5} className="robotic" fontSize={'14px'} fontWeight={'normal'}>Planned Absent Days applicable for all Employees</Text>}
                                {result?.length >= 1 && <Box maxH={'18vh'} overflowY={'auto'} transition={'all 0.3s ease-in-out'}>
                                    <Flex transition={'all 0.3s ease-in-out'} columnGap={'10px'} rowGap={'0px'} flexDir={'row'} flexWrap={'wrap'} justifyContent={'flex-start'}>
                                        {result?.filter((i) => isAfter(i, new Date()) || isSameDayCustom(i, new Date())).sort((a, b) => new Date(a) - new Date(b)).map((item, ind) => {
                                            const month = new Date(item).toLocaleString('default', { month: 'long', timeZone: 'UTC' })
                                            const year = new Date(item).getUTCFullYear()
                                            const currentMonthYear = `${month} ${year}`;
                                            const showMonthYear = previousMonthYear !== currentMonthYear;
                                            previousMonthYear = currentMonthYear;
                                            return (
                                                <Fragment key={ind}>
                                                    {showMonthYear && (
                                                        <Flex mt={'5px'} className="month-heading" justifyContent="start" alignItems={'flex-end'} width="100%" >
                                                            <Text fontWeight="normal" fontSize={'14px'} >{currentMonthYear}</Text>
                                                        </Flex>
                                                    )}
                                                    <Collapse in={true}>
                                                        <Flex className="select-none" lineHeight={'16px'} borderBottom={'1px solid black'} justifyContent={'center'} alignItems={'center'} transition={'all 0.3s ease-in-out'} my={'2px'} key={ind}>
                                                            <Flex justifyContent={'center'} alignItems={'center'} gap={'5px'} ><Text mt={'3px'} className="text-[12px]">{`${new Date(item).getUTCDate().toString().padStart(2, '0')}/${(new Date(item).getUTCMonth() + 1).toString().padStart(2, '0')}`}</Text><Text className="text-[16px] text-red-500" cursor={'pointer'} onClick={() => deleteHoliday(item)}   >x</Text></Flex>
                                                        </Flex>
                                                    </Collapse>
                                                </Fragment>
                                            )
                                        })}
                                    </Flex>
                                </Box>}

                            </ModalBody>
                            <ModalFooter className="clickable">
                                <Button fontWeight={'normal'} fontSize={'14px'} variant='ghost' onClick={() => setOpen(false)}>Cancel</Button>
                                <Button fontWeight={'normal'} fontSize={'14px'} background={"#1A73E8"} colorScheme='blue' ml={3} onClick={saveHolidays}>
                                    Save
                                </Button>
                            </ModalFooter>

                        </ModalContent>
                    </div></Draggable></Modal>
        </>
    )
}
export default memo(GlobalHolidays)