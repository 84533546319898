import React, { useState, useEffect } from "react";
import axios from "axios";
import UserContext from "./UserContext";
import { useAtom } from "jotai";
import { currName, employees, holidays, keys, projects, records } from "../../atoms";

const User = (props) => {
  let [user, setUser] = useState({});
  let [loading, setLoading] = useState(false);
  let [, setProjects] = useAtom(projects)
  let [, setHolidays] = useAtom(holidays)
  let [, setEmployees] = useAtom(employees)
  let [, setRecords] = useAtom(records)
  let [, setName] = useAtom(currName)
  let [, setKeys] = useAtom(keys)
  let [view, setView] = useState(false)
  const [collapseAllClosed, setCollapseAllClosed] = useState(false)
  const [openedList, setOpenedList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/user`, {
        withCredentials: true,
      });
      setUser(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getChart = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/charts/mychart`, { withCredentials: true })
    setRecords(data?.records)
    setProjects(data?.projects)
    setHolidays(data?.holidays)
    setEmployees(data?.employees)
    setName(data?.user?.name)
    setKeys(data?.keys)
  }
  

  const getAdminChart = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/charts/getChartById/${id}`, { withCredentials: true })
    setRecords(data?.records)
    setProjects(data?.projects)
    setHolidays(data?.holidays)
    setEmployees(data?.employees)
    setKeys(data?.keys)
    setName(data?.user?.name)
    // localStorage.setItem('JotaiProjects', JSON.stringify(data.projects))

    // localStorage.setItem('JotaiEmployees', JSON.stringify(data.employees))

    // localStorage.setItem('JotaiHolidays', JSON.stringify(data.holidays))

    // localStorage.setItem('JotaiRecords', JSON.stringify(data.records))

  }

  const getAdminName = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/user/getUserName/${id}`, { withCredentials: true })
    setName(data?.user?.name)
    // localStorage.setItem('JotaiProjects', JSON.stringify(data.projects))

    // localStorage.setItem('JotaiEmployees', JSON.stringify(data.employees))

    // localStorage.setItem('JotaiHolidays', JSON.stringify(data.holidays))

    // localStorage.setItem('JotaiRecords', JSON.stringify(data.records))

  }
  return (
    <UserContext.Provider value={{ view, setView, setUser, openedList, setOpenedList, getAdminName, getChart, collapseAllClosed, setCollapseAllClosed, getAdminChart, user, getUserData: getData, loading }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default User;
