import { Box, Button, CloseButton, Collapse, Flex, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { format, isAfter } from "date-fns"
import { useAtom } from "jotai"
import { Fragment, memo, useState } from "react"
import Draggable from "react-draggable"
import { employees, holidays, records, teams } from "../../atoms"
import { isSameDayCustom, isWeekendCustom } from '../../utils/Functions/dateFunctions'
import showToast from '../../utils/Toast/Toast'
function NewEmployee() {
    const [teamList] = useAtom(teams)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [employeeList, setEmployees] = useAtom(employees)
    const [absentDate, setAbsentDate] = useState("")
    const [holidaysList] = useAtom(holidays)
    const [startRange, setStart] = useState("")
    const [endRange, setEnd] = useState("")
    const [newEmp, setEmployee] = useState({
        name: "",
        team: "",
        absent_dates: []
    })
    const [, setRecords] = useAtom(records)

    const toast = useToast()
    const { name, team, absent_dates } = newEmp
    // const HandleChange = () => {
    //     if (absentDate) {
    //         const ab = new Date(absentDate)
    //         if ([0, 6]?.includes(ab.getDay())) {
    //             showToast(toast, 'Error', `${absentDate} cannot be added as it is already a weekend.`, 'error');
    //         } else if (holidaysList.includes(absentDate)) {
    //             showToast(toast, 'Error', 'Already a Global Holiday.', 'error');
    //         } else if (absent_dates?.some((i) => i === absentDate)) {
    //             showToast(toast, 'Error', 'Date Already added.', 'error');
    //         } else {
    //             setEmployee((prevPro) => {
    //                 return {
    //                     ...prevPro,
    //                     absent_dates: [...absent_dates, absentDate],
    //                 };
    //             });
    //             setAbsentDate("")
    //         }
    //     }
    // };

    const getValidDates = () => {
        let result = [];
        if (!startRange || !endRange) {
            showToast(toast, 'Error', 'Enter the From and To Dates', 'error');
        }
        //  else if (isBefore(startRange, new Date()) && !isSameDayCustom(startRange, new Date())) {
        //     showToast(toast, 'Error', 'Start Date should not be before today date.', 'error');
        // }
        // else if (!(isBefore(startRange, endRange) || isSameDayCustom(startRange, endRange))) {
        //     showToast(toast, 'Error', 'Start Date should be before End date.', 'error');
        // }
        else {
            // Make sure startRange and endRange are valid Date objects
            let start = new Date(startRange);
            let timezoneOffsetInMinutes = start.getTimezoneOffset();
            start.setMinutes(start.getMinutes() + timezoneOffsetInMinutes);
            let end = new Date(endRange);
            let timezoneOffsetInMinutesEnd = end.getTimezoneOffset();
            end.setMinutes(end.getMinutes() + timezoneOffsetInMinutesEnd);
            while (start <= end) {
                if (!isWeekendCustom(format(start, 'yyyy-MM-dd')) &&
                    !holidaysList.some((holiday) => holiday === format(start, 'yyyy-MM-dd')) &&
                    !absent_dates.some((absentDate) => absentDate === format(start, 'yyyy-MM-dd'))) {
                    result.push(format(start, 'yyyy-MM-dd')); // Add a new Date object to the result array
                }
                start.setDate(start.getDate() + 1);
            }
            setEmployee((prevPro) => {
                return {
                    ...prevPro,
                    absent_dates: [...absent_dates, ...result],
                };
            });
            setStart("")
            setEnd("")
        }

    };
    // const removeDateForEmployee = useCallback((employeeName, targetDate) => {
    //     setRecords((prevAssignments) => {
    //         const updatedAssignments = { ...prevAssignments };
    //         if (updatedAssignments.hasOwnProperty(employeeName)) {
    //             const employeeAssignments = updatedAssignments[employeeName];
    //             employeeAssignments.forEach((assignment) => {
    //                 const dateIndex = assignment.dates.findIndex((date) => isSameDayCustom(date, targetDate));
    //                 if (dateIndex !== -1) {
    //                     assignment.dates.splice(dateIndex, 1);
    //                 }
    //             });
    //         }

    //         return updatedAssignments;
    //     });
    // }, []);

    const AddEmployee = () => {
        if (!name || !team) {
            showToast(toast, 'Error', 'Add Name of Employee and Select Team Color', 'error');
        } else if (name.length >= 16) {
            showToast(toast, 'Error', 'Employee Name can be up to 15 characters', 'error');
        }
        else if (employeeList?.some((i) => i.name === name)) {
            showToast(toast, 'Error', 'There is already an Employee with this Name', 'error');
        } else {
            setEmployees([...employeeList, newEmp])
            setEmployee({
                name: "",
                team: "",
                absent_dates: []
            })
            onClose()
        }
    }
    const removeAbsentDate = (curr) => {
        setEmployee((prevPro) => {
            const updatedAbsentDates = prevPro.absent_dates.filter((item, index) => item !== curr);
            return {
                ...prevPro,
                absent_dates: updatedAbsentDates,
            };
        });
    }
    let previousMonthYear = null;
    return (
        <>
            <Button bg={'white'} mt={0} boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'} fontSize={'13px'} fontWeight={'normal'}  maxH={'30px'} w='100%' className={'hover:border-black robotic mx-1 my-1'}  onClick={onOpen}>ADD EMPLOYEE</Button>
            <Modal isOpen={isOpen} >
                <ModalOverlay style={{ zIndex: 10 }} />
                <Draggable cancel=".clickable">
                    <div className="robotic select-none" style={{ fontSize: '14px', position: 'fixed', top: '2vh', zIndex: 10 }}>
                        <ModalContent>
                            <ModalHeader className="cursor-move rounded-t-md mb-[5px] roboto-light" style={{ fontWeight: 'normal', color: '#3c4043', background: '#f1f3f4', display: 'flex', maxHeight: '40px', alignItems: 'center', justifyContent: 'space-between' }}><Text className="text-[14px]">Add Employee</Text><CloseButton p={0} className="clickable" onClick={() => onClose()} /></ModalHeader>
                            <ModalBody mb className="clickable">
                                <Input value={name} onChange={(e) => (setEmployee({ ...newEmp, name: e.target.value }))} fontSize={'22px'} color={'#5f6368'} _placeholder={{ color: '#5f6368' }} variant='flushed' name={'name'} className="mb-5" placeholder='Add Name of Employee...' />
                                {/* <Select value={team} onChange={(e) => (setEmployee({ ...newEmp, team: e.target.value }))} variant="filled" placeholder="Select Team Colour">
                                    1{teamList.map((item, ind) => <option key={ind} style={{ width: '50px', height: '10px', backgroundColor: item, color: 'white' }}>{item}</option>
                                    )}
                                </Select> */}
                                <Text>Select Team Color</Text>
                                <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>{teamList.map((item, ind) => <Box onClick={() => (setEmployee({ ...newEmp, team: item }))} className="hover:border-[1px] border-black" cursor={'pointer'} mx={'2px'} my={'2px'} key={ind} style={{ width: '36px', height: '15px', backgroundColor: item, border: newEmp.team === item ? '2px solid black' : '', borderRadius: newEmp?.team === item ? '5px' : '', color: 'white' }}></Box>
                                )}</Box>
                                <Text mt={5}>Add Planned Absent Dates of Employee</Text><Text fontSize={'13px'}></Text>
                                {/* <Flex justifyContent={'center'} marginTop={2} gap={5} alignItems={'center'}><Input value={absentDate} onChange={(e) => (setAbsentDate(e.target.value))} name="absent_dates" placeholder="Absent Dates" size="md" type="date" />
                            <Button colorScheme='teal' ml={3} onClick={HandleChange}>Add</Button>
                        </Flex> */}
                                <Flex flexDir={'row'} justifyContent={'space-between'} mt={2} alignItems={'center'}>
                                    <Flex flexDir={'column'} justifyContent={'center'} alignItems={'start'}><Text fontSize={'12px'}>From</Text>
                                        <Input value={startRange} onChange={(e) => setStart(e.target.value)} name="absent_dates_from" placeholder="From" size="sm" type="date" />
                                    </Flex>

                                    <Flex flexDir={'column'} justifyContent={'center'} alignItems={'start'}><Text fontSize={'12px'}>To</Text>
                                        <Input value={endRange} onChange={(e) => setEnd(e.target.value)} name="absent_dates_to" placeholder="To" size="sm" type="date" />
                                    </Flex>
                                    <button className="ml-3 mt-4 text-[#1A73E8]" onClick={getValidDates}>Add Dates</button>
                                </Flex>

                                {absent_dates?.length >= 1 && <Text mt={5} fontSize={'14px'} fontWeight={'normal'}>Planned Absent Dates of Employee</Text>}
                                {absent_dates?.length >= 1 && <Box maxH={'18vh'} overflowY={'auto'} transition={'all 0.3s ease-in-out'}>
                                    <Flex transition={'all 0.3s ease-in-out'} columnGap={'10px'} rowGap={'0px'} flexDir={'row'} flexWrap={'wrap'} justifyContent={'flex-start'}>
                                        {absent_dates?.filter((i) => isAfter(i, new Date()) || isSameDayCustom(i, new Date())).sort((a, b) => new Date(a) - new Date(b)).map((item, ind) => {
                                            const month = new Date(item).toLocaleString('default', { month: 'long', timeZone: 'UTC' })
                                            const year = new Date(item).getUTCFullYear()
                                            const currentMonthYear = `${month} ${year}`;
                                            const showMonthYear = previousMonthYear !== currentMonthYear;
                                            previousMonthYear = currentMonthYear;
                                            return (
                                                <Fragment key={ind}>
                                                    {showMonthYear && (
                                                        <Flex mt={'5px'} className="month-heading" justifyContent="start" alignItems={'flex-end'} width="100%" >
                                                            <Text fontWeight="normal" fontSize={'14px'} >{currentMonthYear}</Text>
                                                        </Flex>
                                                    )}
                                                    <Collapse in={true}>
                                                        <Flex className="select-none" lineHeight={'16px'} borderBottom={'1px solid black'} justifyContent={'center'} alignItems={'center'} transition={'all 0.3s ease-in-out'} my={'2px'} key={ind}>
                                                            <Flex justifyContent={'center'} alignItems={'center'} gap={'5px'} ><Text mt={'3px'} className="text-[12px]">{`${new Date(item).getUTCDate().toString().padStart(2, '0')}/${(new Date(item).getUTCMonth() + 1).toString().padStart(2, '0')}`}</Text><Text className="text-[16px] text-red-500" cursor={'pointer'} onClick={() => removeAbsentDate(item)}  >x</Text></Flex>
                                                        </Flex>
                                                    </Collapse>
                                                </Fragment>
                                            )
                                        })}
                                    </Flex>
                                </Box>}



                            </ModalBody>

                            <ModalFooter className="clickable">
                                <Button variant='ghost' fontWeight={'normal'} fontSize={'14px'} onClick={onClose}>Cancel</Button>
                                <Button colorScheme='blue' fontWeight={'normal'} fontSize={'14px'} background={'#1A73E8'} ml={3} onClick={AddEmployee}>
                                    Save
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </div></Draggable></Modal>
        </>
    )
}
export default memo(NewEmployee)