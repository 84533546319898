import { Box, Button, CloseButton, Flex, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react"
import { format, isAfter, isBefore, isSameDay } from "date-fns"
import { useAtom } from "jotai"
import { Fragment, memo, useCallback, useContext, useEffect, useState } from "react"
import Draggable from "react-draggable"
import { CiSquarePlus } from 'react-icons/ci'
import UserContext from "../../Context/User/UserContext"
import { employees, holidays, projects, records } from "../../atoms"
import { isWeekendCustom } from "../../utils/Functions/dateFunctions"
import showToast from '../../utils/Toast/Toast'
import Confirmation from "../Confirmation/ConfirmationModel"
function EditProject({ open, setOpen, proName }) {

    // const { isOpen, onOpen, onClose } = useDisclosure()
    const [tasks, setTasks] = useAtom(projects)
    const [ind, setInd] = useState(-1)
    const [close_date, setCloseDate] = useState("")
    const [newPro, setPro] = useState({
        name: "",
        cs_date: "",
        ce_date: "",
        is_date: "",
        ie_date: "",
        assigned: []
    })
    const { setCollapseAllClosed, openedList, setOpenedList } = useContext(UserContext)
    const [employeeList] = useAtom(employees)
    const [empFilter, setEmpFilter] = useState("")
    const [holidaysList] = useAtom(holidays)
    const [recordsList, setRecords] = useAtom(records)
    const toast = useToast()
    const { name, cs_date, ce_date, is_date, ie_date } = newPro
    const getNextWorkingDay = (curr) => {
        let nextDay = new Date(curr);
        nextDay.setDate(nextDay.getDate() + 1);
        if (isWeekendCustom(format(nextDay, "yyyy-MM-dd")) || holidaysList.some((i) => i === format(nextDay, "yyyy-MM-dd"))) {
            return getNextWorkingDay(nextDay);
        }
        return format(nextDay, "yyyy-MM-dd");
    }

    const HandleChange = (e) => {
        let { name, value } = e.target;
        if (name !== 'name' && (isWeekendCustom(value) || holidaysList.some((i) => i === value))) {
            value = getNextWorkingDay(value)
        }
        setPro((prevPro) => {
            return {
                ...prevPro,
                [name]: value,
            };
        });
    };
    const handleDeliveryDate = (value) => {
        if (isWeekendCustom(value)) {
            value = getNextWorkingDay(value)
        }
        setCloseDate(value)
    }
    const addAssignedDetails = () => {
        const assignedDetails = tasks[ind].assigned.map(employee => {
            const currEmployee = employeeList.find(e => e.name === employee.name);
            return currEmployee ? { name: currEmployee.name, absent_dates: currEmployee.absent_dates, team: currEmployee.team } : null;
        });
        let currRec = new Array()
        Object.keys(recordsList).forEach((empName, index) => {
            const employeeAssignments = recordsList[empName];
            employeeAssignments.forEach((assignment) => {
                if (assignment.project === tasks[ind].name) {
                    currRec = ({ ...currRec, [Object.keys(recordsList)[index]]: [assignment] })
                }
            });
        });
        return ({ ...tasks[ind], holidaysList: holidaysList, assignedDetails, records: currRec });
    };
    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [isWarningOpen2, setIsWarningOpen2] = useState(false);

    const openWarning = () => {
        setIsWarningOpen(true);
    };

    const closeWarning = () => {
        setIsWarningOpen(false);
    };
    const openWarning2 = () => {
        const endDate = tasks[ind].ce_date > tasks[ind].ie_date ? tasks[ind].ce_date : tasks[ind].ie_date
        if (!close_date || close_date < endDate) {
            showToast(toast, 'Error', 'Enter a Delivery Date after internal and contract end dates.', 'error')
            // } else if (differenceInDays(close_date, endDate) > 28) {
            //     showToast(toast, 'Error', 'Difference in Delivery Date and end date should not be more than 4 weeks.', 'error')
            // } else if ([0, 6]?.includes(new Date(close_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${close_date} cannot be internal end as it is a weekend.`, 'error');
            // } else if (holidaysList.some((i) => i === close_date)) {
            //     showToast(toast, 'Error', `${close_date} cannot be internal end as it is a Global Holiday.`, 'error');
        }
        else if (ind >= 0 && ind < tasks.length) {
            setIsWarningOpen2(true);

        }
    };

    const closeWarning2 = () => {
        setIsWarningOpen2(false);
    };
    const getDatesInRangeExcludingWeekends = useCallback((startDate, endDate, emp) => {
        const result = [];
        let currentDate = startDate
        currentDate = new Date(currentDate);
        currentDate = currentDate.toISOString();

        endDate = new Date(endDate);
        endDate = endDate.toISOString();
        while (isBefore(currentDate, endDate) || isSameDay(currentDate, endDate)) {
            const dayOfWeek = new Date(currentDate).getUTCDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6 && !(holidaysList?.some((i) => isSameDay(i, currentDate))) && !(emp.absent_dates?.some((i) => isSameDay(i, currentDate)))) {
                result.push(new Date(currentDate));
            }
            currentDate = new Date(currentDate);
            currentDate.setDate(currentDate.getDate() + 1);
            currentDate = currentDate.toISOString();
        }
        return result;
    }, [recordsList]);
    const assignEmpAtDate = useCallback((projectName, empName, singleDate) => {
        if (projectName !== undefined) {
            setRecords((prevAssignments) => {
                const updatedAssignments = { ...prevAssignments };
                if (!updatedAssignments.hasOwnProperty(empName)) {
                    updatedAssignments[empName] = [];
                    const employeeAssignments = updatedAssignments[empName];
                    const newProjectAssignment = { project: projectName, dates: [singleDate] };
                    employeeAssignments.push(newProjectAssignment);
                } else {
                    const employeeAssignments = updatedAssignments[empName];
                    //checks if employee is assigned to some other project
                    if (employeeAssignments?.some((assignment) => (assignment.project !== projectName && assignment.dates?.some((i) => isSameDay(i, singleDate))))) {
                        // const ProName = (employeeAssignments?.find((assignment) => (assignment.project !== projectName && assignment.dates?.find((i) => isSameDay(i, singleDate)))))?.project
                        // !more && ShowToast(toast, 'Error', `${empName} is Already Assigned on this Date to Project: "${ProName}".`, 'error')
                    } else {
                        const existingProjectIndex = employeeAssignments.findIndex((assignment) => assignment.project === projectName);
                        if (existingProjectIndex !== -1) {
                            if (employeeAssignments[existingProjectIndex].dates?.some((i) => isSameDay(i, singleDate))) {
                                employeeAssignments[existingProjectIndex].dates = employeeAssignments[existingProjectIndex].dates.filter((i) => !(isSameDay(i, singleDate)));
                            } else {
                                employeeAssignments[existingProjectIndex].dates.push(singleDate);
                            }
                        } else {
                            const newProjectAssignment = { project: projectName, dates: [singleDate] };
                            employeeAssignments.push(newProjectAssignment);
                        }
                    }
                }
                return updatedAssignments;
            });
        }
    }, [recordsList, newPro])

    const selectionFun = useCallback((proj, emp, startDate, endDate) => {
        const sutcTimestamp = Date.UTC(startDate.split('-')[0], parseInt(startDate.split('-')[1]) - 1, startDate.split('-')[2], 0, 0, 0, 0);
        const sutcDate = new Date(sutcTimestamp);
        startDate = sutcDate.toUTCString();
        const eutcTimestamp = Date.UTC(endDate.split('-')[0], parseInt(endDate.split('-')[1]) - 1, endDate.split('-')[2], 0, 0, 0, 0);
        const eutcDate = new Date(eutcTimestamp);
        endDate = eutcDate.toUTCString();
        const dates = getDatesInRangeExcludingWeekends(startDate, endDate, employeeList.find((i) => i.name === emp))
        dates.forEach((i) => {
            assignEmpAtDate(proj, emp, i)
        })
    }, [recordsList])

    const AssignEmployee = useCallback((index, empName) => {
        // if (!empName) {
        // showToast(toast, 'Error', "Select an Employee from the list to assign", "error")
        // } else {
        selectionFun(newPro.name, empName, newPro.cs_date < newPro.is_date ? newPro.cs_date : newPro.is_date, newPro.ce_date > newPro.ie_date ? newPro.ce_date : newPro.ie_date)
        setTasks(prevProjectsList => {
            return prevProjectsList.map((project, ind) => {
                if (index === ind && !project.closed && !(project?.assigned?.some((i) => i.name === empName))) {
                    return {
                        ...project,
                        assigned: [...project?.assigned, { name: empName }]
                    };
                }
                return project;
            });
        });
        if (!openedList.some((i) => i === tasks[ind]?.name)) {
            setOpenedList([...openedList, tasks[ind]?.name])
        }
        // }
        // eslint-disable-next-line
    }, [employeeList, ind, recordsList, newPro])

    const removeDatesOutsideRange = (projectName, startDate, endDate) => {
        setRecords((prevAssignments) => {
            const updatedAssignments = { ...prevAssignments };
            Object.keys(updatedAssignments).forEach((empName) => {
                const employeeAssignments = updatedAssignments[empName];
                employeeAssignments.forEach((assignment) => {

                    if (assignment.project === projectName) {
                        assignment.dates = assignment.dates.filter((date) => {
                            return (isBefore(startDate, date) || isSameDay(startDate, date)) && (isAfter(endDate, date) || isSameDay(endDate, date));
                        });
                    }
                });
            });
            return updatedAssignments;
        });
        // eslint-disable-next-line
    }

    // Example usage

    const UpdateProject = () => {
        if (!name || !cs_date || !ce_date || !is_date || !ie_date) {
            showToast(toast, 'Error', 'Add Name of Project + Start and End Dates', 'error');
        } else if (tasks[ind].name !== name && tasks?.find((i) => i.name === name)) {
            showToast(toast, 'Error', 'A Project with this Name already exists', 'error');
            // } else if ([0, 6]?.includes(new Date(cs_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${cs_date} cannot be contract start as it is a weekend.`, 'error');
            // } else if ([0, 6]?.includes(new Date(ce_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${ce_date} cannot be contract end as it is a weekend.`, 'error');
            // } else if ([0, 6]?.includes(new Date(is_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${is_date} cannot be internal start as it is a weekend.`, 'error');
            // } else if ([0, 6]?.includes(new Date(ie_date).getUTCDay())) {
            //     showToast(toast, 'Error', `${ie_date} cannot be internal end as it is a weekend.`, 'error');
        } else if (cs_date > ce_date || is_date > ie_date) {
            showToast(toast, 'Error', 'Start Date should be before End Date', 'error');
        } else {
            if (ind >= 0 && ind < tasks?.length) {
                removeDatesOutsideRange(tasks[ind].name, cs_date < is_date ? cs_date : is_date, ce_date > ie_date ? ce_date : ie_date);
                const newArray = [
                    ...tasks.slice(0, ind),
                    { ...tasks[ind], ...newPro },
                    ...tasks.slice(ind + 1),
                ];
                if (tasks[ind].name !== name) {
                    setRecords(prevDataArray => {
                        const updatedData = {};
                        for (const key in prevDataArray) {
                            updatedData[key] = prevDataArray[key].map(item => ({
                                ...item,
                                project: item.project === tasks[ind].name ? name : item.project
                            }));
                        }
                        return updatedData;
                    });
                }
                setTasks(newArray);
                setPro({
                    name: "",
                    cs_date: "",
                    ce_date: "",
                    is_date: "",
                    ie_date: "",
                    assigned: []
                })
                setOpen(false)
            }
        }
    }
    const CloseProject = () => {
        const newArray = [
            ...tasks.slice(0, ind),
            { ...(addAssignedDetails()), close_date, closed: true },
            ...tasks.slice(ind + 1),
        ];
        setTasks(newArray);
        removeAssignmentsForProject(tasks[ind].name);
        setPro({
            name: "",
            cs_date: "",
            ce_date: "",
            is_date: "",
            ie_date: "",
            assigned: []
        })
        setCollapseAllClosed(true)
        setOpen(false)

    }
    useEffect(() => {
        const Index = (tasks.findIndex((i) => i.name === proName))
        setPro(tasks[Index])
        setInd(Index)
        // eslint-disable-next-line
    }, [open, tasks])
    const removeAssignmentsForProject = useCallback((projectName) => {
        setRecords((prevAssignments) => {
            const updatedAssignments = { ...prevAssignments };

            // Iterate over all employees
            Object.keys(updatedAssignments).forEach((empName) => {
                const employeeAssignments = updatedAssignments[empName];

                // Find the project index in the employee's assignments
                const projectIndex = employeeAssignments.findIndex((assignment) => assignment.project === projectName);

                if (projectIndex !== -1) {
                    // If the project exists for the employee, remove it
                    employeeAssignments.splice(projectIndex, 1);
                }
            });

            return updatedAssignments;
        });
    }, [])

    const deleteProject = () => {
        if (ind >= 0 && ind < tasks.length) {
            removeAssignmentsForProject(tasks[ind].name);
            const newArray = [...tasks.slice(0, ind), ...tasks.slice(ind + 1)];
            setTasks(newArray);
        }
        setOpen(false)
    }

    return (
        <>
            <Modal isOpen={open} >
                <ModalOverlay style={{ zIndex: 10 }} />
                <Draggable cancel=".clickable">
                    <div className="robotic select-none" style={{ fontWeight: 'normal', fontSize: '14px', position: 'fixed', top: '0vh', zIndex: 10, }}>
                        <ModalContent>
                            <ModalHeader className="bg-slate-200 cursor-move rounded-t-md mb-[5px] roboto-light" style={{ fontWeight: 'normal', fontSize: '14px', color: '#3c4043', background: '#f1f3f4', display: 'flex', fontWeight: 'normal', fontSize: '14px', maxHeight: '40px', alignItems: 'center', justifyContent: 'space-between' }}><Text>{newPro?.closed ? 'Delete Delivered Project' : 'Project Information'} </Text><CloseButton h={'min-content'} w={'min-content'} className="clickable" onClick={() => setOpen(false)} /></ModalHeader>
                            {!newPro?.closed &&
                                <>
                                    <ModalBody className="clickable" p={'1.5vw'}>
                                        <Text></Text><Input fontSize={'22px'} color={'#5f6368'} _placeholder={{ color: '#5f6368' }} value={name} onChange={HandleChange} variant='flushed' name={'name'} placeholder='Add Name of Project...' />
                                        <Flex justifyContent={'center'} marginTop={3} gap={5} alignItems={'center'}><Text fontSize={'14px'} w={'135px'} >Contract&nbsp;Start:</Text><Input ml={4} onChange={HandleChange} value={cs_date} name="cs_date" placeholder="Contract Start" size="sm" type="date" /></Flex>
                                        <Flex justifyContent={'center'} marginTop={2} gap={5} alignItems={'center'}><Text fontSize={'14px'} w={'135px'} >Contract&nbsp;End:</Text><Input ml={4} onChange={HandleChange} value={ce_date} name="ce_date" placeholder="Contract End" size="sm" type="date" /></Flex>
                                        <Flex justifyContent={'center'} marginTop={2} gap={5} alignItems={'center'}><Text fontSize={'14px'} w={'135px'} >Internal&nbsp;Start:</Text><Input ml={4} onChange={HandleChange} value={is_date} name="is_date" placeholder="Internal Start" size="sm" type="date" /></Flex>
                                        <Flex justifyContent={'center'} marginTop={2} gap={5} alignItems={'center'}><Text fontSize={'14px'} w={'135px'} >Internal&nbsp;End:</Text><Input ml={4} onChange={HandleChange} value={ie_date} name="ie_date" placeholder="Internal End" size="sm" type="date" /></Flex>
                                    </ModalBody>

                                    <ModalFooter className="clickable" display={'flex'} p={0} mx={'1.5vw'} justifyContent={'space-between'}>
                                        {!newPro.closed && <button className="clickable font-normal text-[14px] text-red-500" onClick={openWarning}>
                                            Delete Active Project
                                        </button>}

                                        <Box>
                                            <Button fontSize={'14px'} fontWeight={'normal'} variant='ghost' onClick={() => setOpen(false)}>Cancel</Button>
                                            <Button fontSize={'14px'} fontWeight={'normal'} colorScheme='blue' background={"#1A73E8"} ml={3} onClick={UpdateProject}>
                                                Save
                                            </Button>
                                        </Box>
                                    </ModalFooter>
                                </>}
                            {!(newPro?.closed) && <><Flex className="clickable" justifyContent={'center'} marginTop={6} mx={'1.5vw'} gap={5} alignItems={'center'}>
                                <Text fontSize={'14px'} w={'135px'} fontWeight={'normal'}  >Delivery&nbsp;Date:</Text><Input ml={4} onChange={(e) => handleDeliveryDate(e.target.value)} value={close_date} name="close_date" placeholder="Delivery Date" size="sm" type="date" />
                            </Flex>

                                <button className="clickable ml-auto me-[1.5vw]  text-blue-600 font-normal mt-2 mb-4" onClick={openWarning2}>
                                    Deliver Project
                                </button>
                                {!newPro.closed && <Confirmation heading={'Deliver Active Project?'} warning={<Text className="text-center robotic text-[14px]">The Project will be delivered and can't be re-opened <br /> Please confirm</Text>} isOpen={isWarningOpen2} onClose={closeWarning2} onConfirm={() => { CloseProject(); closeWarning2(); }} />}
                            </>}

                            {!newPro.closed && employeeList?.length > newPro?.assigned?.length ? <Box className="clickable" mb={'3vh'} px={'1.5vw'}>
                                <Text className="robotic text-[14px] font-normal mb-2">Add Employee to Project</Text>
                                <Input size={'sm'} value={empFilter} onChange={(e) => setEmpFilter(e.target.value)} placeholder="Search for Employee Name" type="text" />
                                {/* <Select onChange={(e) => setCurrentEmp(e.target.value)} value={currentEmp} variant="filled" placeholder="Assign more Employees"> */}
                                <Flex flexDir={'row'} justifyContent={'start'} rowGap={'5px'} mt={'1vh'} alignItems={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                                    {employeeList.filter((employee) => employee?.name?.toLowerCase().includes(empFilter.toLowerCase()) && !(newPro.assigned.some((assignedEmployee) => assignedEmployee.name === employee.name))).map((k, index) => (
                                        <Fragment key={index} >
                                            <Flex h={'25px'} ps={2} pe={'2px'} borderRadius={'5px'} fontSize={'small'} textColor={'white'} columnGap={'3px'} justifyContent={'space-between'} alignItems={'center'} style={{ backgroundColor: k.team }} key={index}>
                                                <Text fontSize={'12px'} fontWeight={'normal'} className="robotic" color={'#3c4043'}>{k.name}</Text>
                                                <CiSquarePlus color="#3c4043" cursor={'pointer'} style={{ marginBottom: 1 }} fontSize={'20px'} onClick={() => AssignEmployee(ind, k?.name)} />
                                            </Flex>
                                        </Fragment>
                                    ))}
                                </Flex>
                                {/* </Select> */}

                            </Box> : !newPro?.closed && !employeeList?.length > newPro?.assigned?.length ? <Text className="clickable" textAlign={'center'} textColor={'#3c4043'} mb={5} >All Employees are on this Project</Text> : !newPro?.closed ? <Text className="clickable" textAlign={'center'} textColor={'#3c4043'} mb={5} >All Employees are on this Project</Text> : <Text className="clickable robotic" textAlign={'center'} px={'30px'} mt={'30px'} mb={'10px'} textColor={'black'} fontSize={'14px'}  >The Delivered Project will be permanently deleted <br /> Please confirm</Text>}



                            {newPro.closed &&
                                <Flex px={'25px'} pt={3} mb={3} marginLeft={'auto'}>
                                    <Button fontSize={'14px'} fontWeight={'normal'} className="text-[14px] robotic" variant="ghost" mr={3} onClick={() => setOpen(false)}>Cancel</Button>
                                    <Button fontSize={'14px'} fontWeight={'normal'} className="text-[14px] robotic" background={"#1A73E8"} colorScheme="blue" onClick={deleteProject}>
                                        Confirm
                                    </Button>
                                </Flex>
                            }

                            {!newPro.closed && <Confirmation heading={'Delete Active Project?'} warning={<Text className="text-center robotic text-[14px]">The Project will be permanently deleted <br /> Please confirm</Text>} isOpen={isWarningOpen} onClose={closeWarning} onConfirm={() => { deleteProject(); closeWarning(); }} />}

                        </ModalContent>
                    </div></Draggable>
            </Modal>
        </>
    )
}
export default memo(EditProject)