import { Box } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { memo, useCallback, useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { employees, holidays, projects, records } from "../../../atoms";
import OpenProject from "./OpenProject";
import UserContext from "../../../Context/User/UserContext";
import { isBefore, isSameDay } from "date-fns";

const ProjectsList = ({ addItem, toggleItem, closed, proFilter }) => {
    const [projectList, setProjects] = useAtom(projects)
    const [employeeList] = useAtom(employees)
    const [holidaysList] = useAtom(holidays)
    const [recordsList, setRecords] = useAtom(records)
    const { openedList } = useContext(UserContext)

    const getDatesInRangeExcludingWeekends = useCallback((startDate, endDate, emp) => {
        const result = [];
        let currentDate = startDate
        currentDate = new Date(currentDate);
        currentDate = currentDate.toISOString();

        endDate = new Date(endDate);
        endDate = endDate.toISOString();
        while (isBefore(currentDate, endDate) || isSameDay(currentDate, endDate)) {
            const dayOfWeek = new Date(currentDate).getUTCDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6 && !(holidaysList?.some((i) => isSameDay(i, currentDate))) && !(emp.absent_dates?.some((i) => isSameDay(i, currentDate)))) {
                result.push(new Date(currentDate));
            }
            currentDate = new Date(currentDate);
            currentDate.setDate(currentDate.getDate() + 1);
            currentDate = currentDate.toISOString();
        }
        return result;
    }, [recordsList]);

    const assignEmpAtDate = useCallback((projectName, empName, singleDate) => {
        if (projectName !== undefined) {
            setRecords((prevAssignments) => {
                const updatedAssignments = { ...prevAssignments };
                if (!updatedAssignments.hasOwnProperty(empName)) {
                    updatedAssignments[empName] = [];
                    const employeeAssignments = updatedAssignments[empName];
                    const newProjectAssignment = { project: projectName, dates: [singleDate] };
                    employeeAssignments.push(newProjectAssignment);
                } else {
                    const employeeAssignments = updatedAssignments[empName];
                    //checks if employee is assigned to some other project
                    if (employeeAssignments?.some((assignment) => (assignment.project !== projectName && assignment.dates?.some((i) => isSameDay(i, singleDate))))) {
                        // const ProName = (employeeAssignments?.find((assignment) => (assignment.project !== projectName && assignment.dates?.find((i) => isSameDay(i, singleDate)))))?.project
                        // !more && ShowToast(toast, 'Error', `${empName} is Already Assigned on this Date to Project: "${ProName}".`, 'error')
                    } else {
                        const existingProjectIndex = employeeAssignments.findIndex((assignment) => assignment.project === projectName);
                        if (existingProjectIndex !== -1) {
                            if (employeeAssignments[existingProjectIndex].dates?.some((i) => isSameDay(i, singleDate))) {
                                employeeAssignments[existingProjectIndex].dates = employeeAssignments[existingProjectIndex].dates.filter((i) => !(isSameDay(i, singleDate)));
                            } else {
                                employeeAssignments[existingProjectIndex].dates.push(singleDate);
                            }
                        } else {
                            const newProjectAssignment = { project: projectName, dates: [singleDate] };
                            employeeAssignments.push(newProjectAssignment);
                        }
                    }
                }
                return updatedAssignments;
            });
        }
    }, [recordsList])

    const selectionFun = useCallback((proj, emp, startDate, endDate) => {
        const sutcTimestamp = Date.UTC(startDate.split('-')[0], parseInt(startDate.split('-')[1]) - 1, startDate.split('-')[2], 0, 0, 0, 0);
        const sutcDate = new Date(sutcTimestamp);
        startDate = sutcDate.toUTCString();
        const eutcTimestamp = Date.UTC(endDate.split('-')[0], parseInt(endDate.split('-')[1]) - 1, endDate.split('-')[2], 0, 0, 0, 0);
        const eutcDate = new Date(eutcTimestamp);
        endDate = eutcDate.toUTCString();
        const dates = getDatesInRangeExcludingWeekends(startDate, endDate, employeeList.find((i) => i.name === emp))
        dates.forEach((i) => {
            assignEmpAtDate(proj, emp, i)
        })
    }, [recordsList])

    const AssignEmployee = useCallback((index, empName) => {
        setProjects(prevProjectsList => {
            return prevProjectsList.map((project, ind) => {
                if (index === ind && !project.closed && !(project?.assigned?.some((i) => i.name === empName.name))) {
                    selectionFun(project.name, empName.name, project.cs_date < project.is_date ? project.cs_date : project.is_date, project.ce_date > project.ie_date ? project.ce_date : project.ie_date)
                    return {
                        ...project,
                        assigned: [...project?.assigned, empName]
                    };
                }
                return project;
            });

        });

        addItem(projectList.find((item, ind) => ind === index)?.name)
        // eslint-disable-next-line
    }, [openedList, projectList])

    const removeAssignmentsForProject = useCallback((projectName, employeeName) => {
        setRecords((prevAssignments) => {
            const updatedAssignments = { ...prevAssignments };
            if (updatedAssignments.hasOwnProperty(employeeName)) {
                const employeeAssignments = updatedAssignments[employeeName];
                const projectIndex = employeeAssignments.findIndex((assignment) => assignment.project === projectName);
                if (projectIndex !== -1) {
                    employeeAssignments.splice(projectIndex, 1);
                }
            }

            return updatedAssignments;
        });
    }, [recordsList, projectList]);

    const UnassignEmployee = useCallback((index, empName) => {
        removeAssignmentsForProject(projectList[index]?.name, empName?.name)
        setProjects(prevProjectsList => {
            return prevProjectsList.map((project, ind) => {
                if (index === ind) {
                    return {
                        ...project,
                        assigned: project.assigned.filter((employee) => employee.name !== empName.name)
                    };
                }
                return project;
            });
        });
        // eslint-disable-next-line
    }, [recordsList])

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedTasks = Array.from(projectList);
        const [movedTask] = updatedTasks.splice(result.source.index, 1);
        updatedTasks.splice(result.destination.index, 0, movedTask);
        setProjects(updatedTasks);
    };
    const { view } = useContext(UserContext)

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='characters'>
                {(provided) => (
                    <Box w={'100%'} {...provided.droppableProps} ref={provided.innerRef}>
                        <>
                            {closed ? projectList?.filter((filter) => filter.closed === true).sort((a, b) => new Date(b?.close_date) - new Date(a?.close_date)).map((i, ind) => {
                                return <OpenProject toggleItem={toggleItem} ind={ind} i={i} provided={provided} />
                            }) :
                                projectList?.filter((filter) => {
                                    if (!view || proFilter.length === 0) {
                                        return true;
                                    } else {
                                        return filter.assigned.some((ass) => proFilter.some((pro) => pro.name === ass.name));
                                    }
                                }).map((i, ind) => {
                                    return !i.closed && view ? <OpenProject toggleItem={toggleItem} ind={ind} i={i} provided={provided} />
                                        : !i.closed && <Draggable key={ind} draggableId={ind.toString()} index={ind}>
                                            {(provided) => (<OpenProject toggleItem={toggleItem} ind={ind} i={i} provided={provided} AssignEmployee={AssignEmployee} UnassignEmployee={UnassignEmployee} />
                                            )}
                                        </Draggable>
                                })}
                            {provided.placeholder}
                        </>
                    </Box>)}
            </Droppable>
        </DragDropContext>
    )
}

export default memo(ProjectsList)