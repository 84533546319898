import { Box, Flex, Text } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { memo, useCallback, useContext } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import UserContext from '../../../Context/User/UserContext';
import { employees } from '../../../atoms';
import DeleteIcon from '../../../Icons/Delete.png'

const EmployeeList = ({ overFilter, setOverFilter }) => {
    const [employeeList] = useAtom(employees)
    const { view } = useContext(UserContext)
    const removerFun = useCallback((name) => {
        if (overFilter.length >= 1) {
            const updatedArray = overFilter.filter(item => item.name !== name);
            setOverFilter(updatedArray);
        } else {
            const updatedArray = employeeList.filter(item => item.name !== name);
            setOverFilter(updatedArray);
        }
    }, [overFilter])
    const sortObjectsByTeam = useCallback(() => {
        let sortedObjects = {};

        employeeList?.filter((currEmp) => view || overFilter?.length === 0 ? true : overFilter?.some((filter) => filter.name === currEmp.name))?.forEach((obj, index) => {
            const teamName = obj.team;
            if (sortedObjects[teamName]) {
                sortedObjects[teamName].push({ ...obj, index }); // Include index with each object
            } else {
                sortedObjects[teamName] = [{ ...obj, index }];
            }
        });

        // Convert the sorted object back to an array
        const sortedArray = Object.values(sortedObjects).reduce((acc, val) => acc.concat(val), []);
        return sortedArray;
    }, [overFilter, employeeList])

    return (
        <Box>
            <Flex ms={'20px'} height={'20px'} borderBottom={'1px solid white'} justifyContent={'space-between'} px={'5px'} whiteSpace={'nowrap'} alignItems={'center'} flexDirection={'row'} w={'100%'}><Text fontSize={'12px'} className="roboto-condensed">Unused Employees</Text></Flex>
            <Flex ms={'20px'} height={'20px'} borderBottom={'1px solid white'} justifyContent={'space-between'} px={'5px'} whiteSpace={'nowrap'} alignItems={'center'} flexDirection={'row'} w={'100%'}><Text fontSize={'12px'} className="roboto-condensed">Absent Employees</Text></Flex>
            <Flex ms={'20px'} height={'20px'} borderBottom={'1px solid white'} justifyContent={'space-between'} px={'5px'} whiteSpace={'nowrap'} alignItems={'center'} flexDirection={'row'} w={'100%'}><Text fontSize={'12px'} className="roboto-condensed">Booked Employees</Text></Flex>
            {
                sortObjectsByTeam().map((i, ind) =>
                    <Box h={'21px'} borderBottom={'1px solid white'} flexDirection={'row'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} fontSize={'12px'} transition={'all 0.25s ease-in-out'} display={'flex'} key={ind} w={'100%'} px={'5px'} height={'20px'}>
                        <Flex ms={'20px'} justifyContent={'center'} alignItems={'center'}>
                            <Box textColor={i?.team} backgroundColor={i?.team} width={'5px'} height={'15px'} opacity={1}></Box>
                            <Text fontSize={'12px'} className="roboto-condensed" ms={'5px'}>{i?.name}</Text>
                        </Flex>

                        <Box width={'15px'} display={'grid'} placeItems={'center'} onClick={() => { removerFun(i?.name) }}><img src={DeleteIcon} alt='del' className='hover:text-red-400 cursor-pointer' fontSize={'17px'} /></Box>
                        {/* <RiDeleteBin6Line onClick={() => { removerFun(i?.name) }} className='hover:text-red-400 cursor-pointer' fontSize={'17px'} /> */}
                    </Box>
                )
            }
            {/* <Flex height={'20px'}  justifyContent={'space-between'} px={'1vw'} whiteSpace={'nowrap'} alignItems={'center'} flexDirection={'row'} w={'100%'}><Text fontSize={'12px'}>{empType} Employees</Text> {unused ? <IoIosArrowUp cursor={'pointer'} onClick={() => { setUnused((prev) => !prev); }} style={{ fontSize: '10px', marginTop: '0.5vh' }} /> : <IoIosArrowDown cursor={'pointer'} onClick={() => { setUnused((prev) => !prev); }} style={{ fontSize: '10px', marginTop: '0.5vh' }} />}</Flex>
            {
                employeeList?.filter((currEmp) => view || overFilter?.length === 0 ? true : overFilter?.some((filter) => filter.name === currEmp.name))?.map((i, ind) =>
                    <Box flexDirection={'row'} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} fontSize={'12px'} transition={'all 0.25s ease-in-out'} pointerEvents={!unused && 'none'} display={'flex'} opacity={unused ? 1 : 0} key={ind} border={unused ? '1px solid black' : 'none'} w={'100%'} px={'10px'} height={unused ? '20px' : '0px'}>
                        <Flex justifyContent={'center'} alignItems={'center'}>
                            <Box textColor={i?.team} backgroundColor={i?.team} width={'5px'} height={'15px'} opacity={1}></Box>
                            <Text fontSize={'12px'} ms={'5px'}>{i?.name}</Text>
                        </Flex>
                        <RiDeleteBin6Line onClick={() => { removerFun(i?.name) }} className='hover:text-red-400 cursor-pointer' fontSize={'17px'} />
                    </Box>
                )
            } */}
        </Box>
    )
}

export default memo(EmployeeList)

