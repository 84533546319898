import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import profileImage from "../../../assets/profile.jpg";
import { Show, useToast } from '@chakra-ui/react';
import ShowToast from '../../../utils/Toast/Toast';
import logo from '../../../assets/logo.png'

const ResetPassword = () => {
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")
    const [loading, setLoading] = useState(false)
    const Navigate = useNavigate()
    const { token } = useParams()
    const toast = useToast()

    const updatePassword = async () => {
        try {
            setLoading(true)
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/password/reset/${token}`, { password, confirmPassword: rePassword })
            setLoading(false)
            ShowToast(toast, "Success", "Password updated Successfully")
            Navigate('/login')
        } catch (error) {
            console.log(error)
            if (error?.response?.data?.message) {
                ShowToast(toast, "Error", error?.response?.data?.message)
            } else {
                ShowToast(toast, "Error", error?.message)
            }
            setLoading(false)
        }
    }
    return (
        <div>
            <section className=" min-h-[100vh] dark:bg-gray-900">
                <div className="flex flex-col items-center justify-center mt-[10vh] px-6 py-8 mx-auto lg:py-0">
                    <div className="mt-[2vh] flex items-center mb-2 text-2xl font-semibold ">
                        <img src={logo} width={'150px'} alt="" />
                    </div>
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="mt-1 p-4 space-y-3 md:space-y-3 ">
                            <h1 className="robotic mx-2 leading-tight tracking-tight text-gray-900 dark:text-white mb-4 text-[18px]">
                                Reset your password
                            </h1>

                            <div className='mx-3'>
                                <label htmlFor="oldPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Type new Password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder='At least 8 characters long'
                                    id="password"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div className='mx-3'>
                                <label
                                    htmlFor="newPassword"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Confirm new Password
                                </label>
                                <input
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={rePassword}
                                    onChange={(e) => setRePassword(e.target.value)}
                                />
                            </div>
                            <div className="w-full rounded-lg  xl:p-0 dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between">
                                <div className=" flex flex-row justify-end ">
                                    <button
                                        onClick={updatePassword}
                                        className="bg-[#1A73E8] w-full text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-4 focus:ring-blue-300"
                                    >
                                        RESET YOUR PASSWORD
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>)
}

export default ResetPassword