import { Box, Button, Flex, Input, Menu, MenuButton, MenuItem, MenuList, Text, useToast } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import '../GanttChart.css';
import GanttChart from '../GanttChart';
import { calendarLength, currName, employees, holidays, optimalDates, projects, records } from '../atoms';
import parseCustomDate from '../utils/Functions/parseCustomDate';
import EditIcon from '../Icons/Edit.png';
import logo from '../assets/logo.png'
import axios from 'axios';
import ShowToast from '../utils/Toast/Toast';
import { Link, useNavigate, useParams } from 'react-router-dom'
import UserContext from '../Context/User/UserContext';
import { VscChevronDown } from 'react-icons/vsc';
import GlobalHolidays from '../Components/Holidays/GlobalHolidays';
function AdminGanttPage() {
  const [proj] = useAtom(projects)
  const [employeeList] = useAtom(employees)
  const [holidaysList] = useAtom(holidays)
  const [recordsList] = useAtom(records)
  const [, setOptimalDate] = useAtom(optimalDates)
  const [companyName, setCompanyName] = useAtom(currName)
  const { getAdminChart, getUserData, collapseAllClosed } = useContext(UserContext)
  const { id } = useParams()

  useEffect(() => {
    getAdminChart(id)
  }, [])

  const optimalDatesCalc = useCallback(() => {
    let activePros = proj
    if (!collapseAllClosed) {
      activePros = proj.filter((i) => !i.closed)
    }
    if (activePros.length >= 1) {
      let optimal = {
        is_date: new Date(activePros[0]?.is_date),
        cs_date: new Date(activePros[0]?.cs_date),
        ie_date: new Date(activePros[0]?.ie_date),
        ce_date: new Date(activePros[0]?.ce_date),
      };

      for (let i = 1; i < activePros.length; i++) {
        const project = activePros[i];
        optimal.is_date = new Date(Math.min(optimal?.is_date.getTime(), parseCustomDate(project.is_date).getTime()));
        optimal.cs_date = new Date(Math.min(optimal?.cs_date.getTime(), parseCustomDate(project.cs_date).getTime()));
        optimal.ie_date = new Date(Math.max(optimal?.ie_date.getTime(), parseCustomDate(project.ie_date).getTime()));
        optimal.ce_date = new Date(Math.max(optimal?.ce_date.getTime(), parseCustomDate(project.ce_date).getTime()));
      }



      setOptimalDate(optimal);
    }
    // eslint-disable-next-line
  }, [proj, collapseAllClosed]);


  useEffect(() => {
    optimalDatesCalc()
    // eslint-disable-next-line
  }, [proj, collapseAllClosed])






  const toast = useToast()
  const Navigate = useNavigate()


  const logout = async () => {
    try {
      await saveProgress()
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/logout`, { withCredentials: true })
      await getUserData()

      ShowToast(toast, 'Success', 'Logged out successfully', 'success')
      setTimeout(() => {
        Navigate('/admin/login')
        window.location.reload()
      }, 500);
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.message) {
        ShowToast(toast, 'Error', error?.response?.data?.message, 'error')
      } else {
        ShowToast(toast, 'Error', error?.message, 'error')
      }
    }
  }


  const saveProgress = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/charts/updateChartById/${id}`, { projects: proj, employees: employeeList, holidays: holidaysList, records: recordsList }, { withCredentials: true })
      ShowToast(toast, 'Success', 'Data Updated Successfully', 'success')
    } catch (error) {

      console.log(error)
      if (error?.response?.data?.message) {
        ShowToast(toast, 'Error', error?.response?.data?.message, 'error')
      } else {
        ShowToast(toast, 'Error', error?.message, 'error')
      }
    }
  }

  const [totalLength] = useAtom(calendarLength)
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [userName, setCurrName] = useState("")
  const changeName = async () => {
    try {
      if (currName.includes('_')) {
        ShowToast(toast, 'Error', "Name cannot contain '_' character", 'error')
      }
      else {
        if (userName.length <= 60) {
          const { data } = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/changeNamebyAdmin/${id}`, { name: userName }, { withCredentials: true })
          setCompanyName(data.name)
          setEdit(false)
        } else {
          ShowToast(toast, 'Error', 'Company Name can be up to 60 characters', 'error')
        }
      }
    } catch (error) {
      if (error?.response?.data?.includes('E11000 duplicate key error collection: gantt.users index: name_1')) {
        ShowToast(toast, 'Error', 'There is already a Company with this Name', 'error')
      }
      setEdit(false)

    }
  }
  return (
    <Box className='flex flex-col' >
      {/* <Overview />
      <Project /> */}
      <Box marginBottom={'30px'} position={'sticky'} w={`${((window.outerWidth * 15) / 100) + (totalLength * 22.4)}px`} paddingTop={'30px'} pb={'60px'} left={'16vw'} id='glass'>
        <Button overflow={'hidden'} background={'white'} color={'#3c4043'} className='robotic' _hover={{ background: '#EEEEEE' }} h={'35px'} border={'none'} position={'sticky'} left={'0vw'}>
          <img width={'100px'} src={logo} />
        </Button>
        <Link to='/'><Button background={'white'} color={'#3c4043'} className='robotic' _hover={{ background: '#EEEEEE' }} h={'35px'} borderColor={'#e1e1e1'} borderWidth={'1px'} position={'sticky'} left={'74vw'}>
          <Text display={'flex'} className='robotic text-[14px] font-normal' alignItems={'center'} zIndex={30} w={'100%'}>Go Back
          </Text>
        </Button>
        </Link>
        <Button background={'white'} border={'none'} outline={'none'} color={'#3c4043'} className='robotic' _hover={{ background: 'white' }} h={'35px'} position={'sticky'} left={'15vw'}>
          {edit ? <Input px={'5px'} minW={'500px'} fontSize={'20px'} fontWeight={'normal'} defaultValue={companyName} onChange={(e) => setCurrName(e.target.value)} outline={'none'} border={'1px solid #eeeeee'} focus h={'35px'} bg={'white'} />
            : <Text display={'flex'} fontSize={'20px'} className='robotic text-[20px] font-normal' alignItems={'center'} zIndex={30} w={'100%'}>{companyName}</Text>}<Box  >
            {edit ? <Flex flexDir={'row'} justifyContent={'center'} alignItems={'center'}> <Button style={{ marginLeft: '10px' }} cursor={'pointer'} fontSize={'15px'} onClick={changeName} _hover={{ bg: '#1A73c8' }} bg={'#1A73E8'} h={'35px'} fontWeight={'normal'} className='robotic' textColor={'white'} >Save</Button><Button ml={'10px'} h={'35px'} fontSize={'15px'} fontWeight={'normal'} onClick={() => setEdit(false)}>Cancel</Button></Flex>
              : <img onClick={() => setEdit(true)} style={{ marginTop: '2px', maxWidth: '15px', minWidth: '15px', marginLeft: '3px' }} src={EditIcon} className={`hover:text-black  cursor-pointer`} />}</Box>
        </Button>


        <Button background={'white'} color={'#3c4043'} className='robotic' _hover={{ background: '#EEEEEE' }} h={'35px'} borderColor={'#e1e1e1'} borderWidth={'1px'} position={'sticky'} onClick={saveProgress} left={'83vw'}>
          <Text display={'flex'} className='robotic text-[14px] font-normal' alignItems={'center'} zIndex={30} w={'100%'}>Save</Text>
        </Button>
        <Menu>
          <MenuButton height={'max-content'} background={'white'} color={'#3c4043'} borderColor={'#e1e1e1'} className='robotic' _hover={{ background: '#EEEEEE' }} borderWidth={'1px'} zIndex={20} position={'sticky'} left={'90vw'} as={Button} rightIcon={<VscChevronDown />}>
            <Box h={'33px'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
              <Text className=' robotic text-[14px] font-normal robotic'>Account</Text>
            </Box>
          </MenuButton>
          <MenuList overflowY={'auto'} p={0} color={'#3c4043'} zIndex={30}  >
            <MenuItem h={'30px'} zIndex={30}>{open && <GlobalHolidays setOpen={setOpen} open={open} />}
              <Text display={'flex'} className='robotic text-[14px]' alignItems={'center'} zIndex={30} h={'25px'} w={'100%'} onClick={() => setOpen(true)}>Add General Absent Days</Text></MenuItem>
            <MenuItem h={'30px'} >
              <Text display={'flex'} className='robotic text-[14px]' alignItems={'center'} zIndex={30} h={'25px'} w={'100%'} onClick={logout}>Support Videos</Text></MenuItem>
            <MenuItem h={'30px'} >
              <Text display={'flex'} className='robotic text-[14px]' alignItems={'center'} zIndex={30} h={'25px'} w={'100%'} ><a target='_blank' rel="noreferrer" href="mailto:info@projektplan.online">Questions: info@projektplan.online</a></Text></MenuItem>
            <MenuItem h={'30px'} >
              <Text display={'flex'} className='robotic text-[14px]' alignItems={'center'} zIndex={30} h={'25px'} w={'100%'} onClick={logout}>Logout</Text></MenuItem>
          </MenuList>
        </Menu>



      </Box>


      <Suspense fallback={<Box background={'black'}>Loading</Box>}>
        <GanttChart />
      </Suspense>
    </Box >
  );
}

export default AdminGanttPage;
