import { Box, Button, Flex, Input, Menu, MenuButton, MenuItem, MenuList, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { useAtom } from 'jotai';
import { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { VscChevronDown } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import GlobalHolidays from '../Components/Holidays/GlobalHolidays';
import GenerateKeys from '../Components/ViewKey/GenerateKeys';
import UserContext from '../Context/User/UserContext';
import GanttChart from '../GanttChart';
import '../GanttChart.css';
import EditIcon from '../Icons/Edit.png';
import logo from '../assets/logo.png'
import { calendarLength, employees, holidays, optimalDates, projects, records } from '../atoms';
import parseCustomDate from '../utils/Functions/parseCustomDate';
import ShowToast from '../utils/Toast/Toast';
function GanttPage() {
  const [proj] = useAtom(projects)

  const [employeeList] = useAtom(employees)
  const [holidaysList] = useAtom(holidays)
  const [recordsList] = useAtom(records)
  const [, setOptimalDate] = useAtom(optimalDates)
  const { collapseAllClosed, getChart, getUserData, user, setUser } = useContext(UserContext)
  let [firstRender, setFirstRender] = useState(0);

  useEffect(() => {
    if (firstRender >= 2) {
      saveProgress()
    } else {
      setFirstRender(++firstRender);
    }
  }, [proj, holidaysList, recordsList, employeeList]);

  useEffect(() => {
    getChart()
  }, [])

  const optimalDatesCalc = useCallback(() => {
    let activePros = proj
    if (!collapseAllClosed) {
      activePros = proj.filter((i) => !i.closed)
    }
    if (activePros.length >= 1) {
      let optimal = {
        is_date: new Date(activePros[0]?.is_date),
        cs_date: new Date(activePros[0]?.cs_date),
        ie_date: new Date(activePros[0]?.ie_date),
        ce_date: new Date(activePros[0]?.ce_date),
      };

      for (let i = 1; i < activePros.length; i++) {
        const project = activePros[i];
        optimal.is_date = new Date(Math.min(optimal?.is_date.getTime(), parseCustomDate(project.is_date).getTime()));
        optimal.cs_date = new Date(Math.min(optimal?.cs_date.getTime(), parseCustomDate(project.cs_date).getTime()));
        optimal.ie_date = new Date(Math.max(optimal?.ie_date.getTime(), parseCustomDate(project.ie_date).getTime()));
        optimal.ce_date = new Date(Math.max(optimal?.ce_date.getTime(), parseCustomDate(project.ce_date).getTime()));
      }



      setOptimalDate(optimal);
    }
    // eslint-disable-next-line
  }, [proj, collapseAllClosed]);

  useEffect(() => {
    optimalDatesCalc()
    // eslint-disable-next-line
  }, [proj, collapseAllClosed])



  // useEffect(() => {
  //   localStorage.setItem('JotaiProjects', JSON.stringify(proj))
  // }, [proj])

  // useEffect(() => {
  //   localStorage.setItem('JotaiEmployees', JSON.stringify(employeeList))
  // }, [employeeList])

  // useEffect(() => {
  //   localStorage.setItem('JotaiHolidays', JSON.stringify(holidaysList))
  // }, [holidaysList])

  // useEffect(() => {
  //   localStorage.setItem('JotaiRecords', JSON.stringify(recordsList))
  // }, [recordsList])

  // window.addEventListener('beforeunload', async function (event) {
  //   if ("Asad") {
  //     await saveProgress();
  //     // Display a confirmation message
  //     const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
  //     event.returnValue = confirmationMessage;
  //     return confirmationMessage; // For some older browsers
  //   }
  // });
  const toast = useToast()
  const Navigate = useNavigate()


  const logout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/logout`, { withCredentials: true })
      await getUserData()
      ShowToast(toast, 'Success', 'Logged out successfully', 'success')
      setTimeout(() => {
        Navigate('/login')
        window.location.reload()
      }, 100);
    } catch (error) {
      if (error?.response?.data?.message) {
        ShowToast(toast, 'Error', error?.response?.data?.message, 'error')
      } else {
        ShowToast(toast, 'Error', error?.message, 'error')
      }
    }
  }


  const saveProgress = async () => {
    try {

      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/charts/updateMyChart`, { projects: proj, employees: employeeList, holidays: holidaysList, records: recordsList }, { withCredentials: true })
      // ShowToast(toast, 'Success', 'Data Updated Successfully', 'success')
    } catch (error) {

      console.log(error)
      if (error?.response?.data?.message) {
        ShowToast(toast, 'Error', error?.response?.data?.message, 'error')
      } else {
        ShowToast(toast, 'Error', error?.message, 'error')
      }
    }
  }

  const [totalLength] = useAtom(calendarLength)
  const [open, setOpen] = useState(false)
  const [share, setShare] = useState(false)
  const [edit, setEdit] = useState(false)
  const [currName, setCurrName] = useState("")
  const changeName = async () => {
    try {
      if (currName.includes('_')) {
        ShowToast(toast, 'Error', "Name cannot contain '_' character", 'error')
      }
      else {
        if (currName.length <= 60) {
          await axios.put(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/changeName`, { name: currName }, { withCredentials: true })
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/server/api/auth/user`, {
            withCredentials: true,
          });
          setUser(data);
          setEdit(false)
        } else {
          ShowToast(toast, 'Error', 'Company Name can be up to 60 characters', 'error')
        }
      }
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.includes('E11000 duplicate key error collection: gantt.users index: name_1')) {
        ShowToast(toast, 'Error', 'There is already a Company with this Name', 'error')
      }

      setEdit(false)

    }
  }
  return (
    <Box className='flex flex-col'>
      {/* <Overview />
      <Project /> */}
      {/* <Button w={'100px'} onClick={saveProgress}>Save</Button> */}

      <Box position={'sticky'} w={`${(window.outerWidth * 15 / 100) + (totalLength * 22.245)}px`} py={'15px'} borderBottom={'1px solid #eeeeee'} mb={'15px'} left={'16vw'} id='glass'>
        {/* <Box m={0} p={0} top={0} minW={`${(window.outerWidth * 15 / 100) + (totalLength * 20)}px`} position={'sticky'} display={'flex'}>  <Image position={'sticky'} src={logo} height={'35px'} p={0} ms={4}></Image></Box> */}

        <Button overflow={'hidden'} background={'white'} color={'#3c4043'} className='robotic' _hover={{ background: '#EEEEEE' }} h={'35px'} border={'none'} position={'sticky'} left={'0vw'}>
          <img width={'100px'} src={logo} />
        </Button>
        <Button background={'white'} border={'none'} color={'#3c4043'} className='robotic' _hover={{ background: 'white' }} h={'35px'} position={'sticky'} left={'15vw'}>
          {edit ? <Input px={'5px'} minW={'500px'} fontSize={'20px'} fontWeight={'normal'} defaultValue={user?.name} onChange={(e) => setCurrName(e.target.value)} outline={'none'} border={'1px solid #eeeeee'} focus h={'35px'} bg={'white'} />
            : <Text display={'flex'} fontSize={'20px'} className='robotic text-[20px] font-normal' alignItems={'center'} zIndex={30} w={'100%'}>{user?.name}</Text>}<Box  >
            {edit ? <Flex flexDir={'row'} justifyContent={'center'} alignItems={'center'}> <Button style={{ marginLeft: '10px' }} cursor={'pointer'} fontSize={'15px'} onClick={changeName} bg={'#1A73E8'} h={'35px'} fontWeight={'normal'} className='robotic' textColor={'white'} >Save</Button><Button ml={'10px'} h={'35px'} fontSize={'15px'} fontWeight={'normal'} onClick={() => setEdit(false)}>Cancel</Button></Flex>
              : <img onClick={() => setEdit(true)} style={{ marginTop: '3px', minWidth: '15px', maxWidth: '15px', marginLeft: '3px' }} src={EditIcon} className={`hover:text-black  cursor-pointer`} />}</Box>
        </Button>
        <Button background={'white'} color={'#3c4043'} className='robotic' left={'65.5vw'} _hover={{ background: '#EEEEEE' }} h={'35px'} borderColor={'#e1e1e1'} borderWidth={'1px'} position={'sticky'}>{open && <GlobalHolidays setOpen={setOpen} open={open} />}
          <Text display={'flex'} className='robotic text-[14px] font-normal' alignItems={'center'} zIndex={30} w={'100%'} onClick={() => setOpen(true)}>Absent Days</Text></Button>
        <Button background={'white'} color={'#3c4043'} className='robotic' _hover={{ background: '#EEEEEE' }} h={'35px'} borderColor={'#e1e1e1'} borderWidth={'1px'} position={'sticky'} onClick={() => setShare(true)} left={'75vw'}>
          <Text display={'flex'} className='robotic text-[14px] font-normal' alignItems={'center'} zIndex={30} w={'100%'}>Share</Text>
        </Button>
        <Button background={'white'} color={'#3c4043'} className='robotic' left={'82vw'} _hover={{ background: '#EEEEEE' }} h={'35px'} borderColor={'#e1e1e1'} borderWidth={'1px'} position={'sticky'}>
          <Text display={'flex'} className='robotic text-[14px] font-normal' alignItems={'center'} zIndex={30} h={'25px'} w={'100%'} >Support</Text>
        </Button>
        <Menu>
          <MenuButton height={'max-content'} background={'white'} color={'#3c4043'} borderColor={'#e1e1e1'} className='robotic' _hover={{ background: '#EEEEEE' }} borderWidth={'1px'} zIndex={20} position={'sticky'} left={'89.5vw'} as={Button} rightIcon={<VscChevronDown />}>
            <Box h={'33px'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
              <Text className=' robotic text-[14px] font-normal robotic'>Account</Text>
            </Box>
          </MenuButton>
          <MenuList h={'62px'} overflowY={'auto'} p={0} color={'#3c4043'} zIndex={50} position={'absolute'} bottom={-1.5} right={0} >
            <MenuItem h={'30px'}>
              <Text display={'flex'} className='robotic text-[14px]' alignItems={'center'} zIndex={30} h={'25px'} w={'100%'} whiteSpace={'nowrap'}><a target='_blank' rel="noreferrer" href="mailto:info@projektplan.online" >Questions: info@projektplan.online</a></Text></MenuItem>
            <MenuItem h={'30px'}>
              <Text display={'flex'} className='robotic text-[14px]' alignItems={'center'} zIndex={30} h={'25px'} w={'100%'} onClick={logout}>Logout</Text></MenuItem>
          </MenuList>
        </Menu>
        {share && <GenerateKeys open={share} setOpen={setShare} />}
      </Box>

      <Suspense fallback={<Box background={'black'}>Loading</Box>}>
        <GanttChart />
      </Suspense>
    </Box>
  );
}

export default GanttPage;
