import { Box, Flex, Text, } from "@chakra-ui/react";
import { memo, useContext, useState } from "react";
import { useDrop } from "react-dnd";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import EditProject from "../Components/Projects/EditProject";
import UserContext from "../Context/User/UserContext";
import { itemTypes } from "../DragTypes/Itemtypes";
import DeleteIcon from '../Icons/Delete.png';
import EditIcon from '../Icons/Edit.png';
import Tooltip from "../SharedViewComponents/Tooltip";
const DroppableBox = ({ project, index, assignEmp, toggleItem, }) => {
    const { view } = useContext(UserContext)
    const [{ isOver, canDrop }, drop] = useDrop({
        drop: (item) => { canDrop && assignEmp(index, item) },
        accept: itemTypes.EMPTOPRO,
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !project.closed && !view
        })
        // Define your drag properties
    });

    const [open, setOpen] = useState(false)
    const { openedList } = useContext(UserContext)

    return (
        <Box h={'22px'} pe={!view && '5px'} ref={drop} fontWeight={600} transition={'all 0.1s ease-in-out'} display={'flex'} w={'100%'} flexDir={'row'} justifyContent={'space-between'} ps={1} alignItems={'center'} overflow={'hidden'} minHeight={'21px'} style={isOver && !project.closed ? { color: 'white', background: 'gray' } : isOver && project.closed ? { background: 'red', color: 'white' } : {}}>
            <Flex flexDir={view && 'row-reverse'} w={view && '100%'} justifyContent={'space-between'}>{openedList?.some((single) => single === project?.name) ? <IoIosArrowUp cursor={'pointer'} fontSize={'13px'} onClick={() => toggleItem(project.name)} className=" ms-[2px]" style={{ marginTop: '3px', marginRight: '6px' }} /> : <IoIosArrowDown cursor={'pointer'} fontSize={'13px'} onClick={() => toggleItem(project.name)} className=" ms-[2px]" style={{ marginTop: '0.5vh', marginRight: '6px' }} />}
                {project?.name?.length >= 15 ? (
                    <Tooltip color={'#e8eaed'} background={'#212125'} fontSize={'12px'} className='roboto-condensed' hasArrow label={project?.name} >
                        <Text fontSize={'12px'} maxW={window.innerWidth <= 900 ? '80px' : '170px'} className="roboto-condensed truncate">{project?.name}</Text>
                    </Tooltip>
                ) : (
                    <Text fontSize={'12px'} className="roboto-condensed" overflow={'hidden'} whiteSpace={'nowrap'}>{project?.name}</Text>
                )}
            </Flex>
            {!view && !project.closed ?
                // <LuPencil onClick={() => setOpen(true)} className='hover:text-blue-500 ms-[3px] text-[16px] cursor-pointer' />
                <Box w={'16px'} me={'3px'} minW={'10px'}><img src={EditIcon} alt="edit" style={{ color: 'red' }} onClick={() => setOpen(true)} className='hover:text-blue-500  ms-[3px] text-[16px] cursor-pointer' /></Box>
                : !view &&
                <Box width={'15px'} display={'grid'} onClick={() => { setOpen(true) }} placeItems={'center'}><img alt="del" src={DeleteIcon} /></Box>
                // <RiDeleteBin6Line onClick={() => setOpen(true)} cursor={'pointer'} className='hover:text-red-500 text-[16px]' />

            }
            {open && <EditProject open={open} setOpen={setOpen} proName={project.name}></EditProject>}
        </Box>
    );
};
export default memo(DroppableBox)