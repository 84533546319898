import { useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import AdminLogin from './Components/Admin/Login/AdminLogin';
import Login from './Components/Auth/Login/Login';
import ForgotPassword from './Components/Auth/Passwords/ForgotPassword';
import ResetPassword from './Components/Auth/Passwords/ResetPassword';
import Signup from './Components/Auth/Signup/Signup';
import UserContext from './Context/User/UserContext';
import Accounts from './Pages/Accounts';
import AdminGanttPage from './Pages/AdminGanttPage';
import GanttPage from './Pages/GanttPage';
import GanttPageView from './Pages/GanttPageView';
import Loader from './utils/Loader/Loader';
function App() {

  // window.addEventListener('beforeunload', function (event) {
  //   if ("Asad") {
  //     // Display a confirmation message
  //     const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
  //     event.returnValue = confirmationMessage; 
  //     return confirmationMessage; // For some older browsers
  //   }
  // });

  const { user, loading } = useContext(UserContext);
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={loading ? <Loader /> : user?.isAdmin === true && user?.email ? <Accounts /> : user?.isAdmin === false && user?.email ? <GanttPage /> : <Navigate to='/login' />} />
        <Route path="/:name/:key" element={loading ? <Loader /> : <GanttPageView />} />
        <Route path="/register" element={loading ? <Loader /> : user?.email ? <Navigate to='/' /> : <Signup />} />
        <Route path="/login" element={loading ? <Loader /> : user?.email ? <Navigate to='/' /> : <Login />} />
        <Route path="/password/forgot" element={loading ? <Loader /> : user?.email ? <Navigate to='/' /> : <ForgotPassword />} />
        <Route path="/admin/login" element={loading ? <Loader /> : user?.email ? <Navigate to='/' /> : <AdminLogin />} />
        <Route path="/admin/gantt/:id" element={loading ? <Loader /> : user?.email && user?.isAdmin === true ? <AdminGanttPage /> : user?.email && user?.isAdmin === false ? <Navigate to='/' /> : <Navigate to='/admin/login' />} />
        <Route path="/password/reset/:token" element={loading ? <Loader /> : user?.email ? <Navigate to='/' /> : <ResetPassword />} />
        {/* <Route path="/profile" element={<div className="sm:ms-64 my-[10vh]">{loading ? <Loader /> : user?.email ? <Profile /> : <></>}</div>} /> */}
      </Routes>
    </BrowserRouter>
    // <GanttPage />
  );
}

export default App;
