import { Box, Flex, Text } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { memo, useContext } from 'react'
import UserContext from '../../../Context/User/UserContext'
import DroppableBox from '../../../DND/DroppableBox'
import DeleteIcon from '../../../Icons/Delete.png'
import { employees } from '../../../atoms'
import Tooltip from '../../../SharedViewComponents/Tooltip'
const OpenProject = ({ toggleItem, AssignEmployee, UnassignEmployee, provided, ind, i }) => {
    const [empList] = useAtom(employees)
    const { openedList } = useContext(UserContext)
    const { view } = useContext(UserContext)
    return (
        <Box display={'flex'} justifyContent={'start'} flexDir={'column'} alignItems={'center'} className={`${!view && !i.closed && 'hover:bg-slate-200'}`} width={'100%'} {...provided.dragHandleProps}{...provided.draggableProps} ref={provided.innerRef}>
            <DroppableBox toggleItem={toggleItem} index={ind} assignEmp={AssignEmployee} key={ind} project={i} />
            {openedList?.some((single) => single === i.name) && i?.assigned?.map((j, index) => {
                return (
                    <Box h={'20px'} borderBottom={'1px solid transparent'} key={index} transition={'all 0.25s ease-in-out'} display={'flex'} w={'100%'}
                        flexDir={'row'} justifyContent={'space-between'} px={'5px'} alignItems={'center'} height={'20px'} >
                        <Flex ms={!view && '20px'} justifyContent={'center'} alignItems={'center'}>
                            <Box textColor={i.closed ? i.assignedDetails.find((k) => k.name === j.name)?.team : empList.find((emp) => emp.name === j.name)?.team} backgroundColor={i.closed ? i.assignedDetails.find((k) => k.name === j.name)?.team : empList.find((emp) => emp.name === j.name)?.team} width={'5px'} height={'15px'} opacity={1}></Box>
                            {(window.innerWidth <= 900 && j.name.length >= 15) ? <Tooltip label={j.name} ><Text fontSize={'12px'} className='roboto-condensed truncate' style={{ maxWidth: '80px' }} ms={'5px'}>{j.name}</Text></Tooltip> : <Text fontSize={'12px'} className='roboto-condensed' ms={'5px'}>{j.name}</Text>}
                        </Flex>
                        {!i.closed && !view && (
                            <Box width={'15px'} display={'grid'} cursor={'pointer'} placeItems={'center'} onClick={() => { UnassignEmployee(ind, { name: j?.name }) }}><img src={DeleteIcon} /></Box>
                            //   <RiDeleteBin6Line  cursor={'pointer'} onClick={() => { UnassignEmployee(ind, { name: j?.name }) }} className='hover:text-red-500 text-[17px]' />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
}

export default memo(OpenProject)